import Axios from 'axios';
import React, { Component } from 'react'
import { Control, Errors, LocalForm } from 'react-redux-form'
import { ClipLoader } from 'react-spinners';
import { Button, InputGroup} from 'reactstrap';

const required = (val) => val && val.length;
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)

class PasswordReset extends Component {
    constructor(props){
        super(props)
        this.state={
            tokenVarified: true,
            isLoading: false,
            isUpdated: false
        }
    }

    componentDidMount(){
        const token =  this.props.match.params.token
        Axios.get(`https://${window.location.hostname}:5005/verifytoken/${token}`).then(result => {
            console.log(result)
            if(result.data.statusCode === 200){
                this.setState({
                    tokenVarified: true
                })
            }
            if(result.data.statusCode === 404){
                this.setState({
                    tokenVarified: false
                })
            }
        }).catch(error => {
            alert("Error while varify token")
        })
    }

    onFormSubmit = (values) => {
        console.log(values)
        if(values && values.password === values.newPassword){
            this.setState({
                isLoading: true
            })
            const token =  this.props.match.params.token
            Axios.put(`https://${window.location.hostname}:5005/resetpassword/${token}`, { password: values.password }).then(result => {
                console.log(result)
                if(result.data.statusCode === 200){
                    this.setState({
                        isUpdated: true,
                        isLoading: false
                    })
                }
                if(result.data.statusCode === 404){
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(error => {
                alert("Error while updating password")
                this.setState({
                    isLoading: false
                })
            })
        }else{
            alert("Password does not match")
        }
    }
    render() {

        let notVarified = (
            <div id="alertdiv">
                <div className="alert alert-info">
                    Token is expired, please send mail again to reset password.
                </div>
            </div>
        )

        let passwordForm = (
            <div>
                {!this.state.isLoading ?
                <LocalForm id="formDiv" onSubmit={(values) => this.onFormSubmit(values)}>

                    <InputGroup className="mb-3">
                        <Control.text model=".password"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="New Password"
                            className="form-control w-100"
                            validators = {{
                                required
                            }}
                        />
                        <Errors 
                            className="text-danger"
                            show="touched"
                            model=".password"
                            messages = {{
                                required: "Required"
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Control.text model=".newPassword"
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            placeholder="Confirm New Password"
                            className="form-control w-100"
                            validators = {{
                                required
                            }}
                        />
                        <Errors 
                            className="text-danger"
                            show="touched"
                            model=".newPassword"
                            messages = {{
                                required: "Required"
                            }}
                        />
                    </InputGroup>
                    <InputGroup>
                            <Button type="submit" block color="info">
                                Update Password
                            </Button>
                    </InputGroup>
                </LocalForm>
                :

                <div className="sweet-loading text-center">
                    <ClipLoader size={35} color={"#009dff"} loading={this.state.spinnerLoading} />
                </div>

                }
            
            </div>
        )

        let alertDiv = (
            <div id="alertdiv">
                <div className="alert alert-info">
                    Password Updated Successfully
                </div>
            </div>
        )

        
        return (
            <div>
                <div className="d-flex align-items-center vh-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <div className="card border-warning shadow">
                                    <div className="card-body m-3 m-md-5">
                                        
                                        <h3 className="text-warning font-weight-bold">Reset Password</h3>
                                        <p>Enter new pasword.</p>

                                        {this.state.tokenVarified ? (this.state.isUpdated ? alertDiv : passwordForm ) : notVarified}
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PasswordReset;