import React, {useState } from 'react';

import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';

import { Control, LocalForm, Errors } from 'react-redux-form';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText, InputGroupAddon, InputGroup } from 'reactstrap';

/* Parent Component  */
export default function CreateMockTest(){
	//which component to render
	//0 - test name and description
	//1 - add questions
	//2 - final step
	const [stage,setStage] = useState(0); 
	
	const required = (val) => val && val.length;

	
	return( 
	  <>
			<div className="d-flex align-items-center " style={{marginTop:'1rem',marginBottom:'5rem'}}>
				{stage === 0 ?<StepOne  setStage={setStage} />: null}
				{stage === 1 ? <StepTwo  setStage={setStage} /> : null}
				{stage === 2 ? <StepThree   setStage={setStage}   required={required} /> : null}
				{stage === 3 ?<Continue setStage={setStage} />:null}
			</div>
	</>
   )
}


/* May not be needed */
function Continue({setStage}){
		return(
			<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100vw'}} >
				<p>Do you want to Continue Creating the Test</p>
				<div>
					<button onClick={() => setStage(1)} >YES</button>
					<button onClick={() => {
						localStorage.removeItem('questionList');
						localStorage.removeItem('answerList');
						setStage(0)
					}} >NO</button>
				</div>
			</div>
	)
}