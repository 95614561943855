import Axios from 'axios';
import React, { Component } from 'react'
import { Control, Errors, LocalForm } from 'react-redux-form'
import { Button, InputGroup} from 'reactstrap';
import { ClipLoader } from 'react-spinners';

const required = (val) => val && val.length;
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)

class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            spinnerLoading: false
        }
    }
    onFormSubmit = (values) => {
        if(values){

            document.getElementById("formDiv").style.display = "none"
            this.setState({
                spinnerLoading: true
            })

            Axios.post(`https://${window.location.hostname}:5005/sendresetmail`,values).then(result => {
                console.log(result)
                this.setState({
                    spinnerLoading: false
                })
                if(result.data.statusCode === 200){
                    document.getElementById("alertdiv").style.display = "block"
                }
                if(result.data.statusCode === 404){
                    document.getElementById("formDiv").style.display = "block"
                    alert("No Such User")
                }
                if(result.data.statusCode === 401){
                    document.getElementById("formDiv").style.display = "block"
                    alert("Error While Sending Mail")
                }
                
            }).catch(error => {

            })
            
            
        }
    }
    render() {
        return (
            <div>
                <div className="d-flex align-items-center vh-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="card border-warning shadow">
                                    <div className="card-body m-3 m-md-5 text-center">
                                        
                                        <h3 className="text-warning font-weight-bold">Reset Password</h3>
                                        <p>Enter Email to Reset your Password</p>
                                        <LocalForm id="formDiv" onSubmit={(values) => this.onFormSubmit(values)}>

                                            <InputGroup className="mb-3 justify-content-center">
                                                <Control.text model=".email"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Enter Your Email"
                                                    className="form-control w-100 text-center"
                                                    validators = {{
                                                        validEmail
                                                    }}
                                                />
                                                <Errors 
                                                    className="text-danger"
                                                    show="touched"
                                                    model=".email"
                                                    messages = {{
                                                        validEmail: "Not valid email."
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                            <InputGroup>
                                                    <Button type="submit" block color="info"  style={{margin:'0'}}>
                                                        Send Mail
                                                    </Button>
                                            </InputGroup>
                                        </LocalForm>
                                        <div className="sweet-loading text-center">
                                            <ClipLoader size={35} color={"#009dff"} loading={this.state.spinnerLoading} />
                                        </div>
                                        <div id="alertdiv" style={{display: "none"}}>
                                            <div className="alert alert-info">
                                                An email has been sent with instructions to reset your password
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;