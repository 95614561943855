export default function Time(time){
			let hr=0,min=0,sec=0;
			if(time <= 86400){
				hr = parseInt((time / 3600));
					if((time/60) < 60){
						min = parseInt((time / 60));
						if(time){
							sec = (time % 60);
						}
					}
			}
			
			if(hr){
				return `${hr} hours  ${min} minutes  ${sec} seconds`
			}
			if(min){
				return `${min} minutes  ${sec} seconds`
			}
			if(sec){
				return `${sec} seconds`
			}
		
}