import React, {Component} from 'react'
import Axios from 'axios'

import EachQuestion from "./EachQuestion"
import ReactLoading from 'react-loading'
import NoQuest from "./NoQuest"


import Pagination from '@material-ui/lab/Pagination';

class AllQuestion extends Component{
    constructor(props){
        super(props)
        this.state = {
			currentTab:1,
			limit:7,
			total:0,
            allQuestion : [],
            isDataReturned: false
        }
		
		this.handlePageChange = this.handlePageChange.bind(this);
    }
	
	
	 fetchNewData(tabValue) {
			let requestOptions = {
				params:{
				  skip: (tabValue - 1) * (this.state.limit),
				  limit:this.state.limit
				}
			}
			
		 Axios.get(`https://${window.location.hostname}:5005/quora/question/`,requestOptions).then(allQuest => {
           
            this.setState({
                allQuestion: allQuest.data,
                isDataReturned: true
            })
        }).catch(error => {
            console.log("Axios error")
            
        })
		
	 }
	

    componentDidMount(){
		const currentTab = this.state.currentTab;
		if(!this.state.total){
			 Axios.get(`https://${window.location.hostname}:5005/quora/question/count`).then(response => {
				
				this.setState({
					total:response.data,
				})
				this.fetchNewData(currentTab);
			}).catch(error => {
				console.log("Axios error")
				
			})
		}
		
    }


    handlePageChange(e,value){
		e.preventDefault();
		this.setState({
			currentTab:value,
			isDataReturned: false,
		})
	}
	
	 // For change in the state
	  componentDidUpdate(prevProps, prevState) {
		if (prevState.currentTab !== this.state.currentTab) {
		 
		  this.fetchNewData(this.state.currentTab);
		} else {
		  
		}
	  }

    

    render(){
        return(
            this.state.isDataReturned ?
            <>  
                {this.state.allQuestion.length>0 ?           
                    <div>
						<div className="mt-2 mb-2"  >
							<Pagination count={Math.round(this.state.total/this.state.limit)} page={this.state.currentTab} onChange={this.handlePageChange} color="primary" />
						</div>
                        {this.state.allQuestion.map((data,  index) => {
                            return(
                                <EachQuestion question={{"eachQuest": data}}/>
                                
                            )
                        })}
                        
                    </div>
                : <NoQuest />}
            </>
            :<ReactLoading style={{ color: "black", margin: "auto", height: "20%", width: "20%" }} type={"spinningBubbles"} />
    
        )
    }
}

export default AllQuestion;