import React from 'react';
import VideoTestimonial from '../testimonial/videoTestimonial';
// import './MentorVideo.css'; // Ensure this file exists
// import Testimonial from './testimonial';

function MentorVideo() {
  return (
    <div className="carousel-container">
      <p className="main-title">See What Our Mentors Say!</p>
      {/* <Testimonial /> */}
      {/* Video Embed Section */}
      <VideoTestimonial />
      {/* <div className="video-container">
        <div className="video-wrapper">
          <iframe
            className="mentor-video"
            src="https://www.youtube.com/embed/SAiS_q8ILLs"
            title="Mentor Experience Video 1"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="video-wrapper">
          <iframe
            className="mentor-video"
            src="https://www.youtube.com/embed/00vR9gruUow"
            title="Mentor Experience Video 2"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}
    </div>
  );
}

export default MentorVideo;
