import React,{useState} from 'react'
import './Mentors.css';
import { ReactComponent as AIImg} from './AIImg.svg';
import {ReactComponent as ProgressImg } from './ProgressImg.svg';
import {ReactComponent as TrackingImg} from './TrackingImg.svg';
import {ReactComponent as RecognitionImg} from './RecognitionImg.svg';
import {ReactComponent as SupportImg} from './SupportImg.svg';
import CollaborativePng from '../../../src/assets/collaborative.png';
import {ReactComponent as QAImg} from './QAImg.svg';
import {ReactComponent as ExpertImg} from './ExpertImg.svg';
import {ReactComponent as TeamImg} from './TeamImg.svg';
// import {ReactComponent as Collaborative} from './Collaborative.png';

function Mentors() {
  
  return (
    <div className='functions-container'>
	  <h2>Key Functions of <span style={{color:'#5a159e'}} >MentoMEET</span></h2>
	<div class="grid-container">
	<div class="key-item">
		<AIImg/>
	  <h3>AI Matching Engine</h3>
	  <p>Match students with relevant mentors using AI.</p>
   </div>
	<div class="key-item">
		<TrackingImg/>
	  <h3>Progress Tracking</h3>
	  <p>Monitor your mentorship sessions and progress to ensure continuous improvement.</p>
	</div>
	<div class="key-item">
	<ProgressImg/>
	  <h3>Mobile-Friendly</h3>
	  <p>Access mentorship easily on your phone, anytime and anywhere.</p>
	</div>
	 <div class="key-item">
		<RecognitionImg/>
	   <h3>Mentor Recognition</h3>
	   <p>Mentors are rewarded for their contributions and successes.</p>
	 
   </div>
   <div class="key-item">
	<img src={CollaborativePng} alt="collaborative"/>
	   <h3>Collaborative Ecosystem</h3>
	   <p>We partner with educational and corporate organizations to create specialized mentorship programs.</p>
   </div>
   <div class="key-item">
	<SupportImg/>
	   <h3>Feedback and Support</h3>
	   <p>Easy access to feedback and support to continuously enhance your experience.</p>
   </div>
   <div class="key-item">
	<QAImg/>
	   <h3>Q&A Exchange</h3>
	   <p>Engage directly with experts for insightful answers.</p>
   </div>
   <div class="key-item">
	<ExpertImg/>
	   <h3>Expert Connect</h3>
	   <p>Communicate with your mentor through secure, high-quality video sessions.</p>
	   {/* <button>Explore Key Features</button> */}
   </div>
   <div class="key-item">
	<TeamImg/>
	   <h3>Community Network</h3>
	   <p>Build connections and collaborate with like-minded individuals.</p>
   </div>
  </div>
  
   {/* <div className='function-btn'>
   <button>Explore Key Features</button>
 </div> */}
 </div>
    );
};

export default Mentors;
