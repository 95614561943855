import React,{useState} from 'react';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText, InputGroupAddon, InputGroup } from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import Time from '../utils/Time';

const required = (val) => val && val.length;

/* Child component of create mock test */
export default function StepThree({setStage}){
	let initName,initDesc 
	if(localStorage.getItem('test_name')){
		initName = localStorage.getItem('test_name');
	}else{
		initName = 'Enter the name of test'
	} 
	if(localStorage.getItem('test_description')){
		initDesc = localStorage.getItem('test_description');
	}else{
		initDesc = 'No Description'
	} 
	const noOfQuestion = JSON.parse(localStorage.getItem('questionList')).length;
	let [correctAnsMarks,setCorrectAnsMarks] = useState(0);
	let [name,setName] = useState(initName);
	let [description,setDescription] = useState(initDesc);
	let [inCorrectAnsMarks,setInCorrectAnsMarks] = useState(0);
	let [passMarks,setPassMarks] = useState('');
	let [duration,setDuration] = useState('');  //in mins to convert in seconds
	
	function check(){
		let code = true;
		if(noOfQuestion == 0) code = false;
		if(correctAnsMarks == 0) code = false;
		if(!passMarks) code = false;
		if(!duration) code = false;
		if(!name) code = false;
		return code;
	}

	function addTest(e){
		e.preventDefault();
		if(!check()){
			alert('Check the fields');
			return;
		}
		let questionList = JSON.parse(localStorage.getItem('questionList'));
		let answerList = JSON.parse(localStorage.getItem('answerList'));
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let testDuration = duration; //in secs
		let totalMarks = correctAnsMarks * parseInt(noOfQuestion);
		
		//get role 
		let role = JSON.parse(localStorage.getItem('user')).role.toLowerCase();		
		const token = localStorage.getItem('token');		
		fetch(`https://${window.location.hostname}:5005/${role}/createTest`,{
			method:"POST",
			headers:{'Content-Type':'application/json','Authorization':`Bearer ${token}`},
			body:JSON.stringify({questionList,answerList,userInfo,name,description,totalMarks,passMarks,correctAnsMarks,inCorrectAnsMarks,testDuration})
		})
		.then(res => {
			if(res.ok){
				alert('SUCCESS');
				localStorage.removeItem('test_name');
				localStorage.removeItem('test_description');
				localStorage.removeItem('questionList');
				localStorage.removeItem('answerList');
				setStage(0);
			}else{
				alert('ERROR');
			}
		})
		.catch(e => {
			alert('Server ERROR');
		})
		
	}
	
	return( 
		    <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card-group shadow">
                            <div className="card border-warning text-white">
                                <div className="card-body m-3">
                                    <LocalForm >
                                        <h3 className="text-warning mb-0">Test Details </h3>
                                  
								  
										{/* No. OF Questions */}
										<InputGroup className="mb-3 d-block">
                                            <label style={{color:'black'}} >Total Questions</label>										
											<Control.text model=".noOfQuestion"
                                                id="name"
                                                name="name"
												value={name}
												onChange={(e) => setName(e.target.value)}
                                                placeholder="Total number of Questions"
                                                className="form-control w-100"                                  
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".name"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>

								  
										{/* No. OF Questions */}
										<InputGroup className="mb-3 d-block">
                                            <label style={{color:'black'}} >Total Questions</label>										
											<Control.text model=".noOfQuestion"
                                                id="noOfQuestion"
                                                name="noOfQuestion"
												value={noOfQuestion}
												disabled
                                                placeholder="Total number of Questions"
                                                className="form-control w-100"
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".noOfQuestion"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>

										{/* Correct Marks*/}
										 <InputGroup className="mb-3 d-block">
                                             <label style={{color:'black'}} >Correct Answer Marks</label>																					
											<Control.text model=".correctAnsMarks"
												type='number'
                                                id="correctAnsMarks"
                                                name="correctAnsMarks"
												value={correctAnsMarks}
												onChange={(e) => setCorrectAnsMarks(e.target.value)}
                                                placeholder="Correct Answer Marks"
                                                className="form-control w-100"
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".correctAnsMarks"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>

									{/*  Incorrect Marks */}
                                        <InputGroup className="mb-3 d-block">
											<label style={{color:'black'}} >InCorrect Answer Marks</label>																					
                                            <Control.text model=".inCorrectAnsMarks"
                                                type="number"
												id="inCorrectAnsMarks"
                                                name="inCorrectAnsMarks"
												value={inCorrectAnsMarks}
												onChange={(e) => setInCorrectAnsMarks(e.target.value)}
                                                placeholder="Incorrect Answer Marks"
                                                className="form-control w-100"
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".inCorrectAnsMarks"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>
										
										{/* Total Marks */}
										  <InputGroup className="mb-3 d-block">
                                            <label style={{color:'black'}} >Total Marks</label>																					                                           
											<Control.text model=".totalMarks"
                                                id="totalMarks"
												disabled
                                                name="totalMarks"
												value={correctAnsMarks * parseInt(noOfQuestion)}
                                                placeholder="Total Marks"
                                                className="form-control w-100"
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".totalMarks"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>

                                        
										{/* Passing Marks */}
                                        <InputGroup className="mb-3 d-block">
                                            <label style={{color:'black'}} >Passing  Marks</label>																					                                           
											<Control.text model=".passingMarks"
                                                id="passingMarks"
                                                name="passingMarks"
												value={passMarks}
												onChange={(e) => setPassMarks(e.target.value)}
                                                placeholder="Passing Marks"
                                                className="form-control w-100"
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".passingMarks"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>
							
                                       
                                        
										{/* Test Duration */}
                                        <InputGroup className="mb-3 d-block">
                                            <label style={{color:'black'}} >Test Duration (in Seconds Only)</label>																					                                           
                                            <label style={{color:'red',marginLeft:'auto',display:'block'}} >{Time(duration)}</label>																					                                           
											<Control.text model=".duration"
                                                id="duration"
                                                name="duration"
												type="number"
												value={duration}
												onChange={(e) => setDuration(e.target.value)}
                                                placeholder="Enter Duration in Seconds only"
                                                className="form-control w-100"                                             
                                                validators={{
                                                    required
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".duration"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>
										
										<InputGroup className="mb-3">
                                            <Button type="submit" block color="info" onClick={addTest}>
													Add Test
                                            </Button>
                                        </InputGroup>
										
										<InputGroup className="mb-3">
                                            <Button type="submit" block color="info" onClick={() => setStage(1)}>
													Go Back
                                            </Button>
                                        </InputGroup>
										
									</LocalForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
}