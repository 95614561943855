import React, { Component, Suspense } from "react"
import Axios from 'axios'
import $ from "jquery"
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText,  InputGroupAddon, InputGroup, Card, CardBody, CardTitle, Alert} from 'reactstrap';
import { Link, BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import {Modal} from 'react-bootstrap';
import CallbackForm from './CallbackForm';


import ReactLoading from 'react-loading';

// import NavBarLog from './../NavBarLog.js'
import Routes from "./MentorRoutes.js"
import { CardHeader } from "@material-ui/core";
// import BlogCreateForm from "./BlogCreateForm"




// import EachQuestion from "./EachQuestion"
// import {checktoken} from "../CommonFunc/common.js"

const required = (val) => val && val.length;



class MentorLists extends Component{
    constructor(props){
        super(props)
        this.state = {
            blogsCount : [],
            isDataReturned: true,
			openCallBackForm: false,//callback form disabled for /mentors
        }
    }

	//callback functionality starts here
		handleClose = () => {
			this.setState({
			   ...this.state,
			  openCallBackForm:false
			});
		  }
	
	 handleOpen = () => {
		 if(!localStorage.getItem('token')){
			 window.location.href = '/login';
			 return;
		 }
		this.setState({...this.state,openCallBackForm:true})
	  }
	
	//ends here

    // componentDidMount(){

    //     Axios.get(`http://${window.location.hostname}:5005/blogs/count/`).then(blogsCount => {
    //         console.log(blogsCount);
    //         this.setState({
    //             blogsCount: blogsCount.data,
    //             isDataReturned: true
    //         })
    //     }).catch(error => {
    //         console.log("Axios error")
    //         console.log(error)
    //     })


        
    // }


    

    TagComponentHandler = (e)=>{
        if(e.target.value !=0){
            console.log(e.target.value)
            window.location.href = "/blogs/tag/"+e.target.value;
        }        
    }
    

    render(){
        return(
            this.state.isDataReturned ?
       <>
	   {/* start */}
		 <Modal 
		show={this.state.openCallBackForm}
		onHide={this.handleClose} 
		backdrop="static"
        keyboard={false}
		size="md"
	>
			<Modal.Header closeButton>
			  <Modal.Title>Register CallBack Request</Modal.Title>
			</Modal.Header>
			<Modal.Body>
					<CallbackForm />
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={this.handleClose}>
				Close
			  </Button>
			</Modal.Footer>
      </Modal>
	  {/* end */}
            <div className="my-4">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <Card style={{marginBottom:"20px"}}>
                                 
                                    <CardBody>
									<h3 style={{fontWeight:'bold'}} > Meet the Mentors</h3>
                                    <p>
                                    Our mentors are comprised of IITians, NITians, AIIMSians and industries experts placed in fortune companies like jp Morgan, Goldman Sachs , flipkart, Amazon, etc.
                                    They are here to help you with their unique strategies and insightful guidance. All you have to do is 'Schedule a meeting' and reach out to them!
                                    <br></br><br/>
									<Button  style={{padding:'10px',marginLeft:'auto'}} className="btn btn-warning" href="/scheduleMeeting" >Schedule a Meeting/Call</Button>
								   <b>For Quality Mentorship,We Give 70-80% callback plan worth to our mentors.</b>
									
									<br></br>
									
                                    </p>
                                    <p>

                                    <Alert color="success"> 
                                    <h4>"Now You can also register your callback on our Number +91 6378963002"</h4>
                                    <h6> For Continuous support and guide,Now you can also Join our telegram channels- <a href="https://t.me/joinchat/Gb2FNKFATqQbSg5G">JEE</a>  ,    <a href="https://t.me/joinchat/G_ujsxcR9ZxP5f_8">NEET</a>   ,   <a href="https://t.me/joinchat/Tpiupnwojyk4fW03">CAREER</a></h6>
                                    <p>NOTE: Links work only, if you have telegram app.</p> 
                                        <br />
                                    </Alert>
                                        
                                    </p>
                                    </CardBody>
                                </Card>
                                <nav>
                                    <div className="nav-tabs-question d-flex justify-content-between">
                                        <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                            <a href="/mentors" className={"nav-item text-dark py-1 px-2 px-sm-3 border-0 nav-link " + (window.location.pathname === '/mentors' ? 'active' : '')} id="nav-home-tab">ALL</a>
                                            <a href="/mentors/jee" className={"nav-item text-dark py-1 px-2 px-sm-3 border-0 nav-link " + (window.location.pathname === '/mentors/jee' ? 'active' : '')} id="nav-home-tab">JEE</a>
                                            <a href="/mentors/neet" className={"nav-item text-dark py-1 px-2 px-sm-3 border-0 nav-link " + (window.location.pathname === '/mentors/neet' ? 'active' : '')} id="nav-profile-tab" >NEET</a>
                                            <a href="/mentors/career" className={"nav-item text-dark py-1 px-2 px-sm-3 border-0 nav-link " + (window.location.pathname === '/mentors/career' ? 'active' : '')} id="nav-contact-tab">CAREER</a>
                                            {/* <a href="/blogs/development" className={"nav-item text-dark py-1 px-2 px-sm-3 border-0 nav-link " + (window.location.pathname === '/blogs/development' ? 'active' : '')} id="nav-contact-tab">DEVELOPMENT</a> */}

                                        </div>
                                        
                                    </div>
                                    <div class="tab-content" id="nav-tabContent">
                                        <Suspense>
                                            <Switch>
                                                {Routes.map((route, index) => {
                                                    return route.component ? (
                                                        <Route
                                                            key={index}
                                                            path={route.path}
                                                            exact={route.exact}
                                                            name={route.name}
                                                            render={props => <route.component {...props} />}
                                                        />
                                                    ) : (null);
                                                })}
                                                <Redirect from="/" to="/index" />
                                            </Switch>
                                        </Suspense>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="blogform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content border-warning">
                            <div class="modal-header py-2">
                                <h6 class="modal-title" id="exampleModalLabel">Ask A Question</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </>
            :<ReactLoading style={{ color: "black", margin: "auto", height: "20%", width: "20%" }} type={"spinningBubbles"} />
        )
    }
}

export default MentorLists;
