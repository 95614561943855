import React from 'react';
import { Card, Button } from 'react-bootstrap';
import Time from '../utils/Time';

export default function TestCard(props) {


	return (
		<Card style={{ minWidth: '18rem', maxWidth: '19rem', margin: '1rem 1rem 1rem 1rem' }}  >
			<Card.Header style={{ background: '#FFC107' }} >
				<Card.Title> {props.name && props.name.toUpperCase()}</Card.Title>
				<p style={{color:'green'}} >{props.isFree?'FREE':'PAID'}</p>
			</Card.Header >

			<Card.Body  >
				<Card.Text>Total Marks : {(props.questionList.length) * (props.correctAnswerMarks)}</Card.Text>
				<Card.Text>Total Questions : {props.questionList.length}</Card.Text>
				{
							props.isDividedInSection?(
								<></>
							):(
								<>
								<Card.Text>Passing Marks : {props.passMarks} </Card.Text>
								<Card.Text>Correct Answer Marks : {props.correctAnswerMarks} </Card.Text>
								<Card.Text>InCorrect Answer Marks : {props.IncorrectAnswerMarks} </Card.Text>
								<Card.Text style={{ fontWeight: '800', color: 'green' }} >{(Time(parseInt(props.testDuration)))} </Card.Text>
							</>
							)
				}
				</Card.Body>

			<Card.Footer style={{ background: '#FFC107', textAlign: 'center' }} >
				<Button style={{ background: '#17a2b8' }}  data-unique={props._id}>Start Test</Button>
			</Card.Footer>
		</Card>
	)
}