import React,{useState,useEffect} from 'react';
import TestHistoryCard from './TestHistoryCard';
import {Container,Row,Col,CardDeck} from 'react-bootstrap';
import axios from 'axios';

export default function MenteeTestHistory(){
	
	const [data,setData] = useState('');
	
	const [err,setErr] = useState(false);
	
	useEffect(() => {
		 let isMounted = true;
		let menteeID = JSON.parse(localStorage.getItem('user'))._id
		axios.get(`https://${window.location.hostname}:5005/mentee/mocktest/getTestsHistory/${menteeID}`)
			.then(data => { 
				if(data){
					if (isMounted) setData(data.data.result);
					console.log(data.data.result);
				} 
			})
			.catch(error => setErr(true))
		 //for unmounting	
		 return () => { isMounted = false }; 
		
	},[])
	
	const cards = data && data.map((cardProp) => {
		return <TestHistoryCard  key={cardProp._id}  {...cardProp} />;
	})
	
	return(
	 <Container  >
		<h1>History</h1>
		<Row className='justify-center align-center mlr-auto' > 
		 <CardDeck >
		     {(cards.length > 0)?cards:<p style={{color:'red',textAlign:'center',marginTop:'2rem'}} >Prepare well for the exam , try the mock tests</p>}
		  </CardDeck>
		</Row>
		{err?<p className='text-danger' style={{fontSize:'2rem'}} >Sorry For Inconvenience !!!</p>:null}
		 
	</Container  >
	)
}