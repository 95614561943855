/* 
	Contain the Entire Folder Structure affect admin panel and user panel
	Ensure :
		parent field  is present,
		name is used for Folder name as well as result are shown based on that
		sub it can be either some more folders or []

 */

export  const  TAGS_LIST_MOCK_TEST = {	
	'JEE':	[
				{
					'name':'Mains',
					'parent':'JEE',
					'sub':[
							{
								'name':'Physics',
								'parent':'Mains',
								'sub':[] 
							},
							{
								'name':'Maths',
								'parent':'Mains',
								'sub':[]
							},
							{
								'name':'Chemistry',
								'parent':'Mains',
								'sub':[
										{
											'name':'Organic',
											'parent':'Chemistry',
											'sub':[]
										},
										{
											'name':'Inorganic',
											'parent':'Chemistry',
											'sub':[]
										},
										{
											'name':'Physical',
											'parent':'Chemistry',
											'sub':[]
										}
								]
							}
						]
				},
				{
					'name':'Advanced',
					'parent':'JEE',
					'sub':[
							{
								'name':'Physics',
								'parent':'Advanced',
								'sub':[] 
							},
							{
								'name':'Maths',
								'parent':'Advanced',
								'sub':[]
							},
							{
								'name':'Chemistry',
								'parent':'Advanced',
								'sub':[]
							}
						]
				}
			],
		'NEET':[	
				{
					'name':'Physics',
					'parent':'NEET',
					'sub':[
								{
									'name':'11th physics',
									'parent':'Physics',
									'sub':[]
								},
								{
									'name':'12th physics',
									'parent':'Physics',
									'sub':[]
								}
						]
				},
				{
					'name':'Chemistry',
					'parent':'NEET',
					'sub':[
						{
							'name':'Organic',
							'parent':'Chemistry',
							'sub':[]
						},
						{
							'name':'Inorganic',
							'parent':'Chemistry',
							'sub':[]
						},
						{
							'name':'Physical',
							'parent':'Chemistry',
							'sub':[]
						}
					]
				},
				{
					'name':'Biology',
					'parent':'NEET',
					'sub':[
								{
									'name':'11th bio',
									'parent':'Biology',
									'sub':[]
								},
								{
									'name':'12th bio',
									'parent':'Biology',
									'sub':[]
								}
						]
				}
			],
		'CAREER':[
						{
							'name':'General Aptitude',
							'parent':'CAREER',
							'sub':[
									{
										'name':'Arithmetic Aptitude',
										
										'parent':'generalAptitude',
										'sub':[
												{
													'name':'Problems on Trains',
													'parent':'arithmeticAptitude',
													'sub':[],
												
												},
												{
													'name':'Height and Distance',
													'parent':'arithmeticAptitude',
													'sub':[]
												}
											]
									}
								]
							},
							{
									'name':'Data Interpretation',								
									'parent':'CAREER',
									'sub':[
											{
												'name':'Table Charts',
												'parent':'dataInterpretation',
												'sub':[{
														'name':'Problems D1',
														'parent':'tableChart',
														'sub':[]
													},
													{
														'name':'Pron D1',
														'parent':'tableChart',
														'sub':[]
													}
												]
											},
											{
												'name':'Pie Charts',
												'parent':'dataInterpretation',
												'sub':[{
														'name':'Problems D2',
														'parent':'pieChart',
														'sub':[]
													},
													{
														'name':'Pron D2',
														'parent':'pieChart',
														'sub':[]
													}
												]
											}
									]
							}
						]			
}
