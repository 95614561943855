import React from 'react';
import { TextField, IconButton, Radio, Typography, Fab, Button ,Badge,Checkbox} from '@material-ui/core';
import CropOriginalIcon from '@material-ui/icons/Image';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {Form} from 'react-bootstrap';
import MUIRichTextEditor from 'mui-rte';
import {createMuiTheme ,MuiThemeProvider} from '@material-ui/core/styles';



const SuperScript = (props) => {
    return (
        <span style={{
			fontStyle:'italic'
        }}>
         {props.children}
        </span>
    )
}

const defaultTheme = createMuiTheme();

Object.assign(defaultTheme,{
	overrides:{
		MUIRichTextEditor:{
			editor:{
				color:'black',
				maxHeight:'200px',
				height:'200px',
				overflow:'scroll'
			}
		}
	}
})

/* Custom Option QuestionField */
export default function QuestionField({topic,setTopic,textQuestion,setTextQuestion,setImageQuestion,imageQuestion}){
	return(
	<>
		<div style={{marginBottom:'1rem'}}>
			<Typography style={{marginTop: '20px',color:'black' }}>Topics Covered  in this question</Typography>
			<TextField fullWidth={true} placeholder={'Write Topics with comma separated like  sets,triangles,probability e.t.c '} style={{ marginTop: '5px' }} required   value={topic}  onChange={(e) => setTopic(e.target.value)}/>                
	   </div>

	   <div >
			<Typography  style={{ marginTop: '20px',color:'black'}}>Question </Typography>
			<p style={{color:'red'}} >You Need To Save Question using the Save ICON on Toolbar(Last in the row and right to the bold button in toolbar) </p>
			<MuiThemeProvider theme={defaultTheme} > 
				 <MUIRichTextEditor 
						label="Start .."
						controls={['superscript','subscript','bulletList','italic','underline','bold','save']}
						name='question'
						
						onSave={data => {
							setTextQuestion(data);
						}}
						customControls={[
							{
								name: "superscript",
								icon:<ArrowUpwardIcon />,
								type:'inline',
								inlineStyle:{
									fontSize:'x-small',
									verticalAlign:'super'
								}
							},
							{
								name: "subscript",
								icon: <ArrowDownwardIcon />,
								type:'inline',
								inlineStyle:{
									fontSize:'x-small',
									verticalAlign:'sub'
								}
							}
							
						]}
				 />
			</MuiThemeProvider>
			<div  style={{ display: 'flex', width: '100%', justifyContent: 'flex-start',padding:'20px' }} >
				
				{/* <TextField
					fullWidth={true}
					placeholder={`Type your question ...`}
					style={{ marginBottom: '18px' }}
					rows={2}
					rowsMax={20}
					multiline={true}
					required
					name='question'
					value={textQuestion}
					variant="filled"
					onChange={(e) => setTextQuestion(e.target.value)}
/> */}
				
				<Form.File
					  required
					  style={{color:'black'}}
					  name="imageQuestion"
					  label="Optional Image"
					  feedbackTooltip
					  style={{marginLeft:'3rem',color:'black'}}
					  onChange={(e) => { 
							setImageQuestion(e.target.files[0])
						}}
					  />
				<IconButton aria-label="image" style={{ outline: 'none' }}  >
					<CropOriginalIcon style={{color:(imageQuestion.length > 0)?'green':'red',marginLeft:'1rem'}} />
				</IconButton>
			</div>
		</div>
	</>
	)
}