import React,{useEffect} from 'react';
import { TextField, IconButton, Radio, Typography, Fab, Button ,Badge,Checkbox} from '@material-ui/core';
import CropOriginalIcon from '@material-ui/icons/Image';
import {Form} from 'react-bootstrap';

/* Custom Option Component */
export default function Options({i,correctOption,setCorrectOption,textAnswers,setTextAnswers,imageAnswers,setImageAnswers}){
		
	return(
		<div key={i} style={{ display: 'flex', flexDirection: 'row', marginLeft: '-13px',justifyContent:'space-between',alignItems:'center'}}>
			<Checkbox
				id={i}
				checked={correctOption[i]}
				onChange={(e) => {
					let i = e.target.id;
					let oldCorrect = correctOption || {};
					oldCorrect[i] = !oldCorrect[i];
					setCorrectOption({...oldCorrect});
				}}
				inputProps={{ 'aria-label': 'primary checkbox' }}
			/>
			<TextField fullWidth={true} placeholder={'Write option'} style={{ marginTop: '5px' }} required  id={i} value={textAnswers[i]} 
					onChange={(e) => {
						  let oldTexAnswers = textAnswers || [];
						  oldTexAnswers[e.target.id] = e.target.value;
						  //oldTexAnswers[e.target.]
						setTextAnswers([...oldTexAnswers]);
					}} />
			<Form.File
			  required
			  id={i}
			  type="file"
			  name="imageAnswers"
			  feedbackTooltip
			  style={{color:'black'}}
			  onChange={(e) => { 
					//setErr({...err,image:''}); 
					//setImage(e.target.files[0]);
					let oldImageAnswers =  imageAnswers || [];
					let id = e.target.id;
					if(id == '') id = 0;
						 oldImageAnswers[id] = e.target.files[0];
						  //oldTexAnswers[e.target.]
						console.log(id)
						console.log(oldImageAnswers)
						setImageAnswers([...oldImageAnswers]);
				}}
			  />
			  <IconButton aria-label="delete" style={{color:imageAnswers[i]?'green':'red',marginRight:'10px'}} >
						<CropOriginalIcon />
			   </IconButton>
		</div>
	)
}