import React,{useEffect,useState} from 'react';
import {Form,Button,Col} from 'react-bootstrap';
import CropOriginalIcon from '@material-ui/icons/Image';
import { TextField, IconButton, Radio, Typography, Fab ,Badge,Checkbox,InputLabel} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TagsDropdown from './TagsDropdown.js'


export default function UploadNotes(){
	
	const [name,setName] = useState('');
	const [description,setDescription] = useState('');
	const [pdf,setPdf] = useState('');
	const [submitted,setSubmitted] = useState(false);
	
	const [tags,setTags] = useState();
	const [error,setError] = useState('');
	
	const useStyles = makeStyles((theme) => ({
		  root: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			listStyle: 'none',
			padding: theme.spacing(0.5),
			margin: 0,
		  },
		  chip: {
			margin: theme.spacing(0.5),
		  },
		  formControl:{
			  width:'100%'
		  }
		}));
	
	const classes = useStyles();
	  
/* 	const [chipData, setChipData] = React.useState([
		{ key: 0, label: 'jee' },
		{ key: 1, label: 'neet' },
		{ key: 2, label: 'college' }
	  ]);

	  const handleDelete = (chipToDelete) => () => {
		setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
	  }; */
	
	function handleSubmit(e){
		e.preventDefault();
		
		if(JSON.parse(localStorage.getItem('user'))==null){
			let redirect = window.location.href;
			localStorage.setItem('redirect',redirect);
			window.location.href = '/login';
		}
		
	
		
		if(!name || !pdf || !description){
			setError('Please Enter Details Correctly');
			return;
		} 
		
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let mentorID = JSON.parse(localStorage.getItem('user'))._id
		
		const data = new FormData();
		data.append('name',name);
		data.append('description',description);
		data.append('pdfnote',pdf);
		data.append('tags',tags);
		
		const token = localStorage.getItem('token');
		fetch(`https://${window.location.hostname}:5005/studyMaterial/upload/${mentorID}`,{
			method:"POST",
			headers:{'Authorization':`Bearer ${token}`},			
			body:data
		}).then((res) => {
			//alert(res);
			console.log(res);
			if(res.ok){
				setSubmitted(true);
			}else{
			
				if(res.status == 401){
					setError(`Server Error`);
					
				}else{
					setError('Please Try Later');
				}
				
			}
		})
		.catch(e => alert(e))
	}
	

	  if(submitted){
		  return (
		  <div style={{height:'80vh'}} >
			  <h3 className="text-success text-center" >Your PDF has been Uploaded Successfully and Will be reviewed by the Admin</h3>
		 </div>
		  )
	  }else{
	
	 return(
	 <div style={{minHeight:'80vh'}} >
		<p className='text-danger text-center' >{(error.length > 0)?error:null}</p>
		<Form style={{maxWidth:'500px',margin:'0 auto'}}>	
			<Form.Row>
				<Col>
				<Form.Group controlId="pdfName">
					<Form.Label>Enter PDF Name</Form.Label>
					<Form.Control onChange={e => setName(e.target.value)} value={name}  placeholder="Enter Name For PDF"  />
				</Form.Group>
				</Col>
			</Form.Row>
			<Form.Row>
				<Col>
				<Form.Group controlId="pdfDesc">
					<Form.Label>About PDF</Form.Label>
					<Form.Control onChange={e => setDescription(e.target.value)} value={description}  placeholder="Small Description About PDF"  />
				</Form.Group>
				</Col>
			</Form.Row>
				<FormControl className={classes.formControl}>
					<InputLabel shrink >
					   SELECT CATEGORY
					</InputLabel>
					<Select
					  value={tags}
					  onChange={(e) => setTags(e.target.value)}
					>
					  <option value={'JEE'}>JEE</option>
					  <option value={'NEET'}>NEET</option>
					  <option value={'CAREER'}>CAREER</option>
					</Select>
				</FormControl> 
		
			<Form.Row>
				<Col>
					<Form.File
					  required
					  style={{color:'black'}}
					  name="pdfnotes"
					  label="Select  PDF File"
					  feedbackTooltip
					  style={{color:'black',textAlign:'center'}}
					  onChange={(e) => { 
							console.log(e.target.files)
							if(e.target.files[0]['type'] != 'application/pdf'){
								alert('Please Enter a Valid PDF File')
								return;
							}
							setPdf(e.target.files[0])
						}}
					  />
				
				</Col>
		  </Form.Row>
		  

		   <div className='Btn' style={{textAlign:'center',margin:'20px 20px'}}>
				<Button type='submit' onClick={(e) => handleSubmit(e)} className='btn btn-warning' >Upload Note</Button>
		   </div>
		</Form>
	 </div>
	)}
  
}