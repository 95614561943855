import React,{useState,useEffect} from 'react';
import {Button,Form} from 'react-bootstrap';
import { TextField} from '@material-ui/core';

import axios from 'axios';
import AdminCreateWebinar from './AdminCreateWebinar';
import RegisteredUser from './RegisteredUser';

/* Child Component of AdminDashboard Page */
export default function AdminWebinar(){
	
	const [webList,setWebList] = useState([]); //an array of webinars
	const [show,setShow] = useState(false); 
	const [selected,setSelected] = useState(''); 
	
	//
	
	function handleShowUser(e){
		if(e.target.dataset.delete){
			alert('Click for delete');
			console.log(e.target.closest('tr').dataset.id);
			
			axios.get(`https://${window.location.hostname}:5005/admin/webinar/delete/${e.target.closest('tr').dataset.id}`)
			.then(response => {
				//recieved response
				//console.log(response.data);
				if(response.data){
					setWebList(response.data);
				}
				
			})
			.catch(err => {
				alert('Error')
			}) 
		}else if(e.target.closest('tr').dataset.id){
			setSelected(e.target.closest('tr').dataset.id);
		}	
		
	}
	
	
	useEffect(() => {
		//on first render get all webinars listStyleType
		if(show) return;
		axios.get(`https://${window.location.hostname}:5005/admin/announcements/fetch`)
		.then(response => {
			//recieved response
			//console.log(response.data);
			if(response.data){
				setWebList(response.data);
			}
			
		})
		.catch(err => {
			alert('Error')
		})
		
	},[show])
	
	
	
	
	return(
		<div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',width:'80vw',margin:'2rem auto 5rem auto'}}>
			<Button onClick={() => setShow(!show)} style={{width:'250px',marginBottom:'2rem'}} >Create New Webinar</Button>
			{show?<AdminCreateWebinar />:null}
			<div onClick={(e) => handleShowUser(e)} >
		<table className="table table-striped table-bordered mt-4">
			<thead className="thead-dark">
				<tr>
				  <th scope="col">Announcement's List</th>
				</tr>
			</thead>
			<tbody>
				{webList.map(obj => {
					return <tr data-id={obj._id} ><td><Button  className='mr-5 ml-5 mt-3 mb-3' >{obj.eventName}</Button></td><td><Button data-delete={true} >Delete</Button> </td></tr>
				})}
			</tbody>	
		</table>
			</div>
			{selected?<RegisteredUser  detail={selected} />:null}
		</div>
	)
}