import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import ReactLoading from 'react-loading';
import EachBlog from "./EachBlog"
import NoBlog from "./NoBlog"

import Pagination from '@material-ui/lab/Pagination';

class AllBlogs extends Component{
    constructor(props){
        super(props)

		let TotalBlogs = 0;
		if(this.props.count){
			let count = 0;
			Object.keys(this.props.count).forEach(type => {
				count += this.props.count[type]
			})
			TotalBlogs = count;
		}
		
        this.state = {
			currentTab:1,
			limit:4,
			total:TotalBlogs,
            allBlogs : [],
            isDataReturned: false
        }
        
		this.handlePageChange = this.handlePageChange.bind(this);
    }  
	

	 fetchNewData(tabValue) {
			let requestOptions = {
				params:{
				  skip: (tabValue - 1) * (this.state.limit),
				  limit:this.state.limit
				}
			}
			
			Axios.get(`https://${window.location.hostname}:5005/allblogs`,requestOptions).then(allBlogs => {		
				this.setState({
					allBlogs: allBlogs.data,
					isDataReturned: true,
				})
			}).catch(error => {
				console.log("Axios error")
				
			}) 
	 }
	
    
    componentDidMount(){

		const currentTab = this.state.currentTab;
		this.fetchNewData(currentTab);
    }

	handlePageChange(e,value){
		e.preventDefault();
		this.setState({
			currentTab:value,
			isDataReturned: false,
		})
	}
	
	 // For change in the state
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab) {
     
      this.fetchNewData(this.state.currentTab);
    } else {
     
    }
  }
	
    render(){
        return(
            this.state.isDataReturned ? 
            <>
            {this.state.allBlogs.length>0 ? 
                <div>
					<div className="mt-2 mb-2"  >
						<Pagination count={Math.round(this.state.total/this.state.limit)} page={this.state.currentTab} onChange={this.handlePageChange} color="primary" />
                    </div>
					{this.state.allBlogs.map((data, index) => {
                        return(
                            <EachBlog blogdata={{"eachBlog": data}}/>
                            
                        )
                    })}
                    
                </div>
            : <NoBlog />
            
            }
            </>:<ReactLoading style={{ color: "black", margin: "auto", height: "20%", width: "20%" }} type={"spinningBubbles"} />
            
        )
    }
}

export default AllBlogs;