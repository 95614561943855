import React,{useState,useEffect} from 'react';
import './App.css'; 
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img2 from './assets/I.jpg'; 
import img1 from './assets/D.jpg'; 
import img3 from './assets/image.jpg'; 

import {Link} from 'react-router-dom'; 
import Pagination from '@material-ui/lab/Pagination';
import {Link as MLink} from '@material-ui/core';
import axios from 'axios'; 

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TAGS from './TagsDropdown.js'; 


function StudyMaterial() {
	
	let userInfo = JSON.parse(localStorage.getItem('user'));
	let userRole = userInfo.category
	const [History,setHistory] = React.useState([userRole]);
	const [depth,setDepth] = React.useState(0);  
	
	let [freeNotes,setFreeNotes] = useState([]);
	let [paidNotes,setPaidNotes] = useState([]);
	
	let [totalNotes,setTotalNotes] = useState(0);
	let [currentTab,setCurrentTab] = useState(1);
	let notesLimit = 8;
	
	let [hashVal,setHashVal] = useState(window.location.hash);
	
	
	
	
	function getNotes(tabValue){
		let requestOptions = {
				params:{
				  skip: (tabValue - 1) * (notesLimit),
				  limit:notesLimit
				}
			}
			
		axios.get(`https://${window.location.hostname}:5005/mentee/studyMaterial/${userInfo._id}/${hashVal.slice(1)}`,requestOptions)
			.then(response => {
				console.log(response.data)
				let free = [];
				let paid = [];
				if(response.data.data.length > 0){
					response.data.data.forEach((pdf) => {
						if(pdf.isFree){
							free.push(pdf);
						}else{
							paid.push(pdf);
						}
					})
				}
				
				setFreeNotes(free);
				setTotalNotes(response.data.total);
				setPaidNotes(paid);
			})
			.catch((err) => {
				console.log('Error :', err);
			})
	}


	useEffect( () => {
		getNotes(currentTab)
		
	},[hashVal,currentTab])
	
	
	window.onhashchange = shouldGetNotes;
	
	function shouldGetNotes(){
		if(window.location.hash != hashVal){
			setHashVal(window.location.hash)
		}
	}
	

	
	function getPDF(name,notesId,download){
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let src = `https://${window.location.hostname}:5005/notes/user/${userInfo._id}/getPdf/${notesId}/${name}`
		let z = document.createElement('a');
		z.href = src;
		z.target = 'blank';
		/* if(download){
			z.setAttribute('href', 'application/pdf'
			+ encodeURIComponent(src));
			z.setAttribute('download', name);
		}
 */
		z.click();
	}
	
	function handlePageChange(e,value){
		e.preventDefault();
		setCurrentTab(value);
	}
	
    const handleDelete = (e) => {
		e.preventDefault();
		if(e.target && e.target.closest('a') && e.target.closest('a').dataset.pos){
			let pos = 	Number(e.target.closest('a').dataset.pos)
			//History Length 1 more than pos
			if(History.length == pos+ 1 ){
				return;
			}else{ 

				if(pos + 1 < History.length){
			
					let newHistory = History.slice(0,pos+1)
					if(newHistory.length == 0){
						return;
					}
					setHistory(newHistory)
					setDepth(newHistory.length -1)
					window.location.hash = newHistory[newHistory.length-1]
			}
		  }
			
		}
		
	}
	
	
	
  return (
    <div className="App mt-5" style={{minHeight:'50vh'}} >
		<Breadcrumbs style={{display:'flex',justifyContent:'center'}}  aria-label="breadcrumb">
			{
				History.map((name,index) => {
					return (
						<MLink   onClick={(e) => handleDelete(e)} data-pos={index}  color="inherit" href="/" >
							{name}
						</MLink>
					)
				})
			}
		</Breadcrumbs>
							
							<TAGS  addInChip={(newVal) => {
										window.location.hash = newVal }}
										History={History}
									  setHistory={setHistory}
									 depth={depth}
									 setDepth={setDepth}
							/>  
{freeNotes && freeNotes.length >0?(<div className="card-group mt-5">
	  <div className="card text-dark border-warning" >
		<div className="card-body">
			<table class="table table-hover">
			  <thead>
				<tr class="table-info">
				 <th scope="col">S.No</th> 
				  <th scope="col">NAME</th> 
				  <th scope="col">PDF</th>
				</tr>
			  </thead>
				  <tbody>
				  {
					  freeNotes.map( (note,index) => {
						  return (
									 <tr class="table">
									  <th scope="row">{index+1}</th>
									  <td>{note.pdfName}</td> 
									  <td><Link  data-link={note.pdfLink} data-id={note._id} onClick={(e) => getPDF(e.target.dataset.link,e.target.dataset.id,false)}  >View PDF</Link></td>
								</tr>
							)
					  })
				  }
				
				
			  </tbody> 
			</table> 
		<Pagination count={Math.ceil(totalNotes/notesLimit)} page={currentTab} onChange={handlePageChange} color="primary" />      
	</div>
  </div>
</div>):null}


    </div>
  );
}

export default StudyMaterial;
