import React,{useEffect,useState} from 'react';
import DisplayMockTests from './DisplayMockTests';
import DisplayInstructions from './DisplayInstructions';
import StartTest from './StartTest';
import Result from './Result';
import CombineResult from './CombineResult';

/* Parent Component - Mentee can see mock test and start a test */
export default function MockTest(){
	
	/* All Categories Data */
	const [data,setData] = useState('');
	
	/* Determine current stage */
	//2 - start test
	//3 - display result
	let initial = 0;
	//alert(JSON.parse(localStorage.getItem('started')));
	if(JSON.parse(localStorage.getItem('started')) == 1 && JSON.parse(localStorage.getItem('TIME'))){
		const timeInfo = JSON.parse(localStorage.getItem('TIME'))
		let now = new Date().getTime()
		//need to check
		if(now > timeInfo.end){
			//alert('Time has expired for giving test');
		}else{
			initial = 4;
		}
	}
	const [stage,setStage] = useState(initial);
	
	/* Selected Test Unique Id and its info */
	const [selectedTestId,changeTestId] = useState('');
	const [selectedTestInfo,changeTestInfo] = useState('');
	

	
	/* Changing Selected Test Info Vaiable */
	useEffect(() => {
		if(!selectedTestId || !data) return;
		console.log('Current Stage is :',stage);
		const Filtertest = data.filter((test) => test._id === selectedTestId);
		console.log(Filtertest);
		localStorage.setItem('TEST_INFO',JSON.stringify({...Filtertest[0]}));
		changeTestInfo(Filtertest[0]);
	},[selectedTestId])
	
	
	
	
	return(
		<>
		    {(stage === 0)?<DisplayMockTests  setStage={setStage} changeTestId={changeTestId} data={data} setData={setData}  />:null}
			{(stage === 1 && selectedTestInfo)?<DisplayInstructions  setStage={setStage} Testinfo={selectedTestInfo}  />:null}
			{(stage === 2)?<StartTest  setStage={setStage} Testinfo={selectedTestInfo}  />:null}
			{(stage === 3 && selectedTestInfo.isDividedInSection)?<CombineResult   Testinfo={selectedTestInfo} setStage={setStage}  />:null}
			{(stage === 3 && !selectedTestInfo.isDividedInSection)?<Result   Testinfo={selectedTestInfo} setStage={setStage}  />:null}
			{(stage === 4)?<Continue   Testinfo={selectedTestInfo} setStage={setStage} />:null}
		</>
	)
}


function Continue({setStage}){
	return(
	<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100vw'}} >
		<p style={{marginLeft:'-3.5%'}}>Do you want to Continue the Test</p>
		<div>
			<button className="btn btn-primary" style={{padding: '12% 26%', marginRight: '7%',marginLeft: '-111%'}} onClick={() => setStage(2)} >YES</button>
			<button className="btn btn-primary" style={{padding: '12% 26%'}} onClick={() => setStage(3)} >NO</button>
		</div>
	</div>
	)
}