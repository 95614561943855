import React, {Component} from 'react'
import Axios from 'axios'
import ReactLoading from 'react-loading';

import EachBlog from "./EachBlog"
import NoBlog from "./NoBlog"

import Pagination from '@material-ui/lab/Pagination';

const Transformation = {
	'jee':'jee_blogs',
	'neet':'neet_blogs',
	'career':'career_blogs',
	'development':'development_blogs',
}

class CategoryBlog extends Component{
    constructor(props){
        super(props)
		
		let TotalBlogs = 0;
		if(this.props.count && this.props.match.params.category){
			console.log(this.props.count);
			let cat = this.props.match.params.category;
			TotalBlogs = this.props.count[Transformation[cat]];
			
		}
		
        this.state = {
			currentTab:1,
			limit:4,
			total:TotalBlogs,
            category: this.props.match.params.category,
            allBlogs : [],
            isDataReturned: false
        }
		
		this.handlePageChange = this.handlePageChange.bind(this);
    }
	
  fetchNewData(tabValue) {
			let requestOptions = {
				params:{
				  skip: (tabValue - 1) * (this.state.limit),
				  limit:this.state.limit
				}
			}
			
		  Axios.get(`https://${window.location.hostname}:5005/blogs/`+this.state.category,requestOptions).then(allBlogs => {
            console.log(allBlogs);
            // if(allBlogs.data.length > 0){
                this.setState({
                    allBlogs: allBlogs.data,
                    isDataReturned: true
                })
            // }
            
        }).catch(error => {
            console.log("Axios error")
            console.log(error)
        })
	 }
	
	

    componentDidMount(){
         console.log('Category');
		console.log(this.props.count)
		const currentTab = this.state.currentTab;
		this.fetchNewData(currentTab);
    }
    
	handlePageChange(e,value){
		e.preventDefault();
		this.setState({
			currentTab:value,
			isDataReturned: false,
		})
	}
	
	 // For change in the state
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab) {
      console.log("Tab is changed", prevState.currentTab, this.state.currentTab);
      this.fetchNewData(this.state.currentTab);
    } else {
      console.log("Tab not changed");
    }
  }
	

    render(){
        return(
            this.state.isDataReturned ? 
            <>
            {this.state.allBlogs.length>0 ? 
                <div>
				     <div className="mt-2 mb-2"  >
							<Pagination count={Math.round(this.state.total/this.state.limit)} page={this.state.currentTab} onChange={this.handlePageChange} color="primary" />
					</div>
				   {this.state.allBlogs.map((data, index) => {
                        return(
                            <EachBlog blogdata={{"eachBlog": data}}/>                            
                        )
                    })}
                    
                </div>
            : <NoBlog />
            
            }
            </>:<ReactLoading style={{ color: "black", margin: "auto", height: "20%", width: "20%" }} type={"spinningBubbles"} />
            
        )
    }
}

export default CategoryBlog;