import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Card, Button} from 'react-bootstrap';

export default function QuestionPalette({sectionID,selectedQuestion,questions,changeQuestion,setStage}){
	
	//console.log(JSON.parse(localStorage.getItem('TEST_ANSWERS')));
	let answers = JSON.parse(localStorage.getItem(`${sectionID}_TEST_ANSWERS`));
	//question palette
	const palette = questions && questions.map((question,index) => {
		console.log(question);
		//
		let currentView = (selectedQuestion.id === question)?{backgroundColor:'pink'}:{};
		if(answers[question]){
			console.log('Question is present in answers');
			let colour = answers[question]['color'];
			return <span  data-index={index} data-questionid={question} className={colour} style={{margin:'1rem 0.5rem 0.5rem 0.5rem',padding:'0.4rem',borderRadius:'15px 0 0 0',cursor:'pointer',display:'inline-block',...currentView}} >{index+1}</span>						
		}else{
			return <span  data-index={index} data-questionid={question} className='grey' style={{margin:'1rem 0.5rem 0.5rem 0.5rem',padding:'0.4rem',borderRadius:'15px 0 0 0',cursor:'pointer',display:'inline-block',...currentView}} >{index+1}</span>					
		}
	}); 

	//handleQuestionChange
	function handleQuestionChange(e){
		if(e.target.dataset.questionid && e.target.dataset.index){
			let id = e.target.dataset.questionid; 
			let index = e.target.dataset.index;
			if(selectedQuestion.id === id){
				//alert('Same Question');
				return;
			}
			if(!answers[id]){
				//alert('Question is being visited first time')
				//console.log('Question is being visited first time');
				//console.log('Question is not answered');
				let myArray = {};
				myArray[id] = {color:'red'};
				//setAnswers({...answers,...myArray});
				localStorage.setItem(`${sectionID}_TEST_ANSWERS`,JSON.stringify({...answers,...myArray}))
		     }
			changeQuestion({id,index});
		}
	}
	
	return(
		    <Col md={3} >
				   <Button style={{margin:'1rem 0 1rem 0'}} onClick={() => setStage(3)} >Submit</Button>
					<Card>
						<Card.Header>
							<Card.Title>Questions</Card.Title>
						</Card.Header>
						<Card.Body onClick={(e) => handleQuestionChange(e)} >
						   {palette}
						</Card.Body>
					</Card>
			</Col>
		 )
}