import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {Container,Row,Col, Table, Button } from 'reactstrap';
//import TestDetail from './TestDetail';  //details of each test
//import DisplayAllQuestions from '../DisplayAllQuestions'; //all questions within a test
import axios from 'axios';
//import ResultTable from '../Actions/ResultTable';
import {Checkbox} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TagsDropdown from './AdminTagsDropdown.js'




function getPDF(name,secretId,notesId){
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let role = userInfo.role;
		if(window.location.href.includes('/admin/dashboard/studymaterial') && secretId){
			role = 'admin';
		}
		
		
		let src = `https://${window.location.hostname}:5005/notes/user/${userInfo._id}/getPdf/${notesId}/${name}`
		let z = document.createElement('a');
		z.href = src;
		z.target = 'blank';
		z.click();
}

/* Parent Component MyAllTestsHistory  */
export default function NotesPanel({secretId}){
	
	const [data,setData] = useState([]);
	const [detail,setDetail] = useState('');
	const [stage,setStage] = useState(0);
	const [drawer,setDrawer] = useState(false);
	const [drawerData,setDrawerData] = useState([]);


	useEffect(() => {
		//get role 
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let role = userInfo.role;
		if(window.location.href.includes('/admin/dashboard/studymaterial') && secretId){
			role = 'admin';
		}else{
			role = 'mentor'
		}
		
		axios.get(`https://${window.location.hostname}:5005/${role}/studyMaterial/all/${userInfo._id}`)
			.then(response => {
				setData(response.data);
			})
			.catch((err) => {
				console.log('Error :', err);
			})
	},[detail])
	
	function handleClick(e){
		e.preventDefault();
		let userInfo = JSON.parse(localStorage.getItem('user'));	
		
		if(e.target.dataset.id && e.target.dataset.publish){
			if(!secretId || !window.location.href.includes('/admin/dashboard/studymaterial')) return;
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId })
			};

			const endpoint = `https://${window.location.hostname}:5005/admin/studyMaterial/publish/${e.target.dataset.publish}/${e.target.dataset.id}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						setData(data);
						//IF Drawer is Open , Close It
						if(drawer){
							setDrawer(false);
						}
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
			
		}else if(e.target.dataset.id && e.target.dataset.pricing){
			
			if(!secretId || !window.location.href.includes('/admin/dashboard/studymaterial')) return;
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId })
			};

			const endpoint = `https://${window.location.hostname}:5005/admin/studyMaterial/pricing/${e.target.dataset.pricing}/${e.target.dataset.id}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						setData(data);
						
						//IF Drawer is Open , Close It
						if(drawer){
							setDrawer(false);
						}
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
			
		}else if(e.target.dataset.id){
			setDetail(e.target.dataset.id);
		}else if(e.target.dataset.delete){
			alert('Do you want to delete this test ?');
			//get role 
			let userInfo = JSON.parse(localStorage.getItem('user'));
			let role = userInfo.role;
			if(window.location.href.includes('/admin/dashboard/studymaterial') && secretId){
				role = 'admin';
			}
			axios.get(`https://${window.location.hostname}:5005/${role}/studyMaterial/delete/${e.target.dataset.delete}/${userInfo._id}`)
			.then(response => {
				setData(response.data);
				//IF Drawer is Open , Close It
				if(drawer){
					setDrawer(false);
				}
			})
			.catch((err) => {
				console.log('Error :', err);
			})
		}
	}
	
	function dataList() {
		if(!data) return;
		return data.map((currData, i) => {
			return <HistoryData secretId={secretId} drawer={drawer} toggleDrawer={toggleDrawer}   data={currData} key={i} />;
		})
	}
	
	 const toggleDrawer = (anchor, open,data) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		  return;
		}

		setDrawer(open);
		setDrawerData(data);
	  };

	
	function updateStudyMaterialTags(newTags,nid){
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId ,newTags:newTags})
			};

			const endpoint = `https://${window.location.hostname}:5005/admin/studyMaterial/update/tags/${nid}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						setData(data);
						//IF Drawer is Open , Close It
						if(drawer){
							setDrawer(false);
						}
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
}

	
	
	return(
		<Container className='justify-center mt-2' style={{marginBottom:'15rem'}} >
					<Drawer anchor={'right'} open={drawer}	 onClose={toggleDrawer('right', false,[])}>
								<AdminOptions updateTags={updateStudyMaterialTags} handleClick={handleClick} data={drawerData} secretId={secretId} />
					</Drawer>
					<Row>
					  {(stage == 0)?<Col>
							<Table striped>
								<thead>
									<tr>
						
										<th>PDF Name</th>
										<th>Status</th>
										{secretId?<th>Pricing</th>:null}
										<th>PDF Link</th>
										{secretId?<th>Actions</th>:null}
										
									</tr>
								</thead>
								<tbody onClick={(e) => handleClick(e)}>
									{dataList()}
								</tbody>
							</Table>
					     </Col>:null}
						 
			
						  
						 {(stage == 2)?<Col >
							<Button onClick={() => setStage(0)}>Back</Button>
									{/* <ResultTable detail={detail} secretId={secretId} /> */}
						</Col>:null}
				  </Row>
			  </Container>
	)
}


const HistoryData = (props) => (

	
	<tr >
		<td>{props.data.pdfName}</td>
		
		<td>{props.data.isPublished?<span style={{color:'green'}} >Published</span>:<span style={{color:'red'}} >Not Published</span>}</td>
		
		{props.secretId && window.location.href.includes('/admin/dashboard/studymaterial')?(
			<td>{props.data.isFree?<span style={{color:'green'}} >FREE</span>:<span style={{color:'red'}} >PAID</span>}</td>
			):null
		}
		
		<td><Link  data-link={props.data.pdfLink} data-id={props.data._id} onClick={(e) => getPDF(e.target.dataset.link,props.secretId,e.target.dataset.id)}  >View PDF</Link></td>
	
		<Button onClick={props.toggleDrawer('right', true,props.data)}>More</Button>
	</tr>
)

const AdminOptions  = ({data,secretId,handleClick,updateTags}) => {
	
   const [chipData,setChipData] = useState(data.tags?data.tags:[]);

	
	const useStyles = makeStyles((theme) => ({
		  root: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			listStyle: 'none',
			padding: theme.spacing(0.5),
			margin: 0,
		  },
		  chip: {
			margin: theme.spacing(0.5),
		  },
		}));
	
	const classes = useStyles();
	  

    const handleDelete = (chipToDelete) => () => {
		setChipData(chipData.filter((key,index) => index != chipToDelete ))
	  }
	
	
 return (
	<div style={{marginLeft:'30px',marginTop:'15px',minWidth:'70vw',display:'flex',justifyContent:'center',flexDirection:'column'}} onClick={(e) => handleClick(e)} >
		
		{secretId && window.location.href.includes('/admin/dashboard/studymaterial')?(
			<div>
				<p><b>Associated Tags</b></p>
				<TagsDropdown  addInChip={(newVal) => {
						let newArray = [...chipData]
						newArray.push(newVal)
						setChipData(newArray)
					} 
				}/> 
					
				<Paper component="ul"  className={classes.root} >
				  {chipData.map((tag,index) => {
					
					return (
					  <li key={index}>
						<Chip
						  label={tag}
						  onDelete={handleDelete(index)}
						  className={classes.chip}
						/>
					  </li>
					)
				  })}
				 </Paper>
				 
				 <br/>
				 <br/>
				 <Button onClick={() => updateTags(chipData,data._id)} >Update Tags</Button>
			</div>
		):null}
		
		<h3>{data.pdfName}</h3>
		<hr/>
		
	
		
		{JSON.parse(localStorage.getItem('user')).role.toLowerCase() === 'mentor'?(
								<p style={{fontSize:'30px'}} >It is {data.isPublished?<span style={{color:'green'}} >Published</span>:<span style={{color:'red'}} >Not Published</span>} </p>):null
		}
	
		
		<p style={{fontSize:'30px'}} >Click to 
		<Button style={{margin:'10px'}} data-delete={data._id} color="danger">DELETE</Button>
		Study Material
		</p>
		
		
		{secretId && window.location.href.includes('/admin/dashboard/studymaterial')?(
			<p style={{fontSize:'30px'}} >Click to  {data.isPublished ?<Button  style={{margin:'10px'}} data-publish={'UNPUBLISH'} data-id={data._id} color="danger">UnPublish</Button> : <Button style={{margin:'10px'}} data-publish={'PUBLISH'} data-id={data._id}  color="success">Publish</Button>}
			Study Material </p>):null
		}
		
		
		
		
		{secretId && window.location.href.includes('/admin/dashboard/studymaterial')?(
			<p style={{fontSize:'30px'}} >Click to make it  {data.isFree ?<Button style={{margin:'10px'}} data-pricing={'PAID'} data-id={data._id} color="danger">PAID</Button> : <Button style={{margin:'10px'}} data-pricing={'FREE'} data-id={data._id}  color="success">FREE</Button>}
			</p>):null
		}
		
		
		
	</div>
		)
}



