import React from 'react';
import Dropdown from 'react-multilevel-dropdown';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import FolderIcon from '@material-ui/icons/Folder';
import Avatar from '@material-ui/core/Avatar';

import {TAGS_LIST_MOCK_TEST}  from '../Tags/TagList.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


/* 
	Props:
		addInChip: [] which will be updated with new tags

 */
 
export default function  TagsDropDownList({addInChip,History,setHistory,depth,setDepth}){
	
   let userRole = JSON.parse(localStorage.getItem('user')).category
   const classes = useStyles();
   const [selectedParent,setSelectedParent] = React.useState([userRole]);
   

	
	
	
	function addIt(targetElem) {
		if(targetElem.closest('li') && targetElem.closest('li').dataset.name){
		    let subCategory = targetElem.closest('li').dataset.name;
			addInChip(subCategory)
	  }
	}
	 
	
	
	function generateSubList(subList,currDepth=0,){
						
		return (
			<>
				{subList.map((cat,index) => {
					return	 (
					    <>
				
							<ListItem   onClick={(e) => addIt(e.target)}  data-parent={cat.parent} style={currDepth == depth && cat.parent == History[History.length-1]?{display:'block'}:{display:'none'}} data-name={cat.name}  >
							<ListItemAvatar>
							  <ListItem button>
								<Avatar>
								  <FolderIcon />
								</Avatar>	  
							  </ListItem>								
							  </ListItemAvatar>
							  <ListItem button>
								<ListItemText
								primary={cat.name}						
							  />  
							  </ListItem>
							  
							 
							</ListItem>
							
							 { cat.sub.length > 0?generateSubList(cat.sub,currDepth+1):null}
						</>
						)
					})
					
				}
			</>	  
			)		  
		
	
	}
	

	function handleClick(e){
		
		if(e.target.closest('li') && e.target.closest('li').dataset.parent){
			
			let newParent = e.target.closest('li').dataset.name;
			let prevParent = selectedParent[0];
			let newDepth = depth + 1;
			let newHistory	= [...History,newParent];
			if(newDepth == newHistory.length){
				return;
			}
			setDepth(newDepth)
			//setSelectedParent([newParent,prevParent])
			setHistory(newHistory)
		}
		
		
	}


	return (
		<div className={classes.demo}>
            <List style={{display:'inline-flex'}} onClick={(e) => handleClick(e)} >
				{generateSubList(TAGS_LIST_MOCK_TEST[userRole])}
            </List>
          </div>
	)

}



 
/*
---OLD LOGIC
	function generateSubList(subList){
		return (
			<Dropdown.Submenu position={'right'}>
				{subList.map(cat => {
					return	 (
							<Dropdown.Item data-name={cat.tags}  >
								{cat.name}
								{cat.sub.length>0?generateSubList(cat.sub):null}
							 </Dropdown.Item>
						)
					})
				}
			</Dropdown.Submenu>	  
			)		  
		
		
 	return (
		<Dropdown
			  title='Click to filter By Tags'
			  className={'multilevel-ddown-head font-weight-bold nav-link'}
				>
				{
					TAGS_LIST_MOCK_TEST[userRole]?(
						TAGS_LIST_MOCK_TEST[userRole].map(category => {
							return	(
								<Dropdown.Item	onClick={(e) => addIt(e.target)} data-name={category.tags} >
									{category.name}
									{category.sub.length > 0?generateSubList(category.sub):null}
								</Dropdown.Item>
							)
						})
					):null
				}
			</Dropdown>
				  
	)

	/* 	
	function goBack(newParent){		
		let newHistory = History.slice(0,-1)
		if(newHistory.length <=0 || depth == 0){
			return;
		}
		setDepth(depth-1);
		setHistory(newHistory);
	} */
	
/* 	function  mainList () {
		return TAGS_LIST_MOCK_TEST[userRole]?(
						TAGS_LIST_MOCK_TEST['CAREER'].map(category => {
							return	(
								<ListItem 	 onClick={(e) => addIt(e.target)} data-name={category.tags} >
									<ListItemAvatar>
										<Avatar>
										  <FolderIcon />
										</Avatar>
									  </ListItemAvatar>
									  <ListItemText
										primary={category.name}
										secondary={secondary ? 'Secondary text' : null}
									 />
									
								</ListItem>
							)
						})
					):null
				
	} */



