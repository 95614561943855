import React, {Component} from "react"
import { useEffect } from "react";
import {setTotalReactPackages} from "react";
import {Link} from 'react-router-dom'
import Login from'./Login'
import axios from 'axios'
import brand from '../../assets/brand.png'
import {InputGroup, Button} from 'reactstrap';

class Otp extends React.Component {
  constructor(props)
  {
    super(props)
    this.state={
      OTP:-1
    }
  }
  handleChange=(e)=>{
    e.preventDefault()
    this.setState({
      [e.target.name]:e.target.value
    })
  }
   
 
  handleSubmit = (event) => {
    event.preventDefault()
   /*  const urlParams = new URLSearchParams(window.location.search);
const data = urlParams.getAll('q');
 
const values=JSON.parse(data);
    */
 
    
   /* console.log("recieved otp"+event.target[0].value) */
   // var data1;
	let email = localStorage.getItem('email');
	let OTP = this.state.OTP;
	if(!email) return;
    fetch(`https://${window.location.hostname}:5005/otp_verify`,{
		method:'POST',
		headers:{'Content-Type':'application/json'},
		body:JSON.stringify({OTP,email})
	})
    /* .then(response => response.json()) */
/*     .then((data)=>{ */
      
    /*   console.log(data);
      console.log(event.target);
      
      if(data==this.state.OTP)
      {
        axios.post(`https://${window.location.hostname}:5005/signUp`, values)
        .then(response => {
    
            console.log("Response is - ")
            console.log(response)
            this.setState({register:false})
    
        }).catch(function(err){      
            console.log("catch err is ");
            console.log(err)  
            alert("Alert of  Error")
        });
        console.log("sucess")
       */
    .then(response => {	
	  if(response.ok){
		    this.props.history.push('/login'); 
			//alert("Account Updated succesfully");
	  }
	else{
	  alert("!!!oops🥴 \n The OTP you have entered is incorrect ,Please do check carefully.");
	  console.log("failed");
	}
  
    });
  
     
    
  }
  render() {
    return (
      <div>
             <div className="d-flex align-items-center " style={{height:"90vh"}}>
                <div className="container ">
                        <div className="row justify-content-center">
                                    <div className="card border-dark card-group shadow text-white bg-light col-8 col-md-5">
                                        <div className="card-body m-3">                                         
                                             <form onSubmit={this.handleSubmit}>
                                               <h3 className="text-dark mb-5 text-center">🥳Hey, you are almost there!</h3>
                                               <p className="text-success font-weight-bold text-center">Enter the OTP we just sent to your email</p>
                                                <input
                                                 className="form-control w-100 mb-3  d-block"
                                                  onChange={this.handleChange}
                                                  type="text"
                                                  name="OTP"
                                                  ref={node => (this.inputNode = node)}
                                                />
                                             <InputGroup className="mb-3">
                                                    <Button type="submit" block color="dark">
                                                        Submit 
                                                    </Button>
                                                </InputGroup>
                                            </form>
                                      </div>
                                    </div> 
                                </div>                    
                        </div>
                </div>
      </div>     
    )
  }
}
export default Otp;