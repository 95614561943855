//ADMIN PART
import React from 'react';
import Dropdown from 'react-multilevel-dropdown';
import {TAGS_LIST_STUDY_MATERIAL}  from './TagList.js';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
/* 
	Props:
		addInChip: [] which will be updated with new tags

 */
export default function  TagsDropDownList({addInChip}){
	
	const [role, setRole] = React.useState('JEE');
	
	 const handleChange = (event) => {
		setRole(event.target.value);
	 };
	
	function addIt(targetElem) {
		if(targetElem.closest('li') && targetElem.closest('li').dataset.name){
		    let subCategory = targetElem.closest('li').dataset.name;
			console.log(subCategory)
			addInChip(subCategory)
			
	  }
	}
	
	function generateSubList(subList){
		return (
			<Dropdown.Submenu position={'right'}>
				{subList.map(cat => {
					return	 (
							<Dropdown.Item data-name={cat.name}  >
								{cat.name}
								{cat.sub.length>0?generateSubList(cat.sub):null}
							 </Dropdown.Item>
						)
					})
				}
			</Dropdown.Submenu>	  
			)		  
		
	
	}
	
	return (
	<>
		<div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
			<InputLabel id="demo-simple-select-label">Select Category</InputLabel>
			<Select
			  class="ml-3"
			  labelId="demo-simple-select-label"
			  id="demo-simple-select"
			  value={role}
			  onChange={handleChange}
			>
			  <MenuItem value={'JEE'}>JEE</MenuItem>
			  <MenuItem value={'NEET'}>NEET</MenuItem>
			  <MenuItem value={'CAREER'}>CAREER</MenuItem>
			</Select>
		</div>
		<br />
		<Dropdown
			  title={'Click to Select Folder where to show theTest'}
			  className={'multilevel-ddown-head  font-weight-bold nav-link'}
				>
				
				{
					TAGS_LIST_STUDY_MATERIAL[role].map(category => {
						return	(
							<Dropdown.Item	onClick={(e) => addIt(e.target)} data-name={category.name} >
								{category.name}
								{category.sub.length > 0?generateSubList(category.sub):null}
							</Dropdown.Item>
						)
					})
					
				}
				
			</Dropdown>
	</>		  
	)
}



/* 
<Dropdown.Item
					onClick={(e) => addIt(e.target)}
					data-name="jee"
				  >
					JEE
					<Dropdown.Submenu position={'right'}>
					  <Dropdown.Item data-name='main'  >
						 Mains
					  </Dropdown.Item>
					  <Dropdown.Item data-name='advanced'  >
						 Advanced
					  </Dropdown.Item>
					</Dropdown.Submenu>
				  </Dropdown.Item>
				  <Dropdown.Item
					onClick={(e) => addIt(e.target)}
					data-name="neet"
				  >
					NEET
					<Dropdown.Submenu position={'right'} >
					  <Dropdown.Item data-name='maths' >
						Maths
					  </Dropdown.Item>
					  <Dropdown.Item data-name='physics' >
						Physics
					  </Dropdown.Item>
					</Dropdown.Submenu>
				  </Dropdown.Item>
				  <Dropdown.Item
					onClick={(e) => addIt(e.target)}
					data-name="college"
				  >
					 College
					  </Dropdown.Item>
		
 */
