import React,{useState,useEffect} from 'react';

import { Control, LocalForm, Errors } from 'react-redux-form';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText, InputGroupAddon, InputGroup } from 'reactstrap';

/* Child  Component of Create Mock Test */
export default function StepOne({setStage,required}){
	
	const [name,setName] = useState('');
	const [description,setDescription] = useState('');
	
	useEffect(() => {
		if(localStorage.getItem('test_name')){
			setStage(1); //move to  question adding stage
		}
	},[])
	
	function onChangeHandler(val){
		if(!name) return;
		localStorage.setItem('test_name',name);
		localStorage.setItem('test_description',description);
		setStage(1); //move to  question adding stage
	}
	
	return(
		 <div className="container">
                 <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card-group shadow">
                            <div className="card border-warning text-white">
                                <div className="card-body m-3">
                                    <LocalForm onSubmit={(values) => onChangeHandler(values)}>
                                        <h3 className="text-warning mb-0">Create a Test </h3>
                                        <p className="text-muted font-weight-bold">Enter the Test Details</p>

                                        <InputGroup className="mb-3 d-block">
                                            <Control.text model=".category"
                                                type="text"
                                                id="category"
                                                name="name"
												value={name}
												onChange={(e) => setName(e.target.value)}
                                                placeholder="Enter Name of Test"
                                                className="form-control w-100"
                                         
                                            />
                                            <Errors
                                                className="text-danger"
                                                show="touched"
                                                model=".category"
                                                messages={{
                                                    required: 'This is a Required Field!'
                                                }}
                                            />
                                        </InputGroup>

                                        <InputGroup className="mb-3 d-block">
                                            <Control.text model=".description"
                                                id="description"
                                                name="description"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
                                                placeholder="Description of  the Test"
                                                className="form-control w-100"
                                            />
                                        </InputGroup>


                                        <InputGroup className="mb-3">
                                            <Button type="submit" block color="info">
													Add Questions
                                            </Button>
                                        </InputGroup>

                                    </LocalForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
		)

}

