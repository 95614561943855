import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Radio} from "@material-ui/core";
import {Row,Col,Container} from "react-bootstrap";
import MUIRichTextEditor from 'mui-rte';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import youtube from "../../assets/social/yt1.png";
import facebook from "../../assets/social/Fb1.png";
import instagram from "../../assets/social/Insta1.png";
import whatsapp from "../../assets/social/WA1.png";
import twitter from "../../assets/social/twitter1.png"



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
	marginRight:'10px',
	marginLeft:'10px',
	marginBottom:'5rem',
	marginTop:'2rem',
  },
  webinarPic:{
	width:'100%',
	height:'auto',
	margin:'1rem 0 3rem auto',
  },
}));

export default function WebinarRegistration(){
	
	const classes = useStyles();
	
	const [data,setData] = useState([]);
	const [selected,setSelected] = useState(0);
	
	function handleWebinarRegistration(e){
		//alert('CALLED')
		if(!e.target.closest('button').dataset.id) return;
		if(!localStorage.getItem('user')){
			let redirect = window.location.href;
			localStorage.setItem('redirect',redirect);
			window.location.href = '/login';
		}else{
			let userInfo = JSON.parse(localStorage.getItem('user'));
			//let webinarId = e.target.closest('button').dataset.id;
			let webinarId = data && data[selected] && data[selected]['_id'];
			//alert(webinarId);
			if(!localStorage.getItem('user')) return;
			let name = userInfo.firstName + ' ' + userInfo.lastName;
			let uid = userInfo._id;
			let email = userInfo.email;
			fetch(`https://${window.location.hostname}:5005/mentee/webinar/register/${webinarId}`,{
				method:'POST',
				headers:{'Content-Type':'application/json'},
				body:JSON.stringify({name,uid,email})
			})
			.then(res => {
				if(res.ok){
					alert('Successfully Registered')
				}else{
					alert('You may have registered before')
				}
			})
			.catch(err => {
				alert('Server Error')
			})
		} 
		
		
	}
	
	useEffect(() => {
		fetch(`https://${window.location.hostname}:5005/admin/announcements/fetch`)
		.then(res => {
		    if(res.ok){
				//alert('ok')
			   res.json().then(data => {
				    //console.log(data); 
					setData(data.reverse());
				}) 
			}else{
				alert('error')
			}
		})
		.catch(err => {
			alert('Error')
		})
	},[])
	
	
	function handleToggle(e){
		if(!e.target.closest('span2').dataset.unique) return;
		setSelected(e.target.closest('span2').dataset.unique);
	}
	
	
	if(data.length == 0){
		return (
		<>
			<div
                    style={{
                        display: "flex",
                        outline: "none !important",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        marginTop: "1rem",
                        marginBottom: "-5px",
                        alignItems: "center",
                        marginLeft: "-20px",
						minHeight:'40vh',
                    }}
                >
                    <a
                        href={
                            ""
                        }
                        target="_blank"
                    >
                        <img
                            src={youtube}
                            style={{
                                width: "45px",
                                height: "45px",
                                marginRight: "-12px",
                            }}
                        />
                    </a>
                    <a
                        href={""}
                        target="_blank"
                    >
                        <img
                            src={instagram}
                            style={{
                                width: "56px",
                                height: "56px",
                                marginRight: "-18px",
                            }}
                        />
                    </a>
                    <a
                        href={""}
                        target="_blank"
                    >
                        <img
                            src={facebook}
                            style={{ width: "50px", marginRight: "-12px" }}
                        />
                    </a>
                    <a
                        href={""}
                        target="_blank"
                    >
                        <img
                            src={twitter}
                            style={{ width: "50px", marginRight: "-12px" }}
                        />
                    </a>
                    <img
                        src={whatsapp}
                        style={{ width: "50px", marginRight: "-12px" }}
                    />
                </div>
				<h3 style={{textAlign:'center'}} ><b>#Mento Meet </b></h3>
			</>
		)
		
	}
	
	return (
		<div className={classes.root} >
			<div  >
			 
					{data.map((ob,index) => (
					   <span2  
							id={ob._id} 
							onClick={(e) => handleToggle(e)}
							data-unique={index} 
							style={{margin:'0px'}}
						>
							<Radio
							  checked={index == selected}
						  />
						</span2>
					))}
				 
				</div>
			
			{/* <div style={{margin:'0 auto 2rem auto'}} onClick={(e) => handleToggle(e)}>
			 
				{data.map((ob,index) => <button id={ob._id} data-unique={index} >{index + 1}</button>)}
			 
</div> */}
		  <Container style={{width:'100vw',display:'flex',justifyContent:'space-around'}}>
		
			<Row>
			  <Col  xs={12} md={6} style={{display:'flex',justifyContent:'center'}} >
				<img className={classes.webinarPic} src={`https://${window.location.hostname}:5005/mentee/webinar/image/${data && data[selected] && data[selected]['banner']}`} alt=' ' />
			  </Col>
			   <Col xs={12} md={6} >
 					<h1 style={{color:'#ffc107',fontSize:'1.5rem',fontWeight:'400',textShadow:'none'}} >{data && data[selected] && data[selected]['eventName'].toUpperCase()}</h1>
					<h1 style={{color:'#ffc107',fontSize:'2.5rem',fontWeight:'800',textShadow:'none'}} >WEBINAR</h1>
					<p style={{fontWeight:'900',fontSize:'1rem'}} >{data && data[selected] && data[selected]['mode']}</p>
				
					<div style={{width:'50%',border:'2px solid yellow',marginBottom:'1rem'}} ></div>
					{/* <h4 style={{fontWeight:'900',marginBottom:'1rem',marginTop:'1rem',whiteSpace:'no-wrap',fontStyle:'italic'}} >About Webinar?</h4> */}
						{/* <p style={{width:'50%',textAlign:'left'}} >{data && data[selected] && data[selected]['description']}</p> */}
					<MUIRichTextEditor 
							label="Start .."
							value={data && data[selected] && data[selected]['description']}
							controls={[]}
							readOnly={true}
							customControls={[
									{
										name: "superscript",
										icon:<ArrowUpwardIcon />,
										type:'inline',
										inlineStyle:{
											fontSize:'x-small',
											verticalAlign:'super'
										}
									},
									{
										name: "subscript",
										icon: <ArrowDownwardIcon />,
										type:'inline',
										inlineStyle:{
											fontSize:'x-small',
											verticalAlign:'sub'
										}
									}
									
								]}
					/>
					<br />
					<br />
					<h4 style={{whiteSpace:'nowrap'}} ><b>Event On </b>: {data && data[selected] && new Date(data[selected]['date']).toLocaleDateString('hi')}</h4>
					<h4 style={{whiteSpace:'nowrap'}} ><b>Event Timing </b> : {data && data[selected] && new Date(data[selected]['date']).toLocaleTimeString('hi')}</h4>
				<div
                    style={{
                        display: "flex",
                        outline: "none !important",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        marginTop: "1rem",
                        marginBottom: "-5px",
                        alignItems: "center",
                        marginLeft: "-20px",
                    }}
                >
				<Button  onClick={(e) => handleWebinarRegistration(e) } data-id={true}
					 variant="contained" style={{backgroundColor:"#f2c700",color:'white',borderRadius:'20px',width:'10rem',fontSize:'0.8rem',fontWeight:'800',height:'2.8rem',margin:'2.3rem auto 10px auto'}} ><span style={{zIndex:'8888888',color:'black'}} >Register Now</span></Button>	
                <Button  onClick={(e) => window.location.href='https://calendar.google.com/event?action=TEMPLATE&tmeid=MzhvcTdqZzdtMHF1cmg0OWVmaXE1a2drY2Egb2ZmaWNpYWxtZW50b21lZXRAbQ&tmsrc=officialmentomeet%40gmail.com' } data-id={true}
					 variant="contained" style={{backgroundColor:"#f2c700",color:'white',borderRadius:'20px',width:'10rem',fontSize:'0.8rem',fontWeight:'800',height:'2.8rem',margin:'2.3rem auto 10px auto'}} ><span style={{zIndex:'8888888',color:'black'}} >Add to calendar</span></Button>	
				<Button  onClick={(e) => window.location.href='https://meet.google.com/mvy-ivdv-udf' } data-id={true}
					 variant="contained" style={{backgroundColor:"#f2c700",color:'white',borderRadius:'20px',width:'10rem',fontSize:'0.8rem',fontWeight:'800',height:'2.8rem',margin:'2.3rem auto 10px auto'}} ><span style={{zIndex:'8888888',color:'black'}} >Webinar Meeting Link</span></Button>	
				
                </div>	
				<div
                    style={{
                        display: "flex",
                        outline: "none !important",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        marginTop: "1rem",
                        marginBottom: "-5px",
                        alignItems: "center",
                        marginLeft: "-20px",
                    }}
                >
                    <a
                        href={
                            "https://www.youtube.com/channel/UCZhV5IEO2hqpeTwLzqKu8Sw/playlists?view_as=subscriber"
                        }
                        target="_blank"
                    >
                        <img
                            src={youtube}
                            style={{
                                width: "45px",
                                height: "45px",
                                marginRight: "-12px",
                            }}
                        />
                    </a>
                    <a
                        href={"https://www.instagram.com/official_mentomeet/"}
                        target="_blank"
                    >
                        <img
                            src={instagram}
                            style={{
                                width: "56px",
                                height: "56px",
                                marginRight: "-18px",
                            }}
                        />
                    </a>
                    <a
                        href={"https://www.facebook.com/mentomeet/"}
                        target="_blank"
                    >
                        <img
                            src={facebook}
                            style={{ width: "50px", marginRight: "-12px" }}
                        />
                    </a>
                    <a
                        href={"https://twitter.com/MentoMeet"}
                        target="_blank"
                    >
                        <img
                            src={twitter}
                            style={{ width: "50px", marginRight: "-12px" }}
                        />
                    </a>
                    
                </div>
					
					
			  </Col>
			 </Row>
		
		  </Container>
		  
		</div>		
	)
}
