import React from 'react';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText, InputGroupAddon, InputGroup } from 'reactstrap';
import AddQuestion from './AddQuestion';
import { Control, LocalForm, Errors } from 'react-redux-form';


/* Child  Component of Create Mock Test */
export default function StepTwo({setStage}){
	 
	return( 
			 <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="card-group shadow">
                            <div className="card border-warning text-white">
                                <div className="card-body m-1">
				   
									<AddQuestion />

									<InputGroup className="mb-3">
										<Button type="submit" block color="info" onClick={() => {
													if(localStorage.getItem('questionList')){
															setStage(2);	
													}else{
														alert('No Questions have been added');
													}
										}}>
											Go to Final Step
												</Button>
									</InputGroup>
									
									<InputGroup className="mb-3">
										<Button type="submit" block color="info" onClick={() => {
													localStorage.removeItem('test_name');
													localStorage.removeItem('test_description');
													localStorage.removeItem('questionList');
													localStorage.removeItem('answerList');
													setStage(0);
										}}>
												Cancel
										</Button>
									</InputGroup>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

		)
}