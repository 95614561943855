import React from "react";
import Mentors from "../Mentors";
import "./MentorList.css";
import AbdulImg from '../../../assets/members/abdul.jpg';

// import abhik from "../../../assets/members/abhik.jpeg";
// import rathod from "../../../assets/members/rathod.jpeg";
import hardik from "../../../assets/members/hardik.jpg";
// import saurabh from "../../../assets/members/saurabh.jpg";
// import nitesh from "../../../assets/members/nitesh.jpg";
import priyanshi from "../../../assets/members/priyanshi.jpg";
import ankit from "../../../assets/members/ankit.jpeg";
import rudra from "../../../assets/members/rudra.jpg";
// import rohit from "../../../assets/members/rohitmalawat.jpeg";

const mentors = [
  { name: "Rudraksh", category: "NEET", title: "NEET" , imgUrl: rudra},
  { name: "Hardik", category: "NEET", title: "NEET" , imgUrl: hardik},
  { name: "Ankit", category: "NEET", title: "JEE" , imgUrl: ankit},
//   { name: "Shivani", category: "NEET", title: "Expert",imgUrl: rudra },
//   { name: "Harshita", category: "JEE", title: "Mentor", imgUrl: rudra },
  { name: "Priyanshi", category: "JEE", title: "JEE" ,imgUrl: priyanshi},
  { name: "Abdul", category: "PLACEMENT", title: "Placement Preparation" , imgUrl: AbdulImg},
];

function MentorLists() {
  return (
    <>
      <Mentors />
      <div className="top-mentors-container">
        <div className="top-mentors-body">
          <h2 className="title" >
            Top Mentors Making an{" "}
            <span style={{ color: "#FF9500" }}>Impact</span>
          </h2>
          <p className="sub-title" >
            Meet our <span style={{ color: "#FF9500" }}>Dedicated Mentors</span>
            , who are experts in their fields , committed to helping students
            achieve their goals.
          </p>
          <div className="mentor-card-container">
            {mentors.map((mentor) => (
              <div>
                {/* <div className="mentor-category">
                  <p>{mentor.category}</p>
                </div> */}
                <div className="mentor-card" key={mentor.name}>
                  <div className="orange"></div>
                  <img src={mentor.imgUrl} alt={mentor.name} />
                  <p className="mentor-name">{mentor.name}</p>
                  <p className="mentor-role">{mentor.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default MentorLists;
