import React, { useState,useEffect } from 'react';
import { Card,Button } from 'reactstrap';
import { Badge } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
//import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import axios from 'axios';
import Options from '../TestComponents/Options';
import QuestionField from '../TestComponents/QuestionField';

/* Child  Component of Step Two */
export default function AddQuestion(){
	
	let init = 0;
	if(localStorage.getItem('questionList')){
		init = JSON.parse(localStorage.getItem('questionList')).length;
	}
	const [addedQuestion,setAddedQuestion] = useState(init);   //question index
	
	const [topic,setTopic] = useState('');
	const [optionsCount,setOptionsCount] = useState(0);
	
	const [correctOption,setCorrectOption] = useState({});
	
	const [textQuestion,setTextQuestion] = useState('');
	const [imageQuestion,setImageQuestion] = useState('');

	const [textAnswers,setTextAnswers] = useState([]);
	const [imageAnswers,setImageAnswers] = useState([]);
	
	const [optionList,setOptionList] = useState([]);
	
	function check(){
		let code = true;
		if(!topic) code = false;
		if(!textQuestion && !imageQuestion) code = false; 
		if(!textAnswers && !imageAnswers) code = false; 
		if(!correctOption) code = false
		if(correctOption){
			let numberOfCorrect = 0;
			Object.keys(correctOption).forEach(key => {
				if(correctOption[key] == true) numberOfCorrect += 1;
			})
			if(numberOfCorrect == 0) code = false;
		}
		if(optionsCount === 0) code = false
		return code;
	}
	

	function addQuestion(e){
		e.preventDefault();
		//check for all fields
		if(!check()){
			alert('Required Fields Missing ')
			return;
		}

		const data = new FormData();
		data.append('topics',topic);
		data.append('correct',JSON.stringify(correctOption));
		data.append('textQuestion',textQuestion);
		data.append('imageQuestion',imageQuestion);
		data.append('textAnswers',JSON.stringify(textAnswers));
		data.append('imageAnswers_0',imageAnswers[0]);
		data.append('imageAnswers_1',imageAnswers[1]);
		data.append('imageAnswers_2',imageAnswers[2]);
		data.append('imageAnswers_3',imageAnswers[3]);
		data.append('imageAnswers_4',imageAnswers[4]);
		
		//get role 
		let role = JSON.parse(localStorage.getItem('user')).role.toLowerCase();
		const token = localStorage.getItem('token');		
		fetch(`https://${window.location.hostname}:5005/${role}/addNewQuestion/add/ok`,{
			method:"POST",
			headers:{'Authorization':`Bearer ${token}`},			
			body:data
		})
		.then(res => {
			if(res.ok){
				res.json().then(data => {
					console.log(data.newQuestion);
					console.log(data.newAnswer);
					//for questionList
					if(localStorage.getItem('questionList')){
						let old = JSON.parse(localStorage.getItem('questionList'));
						old.push(data.newQuestion);
						localStorage.setItem('questionList',JSON.stringify(old));
					}else{
						let arr = JSON.stringify([data.newQuestion]);
						localStorage.setItem('questionList',arr);
					}
					//for answerList
					if(localStorage.getItem('answerList')){
						let old = JSON.parse(localStorage.getItem('answerList'));
						old[data.newQuestion] = data.newAnswer;
						localStorage.setItem('answerList',JSON.stringify(old));
					}else{
						let newObject = {};
						newObject[data.newQuestion] = data.newAnswer;
						let arr = JSON.stringify(newObject);
						localStorage.setItem('answerList',arr);
					}
					let list = JSON.parse(localStorage.getItem('questionList')).length;
					/* setAddedQuestion(parseInt(list));
					setTopic('');
					setOptionsCount(0);
					setTextQuestion('');
					setImageQuestion([]);
					setTextAnswers('');
					setImageAnswers([]);
					setCorrectOption([]); */
					window.location.href = '/mentor/createtest';
				})
			}else{
				alert('ERROR');
			}
		})
		.catch(e => {
			alert('Server ERROR');
		})
		
	}//end here add question
	
	
  function options(){
		let count = parseInt(optionsCount);
		let newArray = [];
		let newTextArray = [];
		let newImageArray = [];
		for(let i=0;i < count;i++){
			newArray.push(`yes`);
			newTextArray.push(textAnswers[i]);
			newImageArray.push(imageAnswers[i]);
		}		
		setTextAnswers(newTextArray);
		setImageAnswers(newImageArray);
		setOptionList(newArray);
	} 
	
	useEffect(() => {
			options();
	},[optionsCount])
	
	return(
		 <React.Fragment>
				<section style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} >
						<div>
							<Badge badgeContent={addedQuestion} color="secondary"  >
								<span style={{color:'black'}} >Added Questions<QuestionAnswerIcon style={{color:'red',marginLeft:'1rem'}} /></span>
							 </Badge>		  
						</div>
				</section>
                <div style={{margin:'1rem auto 0 auto'}}>
					<h3 className="text-warning mb-0">Question {parseInt(addedQuestion) + 1} </h3>
                    <form autocomplete="off">
						{/* Question Field */}
						   <QuestionField topic={topic} setTopic={setTopic} textQuestion={textQuestion} setTextQuestion={setTextQuestion}  setImageQuestion={setImageQuestion}  imageQuestion={imageQuestion} />
				       {/* Options Dynamic */}
						<div>		
							<select defaultValue={optionsCount} onChange={(e) => {
								setOptionsCount(e.target.value);
							}} >
								<option value='0' >Select Number of Options </option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
							</select>
						</div>
						<div >
							{optionList.map((el,i) => {
								return (
									<Options  i={i} correctOption={correctOption} setCorrectOption={setCorrectOption} 
										textAnswers={textAnswers}  setTextAnswers={setTextAnswers}  imageAnswers={imageAnswers} setImageAnswers={setImageAnswers} 
									/>
								)
								})
							}
						</div>
					{/* Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '15px', outline: "none" }}
							onClick={(e) => addQuestion(e)}

                            endIcon={<SaveIcon />}
						 >Save Question 
						</Button>
                    </form>
                </div>
            </React.Fragment>
	)
}
