import React,{useEffect,useState} from 'react';
import TestCard from './TestCard';
import {Container,Row,Col,CardDeck} from 'react-bootstrap';
import axios from 'axios';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TAGS from './TAGS_MOCKTEST.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

/* Child component of mock test */
export default function DisplayMockTest({setStage,changeTestId,setData,data}){
	
	let userRole = JSON.parse(localStorage.getItem('user')).category
	const [err,setErr] = useState(false);
	const [chipData,setChipData] = useState([]);
	const [isDataReturned,setDataReturned] = useState(false);
	let [hashVal,setHashVal] = useState(window.location.hash);
	const [History,setHistory] = React.useState([userRole]);
	const [depth,setDepth] = React.useState(0);  //current Depth
	
	const useStyles = makeStyles((theme) => ({
		  root: {
			display: 'inline-flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			listStyle: 'none',
			padding: theme.spacing(0.5),
			margin: 0,
		  },
		  chip: {
			margin: theme.spacing(0.5),
		  },
		}));
	
	const classes = useStyles();
	
	
	useEffect(() => {
		if(!localStorage.getItem('user') || !localStorage.getItem('token')){
			localStorage.setItem('redirect','/mentee/mocktest')
			window.location.href = '/login'
		}
	 },[])
	 
	
	useEffect(() => {
		if(err){
			setErr(false)
		}
		
		 let isMounted = true;
		 let category = JSON.parse(localStorage.getItem('user')).category;
		 let filter_by_tags = window.location.hash.slice(1,) 
		 if (filter_by_tags.length == 0){
			 filter_by_tags = category;
		 }
		
	//	 if(chipData.length == 0){
		//	 filter_by_tags = category.toLowerCase()
		// }else{
			// filter_by_tags = chipData.join('_');
			 //filter_by_tags += `_${category.toLowerCase()}`
		// }
		 
		axios.get(`https://${window.location.hostname}:5005/mentee/mocktest/getAllTests/${category}/${filter_by_tags}`)
			.then(data => { 
				if(data){
					if (isMounted) setData(data.data.result);
					if(data.data.result.length === 0){
						//setErr('No  Test Found ')
					}
					setDataReturned(true);
				} 
			})
			.catch(error => setErr(true))
		 //for unmounting	
		 return () => { isMounted = false }; 
		
	},[hashVal])
	
	const cards = data && data.map((cardProp) => {
		return <TestCard  key={cardProp._id} isFree={cardProp.isFree} {...cardProp} />;
	})
	
	window.onhashchange = shouldGetMockTest;
	
	function shouldGetMockTest(){
		if(window.location.hash != hashVal){
			setHashVal(window.location.hash)
		}
	}
	
	function handleStartBtn(e){		
		if(e.target.dataset.unique){
			let TEST_ID = e.target.dataset.unique;
			let userInfo = JSON.parse(localStorage.getItem('user'));
			
			let filterData = data.filter(eachTest => eachTest._id == e.target.dataset.unique)
			if(filterData[0].isFree){
						changeTestId(TEST_ID);
						 setStage(1);
			}else{			
					axios.get(`https://${window.location.hostname}:5005/mentee/mocktest/eligible/${userInfo._id}`)
					.then(data => { 
						if(data.status == 200){
						   changeTestId(TEST_ID);
						   setStage(1);
						} 
					})
					.catch(error => { 
							alert('You dont have a plan for mocktest .Please buy a  plan for quality  mock tests')
							setErr(true)
							window.location.href ='/pricing';
					})
			}
		}
	}
	
	const handleDelete = (e) => {
		e.preventDefault();
		if(e.target && e.target.closest('a') && e.target.closest('a').dataset.pos){
			let pos = 	Number(e.target.closest('a').dataset.pos)
			//History Length 1 more than pos
			if(History.length == pos+ 1 ){
				return;
			}else{ 

				if(pos + 1 < History.length){
			
					let newHistory = History.slice(0,pos+1)
					if(newHistory.length == 0){
						return;
					}
					setHistory(newHistory)
					setDepth(newHistory.length -1)
					window.location.hash = newHistory[newHistory.length-1]
			}
		  }
			
		}
		
	}
	
 return(
 
 	<Container  >	
		<h3 style={{textAlign:'center',marginTop:'1rem'}} >Practice Tests </h3>
					<div>
							{err?<p className='text-danger' style={{fontSize:'1.2rem',margin:'auto',textAlign:'center'}} >{err}</p>:null}		 
								
							<Breadcrumbs  aria-label="breadcrumb">
								{
									History.map((name,index) => {
										return (
											<Link   onClick={(e) => handleDelete(e)} data-pos={index}  color="inherit" href="/" >
												{name}
											</Link>
										)
									})
								}
							</Breadcrumbs>
							
							<TAGS  addInChip={(newVal) => {
																	//let newArray = [...chipData]
																	//newArray.push(newVal)
																	//setChipData(newArray)
																	window.location.hash = newVal
											} }
										History={History}
									setHistory={setHistory}
									depth={depth}
									setDepth={setDepth}
							/>  
									
							
					</div>
				<Row style={{minHeight:'60vh'}} className='justify-center align-center mlr-auto' > 
				 {isDataReturned?(<CardDeck onClick={(e) => handleStartBtn(e)}>
					 {(cards.length > 0)?cards:null}
				  </CardDeck>
				  ):null}
				</Row>
				
			
				</Container>
	)
}