import React,{useState,useRef,useEffect} from 'react';


export default function Timer({setStage}){
	
	let [hour,setHour] = useState('00');
	let [minute,setMinute] = useState('00');
	let [second,setSecond] = useState('00');
	
	let interval = useRef();
	
	const startTimer = () => {
		let endTime = JSON.parse(localStorage.getItem('TIME')).end;
		console.log('--------------------------------')
		console.log(endTime)
		const countDownTime  = new Date(endTime).getTime();
		
		interval = setInterval(() => {
			 const now = new Date().getTime();
			 const left = countDownTime - now;
			 
			 const hr = Math.floor((left%(1000*60*60*24)/(1000*60*60)));
			 const min = Math.floor((left%(1000*60*60)/(1000*60)));
			 const sec = Math.floor((left%(1000*60)/(1000)));
			 
			 
			 if(left <= 0){
				 //stop timer
				 clearInterval(interval.current);
				setStage(3);  //submit test automatically
			 }else{
				 //update timer
				 setHour(hr);
				 setMinute(min);
				 setSecond(sec);
			 }
			 
		},1000)
		
	}
	
	useEffect(() => {
		   if(!localStorage.getItem('TIME')) return;
			startTimer();
			return () => {
				clearInterval(interval.current);
			};
	},[])
	
	return(
	<>
		<span style={{margiLeft:'auto',fontWeight:'bold'}} >{hour.toString().padStart(2,'0')}:{minute.toString().padStart(2,'0')}:{second.toString().padStart(2,'0')}</span>
	</>
	)
}