import React,{useEffect,useRef,useState} from 'react';
import { Button,Input } from '@material-ui/core';
import abdul from './abdul.jpg';
//import abdul from './abhinav.jpg';
import mento from './brand3.png';
import polygon from './Polygon.png';
import MenteeBadge from '../../assets/menteeBadge.png';  
import MentorBadge from '../../assets/mentorBadge.png';  
// import TeamBadge from '../../assets/15august.png';  
import {  CardHeader, CardFooter, CardBody,
	CardTitle, CardText,Card } from 'reactstrap';

export default function PromoteUs(){
	
	const canvasRef = useRef(null);
	const contextRef = useRef(null);
	const [baseImage, setBaseImage] = useState("");
	const [file, setFile] = useState("");
	const cancelFileUpload = useRef(null);
	const [iam,setIAM] = useState('MENTEE');

	  const clickHandler = () => {
		var imageBtn = document.querySelector("#user-image");
		imageBtn.click();
	  };
	
	useEffect(() => {
		console.log(canvasRef);
		console.log(canvasRef.current);
		canvasRef.current.width = Math.min(500,window.innerWidth);
		canvasRef.current.height = Math.min(500,window.innerHeight);
		contextRef.current = canvasRef.current.getContext('2d')
		console.log(contextRef)
	//	console.log(context)
		if(contextRef.current){
			console.log('hi');
			contextRef.current.strokeStyle = '#fd9b02';
		   //contextRef.current.fillStyle = '#fd9b02'; 
			contextRef.current.strokeRect(0,50,canvasRef.current.width,canvasRef.current.height);
			contextRef.current.fillStyle = 'white';        
			contextRef.current.font = '30px Poppins';
			contextRef.current.fillText ('Add Your Image', canvasRef.current.width * 0.20, 150)
			//contextRef.current.fill('red'); 
			IntitialImage();
		}
	},[canvasRef,contextRef])
	
	useEffect(() => {
		
		if(!baseImage) return;
		setImage();
	},[baseImage])
	
	function setImage(){
		if(!baseImage){
			contextRef.current.fillRect(0,50,canvasRef.current.width,canvasRef.current.height);
			contextRef.current.fillStyle = 'black';  
			contextRef.current.fillText ('Add Your Image', canvasRef.current.width * 0.20, 150)
			contextRef.current.fillStyle = 'white';        
			contextRef.current.font = '30px Poppins';
			contextRef.current.strokeRect(0,50,canvasRef.current.width,canvasRef.current.height);
			
			IntitialImage();
		}else{
			let image = new Image();
			image.src = baseImage;
			image.onload = () => {
				
				
			const size = Math.min(image.width, image.height);
			const hRatio = window.innerWidth / image.width;
			const vRatio = window.innerHeight / image.height;
			const ratio = Math.max(hRatio, vRatio);
			const x = (window.innerWidth - image.width * ratio) / 2;
			const y = (window.innerHeight - image.height * ratio) / 2;	
			
			contextRef.current.drawImage(
				  image,
				  0,
				  0,
				  500 ,
				  500
				);
				
				IntitialImage();
				
			}
		}
	}
	
	useEffect(() => {
		setImage();
	},[iam])
	
	function IntitialImage(){
		
		if(iam == 'MENTEE'){
				let image2 = new Image();
				image2.src = MenteeBadge;
				image2.onload = () => {
				
					
				const size = Math.min(image2.width, image2.height);
				const hRatio = 500 / image2.width;
				const vRatio = 500 / image2.height;
				const ratio = Math.max(hRatio, vRatio);
				const x = (500 - image2.width * ratio) / 2;
				const y = (500 - image2.height * ratio) / 2;	
				
				contextRef.current.drawImage(
					  image2,
					  0,
					  100,
					  Math.min(500,window.innerWidth) ,
					  400
					);
			}
		}else if(iam == 'MENTOR'){
			let image2 = new Image();
			image2.src = MentorBadge;
			image2.onload = () => {
				
				
			const size = Math.min(image2.width, image2.height);
			const hRatio = 500 / image2.width;
			const vRatio = 500 / image2.height;
			const ratio = Math.max(hRatio, vRatio);
			const x = (500 - image2.width * ratio) / 2;
			const y = (500 - image2.height * ratio) / 2;	
			
			contextRef.current.drawImage(
				  image2,
				  0,
				  100,
				  Math.min(500,window.innerWidth) ,
				  400
				);
		}
	}else if(iam == 'TEAM'){
		let image2 = new Image();
			image2.src = null;
			image2.onload = () => {
				
				
			const size = Math.min(image2.width, image2.height);
			const hRatio = 500 / image2.width;
			const vRatio = 500 / image2.height;
			const ratio = Math.max(hRatio, vRatio);
			const x = (500 - image2.width * ratio) / 2;
			const y = (500 - image2.height * ratio) / 2;	
			
			contextRef.current.drawImage(
				  image2,
				  0,
				  100,
				  Math.min(500,window.innerWidth) ,
				  400
				);
		}
	}
		
	}
	
	function handleDownload(){
	  //let canvas = pic.current;
	  let dataURI = canvasRef.current.toDataURL("image/jpeg");
	//  console.log(dataURI);
	 // window.location.href = dataURI; 
	 //document.write('<img src="'+dataURI+'"/>');
	 
	 let mylink = document.createElement('a');
	  mylink.download = 'uploadIt.jpg';
	  mylink.href = dataURI;
	  mylink.click();
  }
	
	const PreviewImage = async (e) => {
		const myfile = e.target.files[0];
		const base64 = await convertToBase64(myfile);
		setBaseImage(base64);
		setFile(myfile);
	  };

	
	 const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
		  const fileReader = new FileReader();
		  if (file) {
			fileReader.readAsDataURL(file);
		  }
		  fileReader.onload = () => {
			resolve(fileReader.result);
		  };
		  fileReader.onerror = (error) => {
			reject(error);
		  };
		});
	  };
	
	
	return (
		<div style={{display:'flex',flexWrap:'wrap',marginBottom:'100px',justifyContent:'space-around'}} >
			<div>
				<canvas ref={canvasRef}  ></canvas>
			</div>
			<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >

				  <h5>Share this on your Social Media and get a  <span class="badge badge-warning">5% DISCOUNT</span> </h5>
				  <h5>on our plans.Just Choose your <i>Role</i> and click on <i>Upload image</i></h5>
				  <h5>and then <i>download</i> and Share with Tagging us</h5>

				 <div style={{marginBottom:'50px'}} >
						   <Button
						  variant={(iam == 'MENTEE')?'contained':'outlined'}
						  style={(iam == 'MENTEE')?{backgroundColor:'#fd9b02',color:'white',marginRight:'10px'}:{margin:'10px'}}
						  onClick={() => setIAM('MENTEE')}
					 
						>
						   MENTEE
						</Button>
						  <Button
						  variant={(iam == 'MENTOR')?'contained':'outlined'}
						  style={(iam == 'MENTOR')?{backgroundColor:'#fd9b02',color:'white'}:{margin:'10px'}}
						  onClick={() => setIAM('MENTOR')}
					 
						>
						  MENTOR
						</Button>
						<Button
						  variant={(iam == 'TEAM')?'contained':'outlined'}
						  style={(iam == 'TEAM')?{backgroundColor:'#fd9b02',color:'white'}:{margin:'10px'}}
						  onClick={() => setIAM('TEAM')}
					 
						>
						  INDEPENDENCE DAY
						</Button>
				</div>
				 <input
                  id="user-image"
                  type="file"
                  onChange={(e) => PreviewImage(e)}
                  hidden
                ></input>
                <Button
                  variant="contained"
                  style={{backgroundColor:'#fd9b02',color:'white'}}
                  onClick={clickHandler}
             
                >
                  Upload Image
                </Button>
				
				<Button  variant="outlined" onClick={handleDownload} style={{maxHeight:'50px',marginTop:'40px'}} >Download Image</Button>
			</div>
		</div>
	)
} 