import React,{ Component} from 'react';

import './../css/App.css';
import { Row, Label, Col, Container, Breadcrumb, BreadcrumbItem, Button, InputGroupText,  InputGroupAddon, InputGroup, Card, CardBody, CardTitle, Alert} from 'reactstrap';

import WhyChooseUs from '../components/WhyChooseUs'
import Home from './NEWDESIGN/pages/Home.js'
import Care from './NEWDESIGN/pages/Care/App.js'
import Updated from './NEWDESIGN/Updated.js'
import TopMentors from './NEWDESIGN/pages/MentorList.js'
import Profiles from './NEWDESIGN/pages/Profiles.js'
import WhyMentomeet from './NEWDESIGN/pages/WhyMentomeet.js'
import NewTestimonial from './testimonial/NewTestimonial.js'
import NewCollaboration from './NewCollaboration.js';
import NewFAQ from './NewFAQ.js';


import Footer from './Footer'
import Mentor from './Team'
// import WordFromStudents from './WordFromStudents.js'
import FadeIn from 'react-fade-in';
import Announcements from './Announcements';
import ReactLoading from 'react-loading';
import Testimonial from './testimonial/testimonial.js';
import {Modal} from 'react-bootstrap';
import CallbackForm from './Profile/Mentor/CallbackForm';
import NewMedia from './NewMedia.js';

class Index extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoaded:false ,
        openCallBackForm: false,
      }
      console.log('intial state')
    }
    //callback functionality starts here
		handleClose = () => {
			this.setState({
			   ...this.state,
			  openCallBackForm:false
			});
		  }
	
	 handleOpen = () => {
		 if(!localStorage.getItem('token')){
			 window.location.href = '/login';
			 return;
		 }
		this.setState({...this.state,openCallBackForm:true})
	  }
	
	//ends here

    componentDidMount() {
     
       this.setState({isLoaded: true})
    // setTimeout(() => { 
    //       this.setState({isLoaded: true})
    // },500);

    console.log('mounted successfully')
    setTimeout(() => { 
      //this.setState({openCallBackForm: true})
    }, 6000);
    }
  render() { 
    
    return (      
     this.state.isLoaded==false?
    <ReactLoading style={{margin: "4rem auto", height: "10%", width: "10%" }} color='#ff9c00' type={"spinningBubbles"} />
:<div id="HOMEPAGE" >
      <Modal 
		show={this.state.openCallBackForm}
		onHide={this.handleClose} 
		backdrop="static"
        keyboard={false}
		size="sm"
	>
			<Modal.Header closeButton>
			  <Modal.Title>Register CallBack Request</Modal.Title>
			</Modal.Header>
			<Modal.Body>
					<CallbackForm />
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={this.handleClose}>
				Close
			  </Button>
			</Modal.Footer>
      </Modal>
      <FadeIn>
		<Home />
		<Care />
  		<WhyMentomeet />
  		<TopMentors />
		{/*  <Profiles />  */}
 		{/* <Updated /> */}
		 {/* <Testimonial/> */}
     <NewTestimonial />
     <NewCollaboration />
     <NewFAQ />
     <NewMedia />
       </FadeIn>
       
      </div>
       
    
     );
     
  }
}
 
export default Index;



/* 
	 <Home/>
     <Announcements/> 
      <CareerSolutionEveryone/>
      <WhyChooseUs/> 
      <BlogDetailedPage/> 
      
      <About/>
      {/* <Mentor/> 
		  {/* <BeyondMentorship/>     
      {/* <WhyChooseMentomeet/> 
       {/* <Footer /> 
       <Testimonial/>

 */