import React, { useState,useEffect } from 'react';
import { Card, CardHeader, CardFooter, CardBody, CardTitle, CardText,Button} from 'reactstrap';
import axios from 'axios';
import Time from '../utils/Time';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TagsDropdown from './TagsDropdown.js'



/* Child Component of MyAllTestsHistory */
export default function TestDetail({detail,setStage,handleClick,handleTags}){
	
	const [data,setData] = useState('');
	const [show,setShow] = useState(false);
	
    const [chipData,setChipData] = useState([]);
	
	const useStyles = makeStyles((theme) => ({
		  root: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			listStyle: 'none',
			padding: theme.spacing(0.5),
			margin: 0,
		  },
		  chip: {
			margin: theme.spacing(0.5),
		  },
		}));
	
	const classes = useStyles();
	

	useEffect(() => {
		if(!detail) return;
		let userInfo = JSON.parse(localStorage.getItem('user'));		
		axios.get(`https://${window.location.hostname}:5005/mentor/testdetail/${detail}`)
            .then(response => {
				if(window.location.pathname.includes('/admin/dashboard/mocktest')){
					setShow(true);
				}
				setData(response.data);
				setChipData(response.data.tags)
            })
            .catch((err) => {
                console.log('Error :', err);
            })
	},[detail])
	
	function handleAllQuestions(e){
			e.preventDefault();
			if(data.isDividedInSection){
				
			}else{
				let {questionList,answerList} = data;
				localStorage.setItem(`${detail}.admin_questionList`,JSON.stringify(questionList));
				setStage(1);
			}
			
			
	}
	
	function changeVisibility(val){
		axios.get(`https://${window.location.hostname}:5005/admin/test/change/visibility/${detail}/${val}`)
            .then(response => {
				alert('Updated')
            })
            .catch((err) => {
               alert('Failed')
            })
	}
	
	
	
	const handleDelete = (chipToDelete) => () => {
		setChipData(chipData.filter((key,index) => index != chipToDelete ))
	}
	
	return(
	    <div style={{width:'70vw'}} >
				{show?(
					<div>
						<p><b>Associated Tags</b></p>
						<TagsDropdown  addInChip={(newVal) => {
								let newArray = [...chipData]
								newArray.push(newVal)
								setChipData(newArray)
							} 
						}/> 
							
						<Paper component="ul"  className={classes.root} >
						  {chipData.map((tag,index) => {
							
							return (
							  <li key={index}>
								<Chip
								  label={tag}
								  onDelete={handleDelete(index)}
								  className={classes.chip}
								/>
							  </li>
							)
						  })}
						 </Paper>
						 
						 <br/>
						 <br/>
						 <Button onClick={() => handleTags(chipData,data._id)} >Update Tags</Button>
					</div>
				):null}
				
	        	<Card style={{ width: "85%", margin: "30px",border:'none' }}>           
                    <CardHeader>{new Date(data.creationDate).toLocaleDateString()}</CardHeader>
                    <CardBody>
                        <CardTitle tag="h5">Mentor: {data.creator}</CardTitle>
                        <CardText>Test Details:
                        <ul>
								<li>Test Name : {data.name} </li>
                                <li>Correct Answer Marks: {data.correctAnswerMarks}</li>
                                <li>Incorrect Answer Marks: {data.IncorrectAnswerMarks}</li>                             
                                <li>Passing Marks: {data.passMarks}</li>
                         </ul>
						 <div  style={{display:'flex',width:'100%',justifyContent:'space-between'}} >
							 {
								 data.isDividedInSection?(
									<p>Not Implemented</p>
								 ):(
									<Button onClick={(e) =>  handleAllQuestions(e)} >Available Questions</Button>
								 )
							 }
						
						</div>
						 <div  style={{display:'flex',margin:'20px 20px',width:'100%',justifyContent:'space-between'}} >
						 <span>Visible To : {data.visibility}</span>
							{show?<select value={data.visibility} onChange={(e) => changeVisibility(e.target.value)} >
										<option value="ALL" >ALL</option>
										<option value="JEE"  >JEE</option>
										<option value="NEET" >NEET</option>
										<option value="CAREER" >CAREER</option>
									</select>:null
							}
						</div>
						</CardText>
                    </CardBody>
                    <CardFooter>
						Test Duration: {(Time(parseInt(data.testDuration)))}
						
						{show?<Button style={{display:'flex',justifyContent:'end'}} onClick={(e) =>  setStage(2)} >Get Result</Button>:null}
					</CardFooter>
                </Card>
				<hr/>
				<div style={{marginLeft:'30px',marginTop:'15px',minWidth:'40vw',display:'flex',justifyContent:'center',flexDirection:'column'}} onClick={(e) => handleClick(e)} >
				
					{JSON.parse(localStorage.getItem('user')).role.toLowerCase() === 'mentor'?(
											<p style={{fontSize:'30px'}} >It is {data.published?<span style={{color:'green'}} >Published</span>:<span style={{color:'red'}} >Not Published</span>} </p>):null
					}
					
					<p style={{fontSize:'30px'}} >Click to 
					<Button style={{margin:'10px'}} data-delete={data._id} color="danger">DELETE</Button>
					Test
					</p>
					
					{show?(
						<p style={{fontSize:'30px'}} >Click to  {data.published ?<Button  style={{margin:'10px'}} data-publish={'UNPUBLISH'} data-id={data._id} color="danger">UnPublish</Button> : <Button style={{margin:'10px'}} data-publish={'PUBLISH'} data-id={data._id}  color="success">Publish</Button>}
						Test </p>):null
					}
					
					
					
					
					{show?(
						<p style={{fontSize:'30px'}} >Click to make it  {data.isFree ?<Button style={{margin:'10px'}} data-pricing={'PAID'} data-id={data._id} color="danger">PAID</Button> : <Button style={{margin:'10px'}} data-pricing={'FREE'} data-id={data._id}  color="success">FREE</Button>}
						</p>):null
					}
					
					
				</div>
            </div>
		
        );
	
}