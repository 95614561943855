import React from 'react';
import { Card, Button} from 'react-bootstrap';
import Time from '../../utils/Time';

export default function TestHistoryCard(props){
	
	return(
		<Card  style={{minWidth: '18rem',maxWidth:'19rem',margin:'1rem 1rem 1rem 1rem' }}  >
			 <Card.Header style={{background:'#FFC107'}} >
				 <Card.Title>{(props.testResult == 'PASS')?<p style={{color:'green'}} >Passed</p>:<p style={{color:'red'}} >Failed</p>}</Card.Title>
				 <Card.Title><p>{new Date(props.attendedDate).toLocaleDateString()}</p></Card.Title>
				 <Card.Title><p>{props.category.map(e => ` ${e.toUpperCase()} `)}</p></Card.Title>
			 </Card.Header >
			 
			 <Card.Body  >
				<Card.Text><span style={{fontWeight:'800'}} >Total Marks </span>: {props.totalMarks}</Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Passing Marks  </span>: {props.passMarks} </Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Marks Obtained  </span>: {props.marksObtained} </Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Test Started at  </span>: {new Date(props.startTime).toLocaleString().split(',')[1].trim()} </Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Test Completed at  </span>: {new Date(props.completionTime).toLocaleString().split(',')[1].trim()} </Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Total Correct Answers </span> : {props.totalCorrectAnswer} </Card.Text>
				<Card.Text><span style={{fontWeight:'800'}} >Total Wrong Answers  </span>: {props.totalWrongAnswer} </Card.Text>
			</Card.Body>
		
		</Card>
	)
}