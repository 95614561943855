import React,{useState,useEffect} from 'react'
import {Container,Row,Col,Card, Button} from 'react-bootstrap';

/* Child component of mock test */
export default function Result({Testinfo,setStage}){
	
	if(!Testinfo){
		//alert('Getting from localStorage')
		Testinfo = JSON.parse(localStorage.getItem('TEST_INFO'));
	}
	
	//remove started from localStorage
	localStorage.removeItem('started');
	let [marksObtained,setMarks] = useState('...');
	let [result,setResult] = useState('...');
	
	let RED = [],GREEN = [],VIOLET=[],ORANGE=[];
	
	let questionsArray = [];
	let answers;

	questionsArray = (Testinfo && Testinfo.questionList) || [];
	answers = JSON.parse(localStorage.getItem(`${Testinfo._id}_TEST_ANSWERS`))
	

	
	questionsArray && questionsArray.forEach((q) => {
		//console.log('Question ID',q);
		if(answers[q]){
			//console.log('Answer is  present in answer list');
			if(answers[q]['color'] === 'violet'){
				VIOLET.push(answers[q]);
			}
			if(answers[q]['color'] === 'orange'){
				ORANGE.push(answers[q]);
			}
			if(answers[q]['color'] === 'green'){
				GREEN.push(answers[q]);
			}
			if(answers[q]['color'] === 'red'){
				RED.push(answers[q]);
			}
		}
		
	});
	
	useEffect(() => {
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let timeInfo = JSON.parse(localStorage.getItem('TIME'));
		if(!timeInfo) return;
		//alert(timeInfo);
		timeInfo.complete = new Date().getTime();
		let extraInfo = {RED,GREEN,ORANGE,VIOLET}
		const token = localStorage.getItem('token');		
		fetch(`https://${window.location.hostname}:5005/mentee/mocktest/result/${Testinfo._id}`,{
			method:'POST',
			headers:{'Content-Type':'application/json','Authorization':`Bearer ${token}`},
			body:JSON.stringify({answers,userInfo,timeInfo,extraInfo})
		})
		.then(res => {
			if(res.ok){
				res.json().then(data => {
					//alert('result rec');
					console.log(data);
					setMarks(data.marksObtained);
					setResult(data.testResult);
				})
			}else{
				alert('There is some problem !!');
			}
		})
		.catch(err => {
			alert('SERVER ERROR');
		})
	},[])
	
	let attendedQuestion = GREEN.length  + ORANGE.length;
	let unattendedQuestion = (Testinfo.questionList.length) - attendedQuestion;
			
	const MarksObtained = () => {
		return (parseInt(GREEN.length) + parseInt(ORANGE.length)) *  (Testinfo.correctAnswerMarks);
	}
	
	return(
		<Container style={{overflowX: 'hidden',fontFamily: 'Roboto,sans-serif'}}>
			<h1>Result</h1>
			<Row style={{marginBottom:'5%'}}>
			   <Col style={{color:'blue'}} >
			   <Card style={{height:'100%',padding:'4%'}}>
				<p>Total Questions : {Testinfo.questionList.length}</p>
				<p>Total Marks : {(Testinfo.questionList.length) * (Testinfo.correctAnswerMarks)}</p>
				<p>Passing Marks : {Testinfo.passMarks}</p>
				<p>Correct Answer Marks : {Testinfo.correctAnswerMarks}</p>
				<p>InCorrect Answer Marks : {Testinfo.IncorrectAnswerMarks}</p>
			   </Card>
			   </Col>
			   <Col style={{color:'green'}} >
			   <Card style={{padding:'4%'}}>
					<p>Status</p>
					<p>Attended Questions : {attendedQuestion}</p>
					<p>UnAttended Questions : {unattendedQuestion}</p>
					<p>Marked Questions : {parseInt(VIOLET.length) + parseInt(ORANGE.length)}</p>
					<p>Marks Obtained : {marksObtained}</p>
					<p>Result : {result}</p>
				</Card>
			   </Col>
			</Row>
			<div style={{display:'flex',flexDirection:'column',width:'100vw',marginBottom:'5rem'}} >
				<div>
					<button className="btn btn-primary" 
					onClick={(e) => {
								e.preventDefault();
								localStorage.removeItem('TEST_INFO');						
								localStorage.removeItem('TIME');						
								window.location.href = '/mentee/mocktest'
						}} >Get Back to Mock Test Page</button>
				</div>
			</div>
		</Container>
	)
}