import React from 'react';
const Ticker = () => {


    return (
        <div class="ticker">
        <ul class="ticker__list">
            <li class="ticker__item">"✨ Brighten Your Future With Mentomeet! Get Special Discounts on MentoMEET's Services– Limited Time Offer! ✨"</li>
                </ul>
    </div>
    
    );
};

export default Ticker;
