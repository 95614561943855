import React, { useState,useEffect } from 'react';
import { Card } from 'reactstrap';
import { TextField, IconButton, Radio, Typography, Fab, Button ,Badge,Checkbox} from '@material-ui/core';
import CropOriginalIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import axios from 'axios';
import Options from '../TestComponents/Options';
import QuestionField from '../TestComponents/QuestionField';
import {Modal,Form} from 'react-bootstrap';

export default function AddTestQuestion({detail,setAddMode,setLastEdited,setQuestions}){
	
	const [topic,setTopic] = useState('');
	const [optionsCount,setOptionsCount] = useState([]);
	
	const [correctOption,setCorrectOption] = useState(0);
	
	const [textQuestion,setTextQuestion] = useState('');
	const [imageQuestion,setImageQuestion] = useState('');

	const [textAnswers,setTextAnswers] = useState([]);
	const [imageAnswers,setImageAnswers] = useState([]);
	
	const [optionList,setOptionList] = useState([]);
	
	
	function check(){
		let code = true;
		if(!topic) code = false;
		if(!textQuestion && !imageQuestion) code = false; 
		if(!textAnswers && !imageAnswers) code = false; 
		if(!correctOption) code = false
		if(correctOption){
			let numberOfCorrect = 0;
			Object.keys(correctOption).forEach(key => {
				if(correctOption[key] == true) numberOfCorrect += 1;
			})
			if(numberOfCorrect == 0) code = false;
		}
		if(optionsCount === 0) code = false
		return code;
	}
	

	function updateQuestion(e){
		e.preventDefault();
		if(!check()){
			alert('Required Fields Missing ')
			return;
		}
	
		const data = new FormData();
		data.append('topics',topic);
		data.append('correct',JSON.stringify(correctOption));
		data.append('textQuestion',textQuestion);
		data.append('imageQuestion',imageQuestion);
		data.append('textAnswers',JSON.stringify(textAnswers));
		data.append('imageAnswers_0',imageAnswers[0]);
		data.append('imageAnswers_1',imageAnswers[1]);
		data.append('imageAnswers_2',imageAnswers[2]);
		data.append('imageAnswers_3',imageAnswers[3]);
		data.append('imageAnswers_4',imageAnswers[4]);
		
		let userInfo = JSON.parse(localStorage.getItem('user'));						
		const token = localStorage.getItem('token');		
		fetch(`https://${window.location.hostname}:5005/mentor/test/add/${detail}`,{
			method:"POST",
			headers:{'Authorization':`Bearer ${token}`},		
			body:data
		})
		.then(res => {
			if(res.ok){
				res.json().then(data => {
					
				//console.log(data.result.questionList);
			    let newQuestions = (data && data.result && data.result.questionList) || [];
				  console.log('new questions',newQuestions);
				   localStorage.setItem('admin_questionList',JSON.stringify(newQuestions));
				  setQuestions(newQuestions);
				  setAddMode(false); 
				})
			}else{
				alert('ERROR');
			}
		})
		.catch(e => {
			alert('Server ERROR');
		})
		
	}//end here add question
	
	/* 
	function options(){
		let count = parseInt(optionsCount);
		//let myArrayOptions = new Array(count);
		let newArray = [];
		//let correct = [];
		
		for(let i=0; i < count;i++ ){
				newArray.push(<div key={i} style={{ display: 'flex', flexDirection: 'row', marginLeft: '-13px',justifyContent:'space-between',alignItems:'center'}}>
							<Checkbox
								checked={correctOption[i]}
								onChange={() => {
									let oldCorrect = correctOption || {};
									oldCorrect[i] = !oldCorrect[i];
									setCorrectOption({...oldCorrect});
								}}
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
							<TextField fullWidth={true} placeholder={'Write option'} style={{ marginTop: '5px' }} required  id={i} value={textAnswers[i]} 
									onChange={(e) => {
										  
										  let oldTexAnswers =  textAnswers || [];
										  oldTexAnswers[e.target.id] = e.target.value;
										  //oldTexAnswers[e.target.]
										setTextAnswers([...oldTexAnswers]);
									}} />
							<Form.File
							  required
							  id={i}
							  type="file"
							  name="imageAnswers"
							  feedbackTooltip
							  style={{color:'black'}}
							  onChange={(e) => { 
									//setErr({...err,image:''}); 
									//setImage(e.target.files[0]);
									let oldImageAnswers =  imageAnswers;
									let id = e.target.id;
									if(id == '') id = 0;
										 oldImageAnswers[id] = e.target.files[0];
										  //oldTexAnswers[e.target.]
										console.log(id)
										console.log(oldImageAnswers)
										setImageAnswers([...oldImageAnswers]);
								}}
							  />
						  <IconButton aria-label="delete" style={{color:imageAnswers[i]?'green':'red',marginRight:'10px'}} >
                                    <CropOriginalIcon />
                           </IconButton>
					  </div>);
		};
		return newArray;
	}  */
	
	//updated
	
  function options(){
		let count = parseInt(optionsCount);
		let newArray = [];
		let newTextArray = [];
		let newImageArray = [];
		for(let i=0;i < count;i++){
			newArray.push(`yes`);
			newTextArray.push(textAnswers[i]);
			newImageArray.push(imageAnswers[i]);
		}		
		setTextAnswers(newTextArray);
		setImageAnswers(newImageArray);
		setOptionList(newArray);
	} 
	
	useEffect(() => {
			options();
	},[optionsCount])
	
	
	
	return(
		 <React.Fragment>
                <div style={{margin:'1rem auto 0 auto'}}>
				   <form autocomplete="off">
					{/* Question Field */}
						 <QuestionField topic={topic} setTopic={setTopic} textQuestion={textQuestion} setTextQuestion={setTextQuestion}  setImageQuestion={setImageQuestion}  imageQuestion={imageQuestion} />
				   	  
				    {/* Options Dynamic */}
						<div>		
							<select  onChange={(e) => setOptionsCount(e.target.value)} >
								<option value='0' >Select Number of Options </option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
							</select>
						</div>
					{/* Options  */}
						<div  >
							{optionList.map((el,i) => {
								return (
									<Options  i={i} correctOption={correctOption} setCorrectOption={setCorrectOption} 
										textAnswers={textAnswers}  setTextAnswers={setTextAnswers}  imageAnswers={imageAnswers} setImageAnswers={setImageAnswers} 
									/>
								)
								})
							}
						</div>
					{/* Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '15px', outline: "none" }}
							onClick={(e) => updateQuestion(e)}
                            endIcon={<SaveIcon />}
						 >Add Question 
						</Button>
						<Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '15px', outline: "none" }}
							onClick={(e) => setAddMode(false)}                       
						 >Cancel
						</Button>
                    </form>
                </div>
            </React.Fragment>
	)
}