import React,{useEffect,useState} from 'react';
import {Form,Button,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom'

export default function CallbackForm(){
	
	const [submitted,setSubmitted] = useState(false);
	const [mentor,setMentor] = useState('');
	const [slot,setSlot] = useState('Any Time');
	const [error,setError] = useState('');
	const [isDiasbled,setDisabled] = useState(false);
	
	const [date,setDate] = useState(() => {
		let DATE = new Date(); //application date
		let newTimeStamp = DATE.valueOf() + 86400000  //new Time stamp
		let applicationDate = new Date(newTimeStamp)
		
		let oldMilliseconds = DATE.valueOf();
		let newMilliseconds = DATE.valueOf() + 86400000*7;
		let newDate = new Date(newMilliseconds);  //next 7 days
		
	
	
	
		return {min:{year:applicationDate.getFullYear(),
					month:(applicationDate.getMonth()+1).toLocaleString().padStart(2,0),
					day:applicationDate.getDate().toLocaleString().padStart(2,0)},
				max:{year:newDate.getFullYear(),
					month:(newDate.getMonth()+1).toLocaleString().padStart(2,0),
					day:newDate.getDate().toLocaleString().padStart(2,0)
				 }
			  };
		
	});
	

	const [selectedDate,setSelectedDate] = useState('');
	const [query,setQuery] = useState('');
	
	function handleSubmit(e){
		
		if(JSON.parse(localStorage.getItem('user'))==null){
			let redirect = window.location.href;
			localStorage.setItem('redirect',redirect);
			window.location.href = '/login';
		}
		
		e.preventDefault();
		
		if(!selectedDate || !query){
			setError('Please Enter Details Correctly');
			return;
		} 
		
		let minTimestamp = new Date(`${date.min.year}-${date.min.month}-${date.min.day}`).valueOf();
		
		let maxTimestamp = new Date(`${date.max.year}-${date.max.month}-${date.max.day}`).valueOf();
		
		let selectedDateTimestamp = new Date(selectedDate).valueOf();
		
		
		if(selectedDateTimestamp < minTimestamp || selectedDateTimestamp > maxTimestamp){
			setError('Please Select Valid Date');
			return;
		}
		
		
		let userInfo = JSON.parse(localStorage.getItem('user'));
		console.log()
		let menteeID = JSON.parse(localStorage.getItem('user'))._id
		let category = JSON.parse(localStorage.getItem('user')).category
		let mobile = JSON.parse(localStorage.getItem('user')).mobile

		const token = localStorage.getItem('token');
		setDisabled(true);
		fetch(`https://${window.location.hostname}:5005/mentee/callback/${menteeID}`,{
			method:'POST',
			headers:{"Content-Type":"application/json",'Authorization':`Bearer ${token}`},
			body:JSON.stringify({selectedDate,category,query,userInfo,mentor,slot,mobile})
		}).then((res) => {
			//alert(res);
			console.log(res);
			if(res.ok){
				setSubmitted(true);
			}else{
			
				if(res.status == 401){
					setError(`You do not have a valid Callback plan.Please buy a Plan for Quality mentoring`);
					setTimeout(() => {
						if(JSON.parse(localStorage.getItem('user')).category=='CAREER'){
						window.location.href ='/plans';
					}				
					else{
						window.location.href ='/pricing';
					}   }, 
					2000);
					
				}else{
					throw('Network Error')
				}
				
			}
		})
		.catch(e => {
			setError('Please Try Later');
			setDisabled(false)
		})
	}
	

	  if(submitted){
		  return (
		  <div style={{height:'90vh',display:'flex',justifyContent:'center',flexDirection:'column'}} >
			  <h3 className="text-success text-center" >Your Request Has Been Successfully Registered </h3>
			  <p className="text-muted mt-3" >To check the Status of your request , Go to <Link to='/mentee/dashboard'>Dashboard</Link> in top right corner</p>
			  <p className="text-muted" >Within 24 hours You will get an <b style={{padding:'0'}} >Email</b> Notification  when your request is Accepted. </p>
		  </div>
		  )
	  }else{
	
	 return(
	 <>
		<p className='text-danger' >{(error.length > 0)?error:null}</p>
		<Form >	
			<Form.Row>
				<Col>
				<Form.Group controlId="exampleForm.ControlTextarea1">
				    <h5>Talk with our Top <a href="/mentors">Mentors</a></h5>
				    <p>If You are not logged in, Then please <a href="/login">Login</a></p>
					<Form.Label>Query</Form.Label>
					<Form.Control onChange={e => setQuery(e.target.value)} as="textarea"  placeholder="Write your Query" rows={3} />
				</Form.Group>
				</Col>
			</Form.Row>
			<Form.Row>
			<Col>
			<Form.Group controlId="selectSlot" style={{display:'flex',flexDirection:'column'}} >
					<Form.Label>Select Date </Form.Label>
					<input onChange={e => setSelectedDate(e.target.value)} className="form-control" name="selectedDate" type="date"
						
						min={`${date.min.year}-${date.min.month}-${date.min.day}`}
						max={`${date.max.year}-${date.max.month}-${date.max.day}`}
					/>
			</Form.Group>
			</Col>
		  </Form.Row>
		  <Form.Row>
				<Col>
				<Form.Group controlId="selectSlot" style={{display:'flex',flexDirection:'column'}} >
					<Form.Label>Select Slot </Form.Label>
					<select style={{padding:'10px'}} onChange={e => setSlot(e.target.value)} >
						<option value="Any Time" >Any Time</option>
						<option value="1PM:3PM" >Slot 1 (1PM:3PM)</option>
						<option value="5PM:7PM" >Slot 2 (5PM:7PM)</option>
						<option value="9PM:10PM" >Slot 3 (9PM:10PM)</option>
					</select>
				</Form.Group>
				</Col>
			</Form.Row>
		  <Form.Row>
				<Col>
				<Form.Group controlId="preferredMentor">
					<Form.Label>Any Preferred Mentor</Form.Label>
					<Form.Control onChange={e => setMentor(e.target.value)} value={mentor}  placeholder="Want to Talk to any Particular  Mentor, Enter his  Name"  />
				</Form.Group>
				</Col>
			</Form.Row>
		   <div className='Btn' style={{textAlign:'center',marginBottom:'20px'}}>
				<Button disabled={isDiasbled} type='submit' onClick={(e) => handleSubmit(e)} className='btn btn-warning' >Submit</Button>
		   </div>
		</Form>
	 </>
	)}
  
}
