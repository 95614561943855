import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Card, Button} from 'react-bootstrap';
import DisplayQuestion from './DisplayQuestions';
import QuestionPalette from './QuestionPalette';
import Timer from '../utils/Timer';

/* Child Component of Mock Test  */



export default function StartTest({Testinfo,setStage}){
	
	if(!Testinfo){
		//alert('Getting from localStorage')
		Testinfo = JSON.parse(localStorage.getItem('TEST_INFO'));
	}
	
	//if test has sections
	const availableSections = []
	let [selectedSection,changeSection] = useState(0); //default if not divided in sections
	
	if(Testinfo && Testinfo.isDividedInSection){
		//get request to get all sub sections
		let allSection = JSON.parse(localStorage.getItem(`${Testinfo._id}_SECTIONS`));
		allSection && allSection.forEach(sct => {
			let questions = sct.questionList 
			let sectionName = sct.name
			let sectionID = sct._id
			let combine = {questions,sectionName,sectionID}
			availableSections.push(combine)
		})
		
	}else{
		//Test Exist and has no sub sections
		if(Testinfo && Testinfo.questionList){
			let questions = Testinfo.questionList || []
			let sectionName = Testinfo.name
			let sectionID = Testinfo._id
			let combine = {questions,sectionName,sectionID}
			availableSections[0] = combine
		}
	}
	
	
	//if test has  no section
	//let questionsArray = (Testinfo && Testinfo.questionList) || [];
	
	
	
	
	const [err,setError] = useState('');
	const [questions,setQuestions] = useState(availableSections.length >0?availableSections[selectedSection]['questions']:[]); //array
	//
	let questionsArray = questions || [];
	let Local_Storage = '';
	//to prevent restarting of test on refreshing
	localStorage.setItem('started',1);
	
	if(localStorage.getItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`)){
		Local_Storage = JSON.parse(localStorage.getItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`));
		console.log(Local_Storage);
	}else{
		//alert('NOT EXIST');
		localStorage.setItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`,JSON.stringify({}));
	}
	//const [answers,setAnswers] = useState(Local_Storage); //object
	
	const [selectedQuestion,changeQuestion] = useState({'id':questionsArray[0],index:0});
	//will be used to highlight the current question
	function PreviousQuestion(){
		let curIndex = selectedQuestion.index;
		//if question is first
		if(curIndex == 0) return;
		let prevQuestionIndex = parseInt(curIndex - 1);
		let prevQuestionId = questionsArray[prevQuestionIndex];
		console.log(curIndex,prevQuestionIndex,prevQuestionId);
		changeQuestion({'id':prevQuestionId,'index':prevQuestionIndex});
	}
	
	function NextQuestion(){
		let curIndex = parseInt(selectedQuestion.index);
		let curQuestionId = selectedQuestion.id;
		//
		/* if(!answers[curQuestionId]){
			//Question is being visited first time
			console.log('Question is being visited first time');
			console.log('Question is not answered');
			let myArray = {};
			myArray[curQuestionId] = {color:'red'};
			setAnswers({...answers,...myArray});
			
		} */
		//
		if(!Local_Storage[curQuestionId]){
			//Question is being visited first time
			console.log('Question is being visited first time');
			console.log('Question is not answered');
			let myArray = {};
			myArray[curQuestionId] = {color:'red'};
			//setAnswers({...answers,...myArray});
			localStorage.setItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`,JSON.stringify({...Local_Storage,...myArray}))
		}
		//if question is last
		if(curIndex === (questionsArray.length - 1)) return;
		let nextQuestionIndex = parseInt(curIndex + 1);
		let nextQuestionId = questionsArray[nextQuestionIndex];
		console.log('--------------------------------------------')
		console.log(curIndex,curQuestionId,nextQuestionIndex,nextQuestionId);
		setError('');
		changeQuestion({'id':nextQuestionId,'index':nextQuestionIndex});
	}
	
	
	function handleSaveNext(index){
		console.log('----------------------');
		console.log(typeof index);
		console.log(index);
		if(!index){
			//alert('NO INDEX')
			setError({'index':'Option Not Selected'});
			return;
		}else{
			//alert('YES INDEX')
				let curIndex = parseInt(selectedQuestion.index);
				let curQuestionId = selectedQuestion.id;
				//may be required to get old state
				let myArray = {};
				myArray[curQuestionId] = {color:'green','selectedIndex':index}
				//setAnswers({...answers,...myArray});
				localStorage.setItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`,JSON.stringify({...Local_Storage,...myArray}))	
				setError('');
		        //if question is last
				if(curIndex === (questionsArray.length - 1)){
					//alert('No more Questions');
					changeQuestion({'id':questionsArray[0],'index':0});
					return;
				}
				let nextQuestionIndex = parseInt(curIndex + 1);
				let nextQuestionId = questionsArray[nextQuestionIndex];
				console.log('--------------------------------------------')
		console.log(curIndex,curQuestionId,nextQuestionIndex,nextQuestionId);
		
				changeQuestion({'id':nextQuestionId,'index':nextQuestionIndex});  
		}
	}
	
	function MarkReview(index){
		let curIndex = parseInt(selectedQuestion.index);
		let curQuestionId = selectedQuestion.id;
		let nextQuestionIndex,nextQuestionId;
		//not the last element
		if(curIndex === (questionsArray.length - 1)){
			//alert('No more Questions left');
		}else{
		   nextQuestionIndex = parseInt(curIndex + 1);
		   nextQuestionId = questionsArray[nextQuestionIndex];
		}
		let myArray = {};
		//mark for review clicked but not answered ----  Violet
		if(!index){
			//alert('Unanswered mark for review')
			myArray[curQuestionId] = {color:'violet'}
		//	setAnswers({...answers,...myArray});
		    localStorage.setItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`,JSON.stringify({...Local_Storage,...myArray}))					
			if(!nextQuestionId && !nextQuestionIndex){
				//alert('No More Questions')
				changeQuestion({'id':questionsArray[0],'index':0});
				return;
			}
			setError('');
			changeQuestion({'id':nextQuestionId,'index':nextQuestionIndex});  
		}else{
			//alert('YES INDEX and marked for review')
			    //will be considered for evaluation
				myArray[curQuestionId] = {color:'orange','selectedIndex':index}
				//setAnswers({...answers,...myArray});
		        localStorage.setItem(`${availableSections[selectedSection]['sectionID']}_TEST_ANSWERS`,JSON.stringify({...Local_Storage,...myArray}))					
				//if question is last
				if(!nextQuestionId && !nextQuestionIndex){
					//alert('No More Questions')
					changeQuestion({'id':questionsArray[0],'index':0}); 
					return;
				}
				setError('');
				changeQuestion({'id':nextQuestionId,'index':nextQuestionIndex});  
		}
	}
	
	if(!localStorage.getItem('TIME')){
		//alert('Timer is not present before');
		//let timeInfo = {};
		let start = new Date().getTime();
		let duration = parseInt(Testinfo.testDuration) * 1000; //we are recieving time in seconds
		let end = new Date(start + duration ).getTime();
		console.log('Test Started at ',start);
		console.log('Test End at ',end);
	/* 	timeInfo['start'] = new Date().getTime();
		timeInfo['duration'] = 1800 * 1000 //in millesecond
		timeInfo['end'] = new Date().getTime(); */
		
		
		localStorage.setItem('TIME',JSON.stringify({start,end}))
	}
	
	useEffect(() => {
		setQuestions(availableSections.length >0?availableSections[selectedSection]['questions']:[]);
		setTimeout(() => {
			questionsArray = availableSections[selectedSection]['questions']
			changeQuestion({'id':questionsArray[0],index:0})
		},250)
		
	},[selectedSection])	
		
	return(
		<Container  style={{marginTop:'1.5rem'}}>
			<p>{err?err.index:null}</p>
			<Timer setStage={setStage} />
			{/* Available Sections */}
			{availableSections.map((sect,index) => <Button  style={{margin:'1rem',backgroundColor:'white',color:index== selectedSection?'blue':'black',border:index== selectedSection?'2px solid black':'none'}} onClick={(e) => changeSection(e.target.value)}  value={index} >{sect.sectionName}</Button>)}
			<Row>
				
				{/* Test Question */}
				<DisplayQuestion sectionID={availableSections[selectedSection]['sectionID']} selectedQuestion={selectedQuestion} PreviousQuestion={PreviousQuestion}  NextQuestion={NextQuestion} handleSaveNext={handleSaveNext} MarkReview={MarkReview} />
				{/* Questions Palette*/}
				<QuestionPalette sectionID={availableSections[selectedSection]['sectionID']} setStage={setStage} questions={questions} changeQuestion={changeQuestion}  selectedQuestion={selectedQuestion} />
				
			</Row>
		</Container>
	)
}