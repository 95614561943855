import React,{useEffect} from 'react';
import CallbackForm from './CallbackForm';


export default function ScheduleMeetingPage(){
		
		useEffect(() => {
			 if(!localStorage.getItem('token') || !localStorage.getItem('user')){
			/* 	window.location.href = '/login';
				 return null;  */
			 }
		},[])
		
		return(
			<div style={{maxWidth:'500px',margin:'0 auto'}} >
				<CallbackForm />
			</div>
		)
}