import React, { useState,useEffect } from 'react';
import { Card } from 'reactstrap';
import { TextField, IconButton, Radio, Typography, Fab, Button ,Badge,Checkbox} from '@material-ui/core';
import CropOriginalIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import axios from 'axios';
import Options from '../TestComponents/Options';
import {Modal,Form} from 'react-bootstrap';
import MUIRichTextEditor from 'mui-rte';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export default function EditQuestion({questionDisplay,setEditMode,setLastEdited}){
	
	const [topic,setTopic] = useState(questionDisplay.topic);
	const [optionsCount,setOptionsCount] = useState(questionDisplay.textAnswers || questionDisplay.imageAnswers);
	
	const [correctOption,setCorrectOption] = useState(questionDisplay.correctOption);
	
	const [textQuestion,setTextQuestion] = useState(questionDisplay.textQuestion);
	const [imageQuestion,setImageQuestion] = useState(questionDisplay.imageQuestions);

	const [textAnswers,setTextAnswers] = useState(questionDisplay.textAnswers);
	const [imageAnswers,setImageAnswers] = useState(questionDisplay.imageAnswers);
	
	const [optionList,setOptionList] = useState([]);
	
	function check(){
		let code = true;
		if(!topic) code = false;
		if(!textQuestion && !imageQuestion) code = false; 
		if(!textAnswers && !imageAnswers) code = false; 
		if(!correctOption) code = false
		if(correctOption){
			let numberOfCorrect = 0;
			Object.keys(correctOption).forEach(key => {
				if(correctOption[key] == true) numberOfCorrect += 1;
			})
			if(numberOfCorrect == 0) code = false;
		}
		if(optionsCount === 0) code = false
		return code;
	}
	

	function updateQuestion(e){
		e.preventDefault();
		if(!check()){
			alert('Required Fields Missing ')
			return;
		}
	
		const data = new FormData();
		data.append('topics',topic);
		data.append('correct',JSON.stringify(correctOption));
		data.append('textQuestion',textQuestion);
		data.append('imageQuestion',imageQuestion);
		data.append('textAnswers',JSON.stringify(textAnswers));
		data.append('imageAnswers_0',imageAnswers[0]);
		data.append('imageAnswers_1',imageAnswers[1]);
		data.append('imageAnswers_2',imageAnswers[2]);
		data.append('imageAnswers_3',imageAnswers[3]);
		data.append('imageAnswers_4',imageAnswers[4]);
		
		let userInfo = JSON.parse(localStorage.getItem('user'));				
		const token = localStorage.getItem('token');
		fetch(`https://${window.location.hostname}:5005/mentor/addNewQuestion/update/${questionDisplay._id}`,{
			method:"POST",
			headers:{'Authorization':`Bearer ${token}`},		
			body:data
		})
		.then(res => {
			if(res.ok){
				alert('Success')
			}else{
				alert('ERROR');
			}
		})
		.catch(e => {
			alert('Server ERROR');
		})
		
	}//end here add question
	
	
	/* function options(){
		let count = parseInt(optionsCount);
		//let myArrayOptions = new Array(count);
		let newArray = [];
		//let correct = [];
		
		for(let i=0; i < count;i++ ){
				newArray.push(<div key={i} style={{ display: 'flex', flexDirection: 'row', marginLeft: '-13px',justifyContent:'space-between',alignItems:'center'}}>
							<Checkbox
								checked={correctOption[i]}
								onChange={() => {
									let oldCorrect = correctOption;
									oldCorrect[i] = !oldCorrect[i];
									setCorrectOption({...oldCorrect});
								}}
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
							<TextField fullWidth={true} placeholder={'Write option'} style={{ marginTop: '5px' }} required  id={i} value={textAnswers[i]} 
									onChange={(e) => {
										  let oldTexAnswers =  textAnswers;
										  oldTexAnswers[e.target.id] = e.target.value;
										  //oldTexAnswers[e.target.]
										setTextAnswers([...oldTexAnswers]);
									}} />
							<Form.File
							  required
							  id={i}
							  type="file"
							  name="imageAnswers"
							  feedbackTooltip
							  style={{color:'black'}}
							  onChange={(e) => { 
									//setErr({...err,image:''}); 
									//setImage(e.target.files[0]);
									let oldImageAnswers =  imageAnswers;
									let id = e.target.id;
									if(id == '') id = 0;
										 oldImageAnswers[id] = e.target.files[0];
										  //oldTexAnswers[e.target.]
										console.log(id)
										console.log(oldImageAnswers)
										setImageAnswers([...oldImageAnswers]);
								}}
							  />
						  <IconButton aria-label="delete" style={{color:imageAnswers[i]?'green':'red',marginRight:'10px'}} >
                                    <CropOriginalIcon />
                           </IconButton>
					  </div>);
		};
		setOptionList(newArray);
	} 
	
	 */
	 
	 	
  function options(){
		let count = parseInt(optionsCount);
		let newArray = [];
		let newTextArray = [];
		let newImageArray = [];
		for(let i=0;i < count;i++){
			newArray.push(`yes`);
			newTextArray.push(textAnswers[i]);
			newImageArray.push(imageAnswers[i]);
		}		
		setTextAnswers(newTextArray);
		setImageAnswers(newImageArray);
		setOptionList(newArray);
	} 
	
	useEffect(() => {
			options();
	},[optionsCount])
	
	
	 
	
	return(
		 <React.Fragment>
                <div style={{margin:'1rem auto 0 auto'}}>
					<span style={{color:'red'}} >You need to reupload the images</span>
					<p style={{color:'red'}} >You Need To Save Question using the Save ICON on Toolbar </p>
					<p style={{color:'red'}} >You Need To Check All Options are there if not , you need to add them again </p>
				   <form autocomplete="off">
						{/* Question Field */}
						   <div style={{marginBottom:'1rem'}}>
								<Typography style={{marginTop: '20px',color:'black' }}>Topics Covered  in this question</Typography>
								<TextField fullWidth={true} placeholder={'Write Topics with comma separated like  sets,triangles,probability e.t.c '} style={{ marginTop: '5px' }} required   value={topic}  onChange={(e) => setTopic(e.target.value)}/>                
						   </div>
						   <div >
								<Typography style={{ marginTop: '20px',color:'black' }}>Question</Typography>
								<div  style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }} >
									{ /*  Adding NEW TEXT EDITOR */}
								{!textQuestion.includes('{"blocks"')?<TextField
										fullWidth={true}
										placeholder={`Type your question ...`}
										style={{ marginBottom: '18px' }}
										rows={2}
										rowsMax={20}
										multiline={true}
										required
										name='question'
										value={textQuestion}
										variant="filled"
										onChange={(e) => setTextQuestion(e.target.value)}
									/>:(
									 <MUIRichTextEditor 
										label="Start .."
										controls={['superscript','subscript','bulletList','italic','underline','bold','save']}
										name='question'
										customControls={[
												{
													name: "superscript",
													icon:<ArrowUpwardIcon />,
													type:'inline',
													inlineStyle:{
														fontSize:'x-small',
														verticalAlign:'super'
													}
												},
												{
													name: "subscript",
													icon: <ArrowDownwardIcon />,
													type:'inline',
													inlineStyle:{
														fontSize:'x-small',
														verticalAlign:'sub'
													}
												}
												
											]}
										value={textQuestion}
										onSave={data => {
											setTextQuestion(data);
										}}
										/>)
								}
									<Form.File
										  required
										  style={{color:'black'}}
										  name="imageQuestion"
										  label="Optional Image"
										  feedbackTooltip
										  style={{marginLeft:'3rem',color:'black'}}
										  onChange={(e) => { 
												setImageQuestion(e.target.files[0])
											}}
										  />
									<IconButton aria-label="image" style={{ outline: 'none' }}  >
										<CropOriginalIcon style={{color:imageQuestion?'green':'red',marginLeft:'1rem'}} />
									</IconButton>
								</div>
							</div>
				    {/* Options Dynamic */}
						<div>		
							<select onChange={(e) => {
											setOptionsCount(e.target.value)
										}} >
								<option value='0' >Select Number of Options </option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
							</select>
						</div>
						<div  >
							{optionList.map((el,i) => {
								return (
									<Options  i={i} correctOption={correctOption} setCorrectOption={setCorrectOption} 
										textAnswers={textAnswers}  setTextAnswers={setTextAnswers}  imageAnswers={imageAnswers} setImageAnswers={setImageAnswers} 
									/>
								)
								})
							}
						</div>
					{/* Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '15px', outline: "none" }}
							onClick={(e) => updateQuestion(e)}
                            endIcon={<SaveIcon />}
						 >Update Question 
						</Button>
						<Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '15px', outline: "none" }}
							onClick={(e) => setEditMode(false)}                       
						 >Cancel
						</Button>
                    </form>
                </div>
            </React.Fragment>
	)
}