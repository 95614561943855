import Axios from "axios";
import { Button } from "react-bootstrap";
import React from "react";
import { Redirect } from "react-router-dom";
const PaymentGateWay = ({coupon,amount,PlanId}) =>{
   
    const handlePayment = (e) =>{
        e.preventDefault();
		 if(localStorage.user)
			{
				var _id = JSON.parse(localStorage.getItem("user"))._id
			}
		   
		else
		{
			alert('Please Login First');
			localStorage.setItem('redirect','/pricing')
		    window.location.href = '/login'
		}
		
		///For Coupon
	
	
		//
	
        Axios.post(`https://${window.location.hostname}:5005/generateorder/${amount}`,{coupon})
        .then((order_details)=>{
            console.log(order_details)
              const options = {
                key_id : 'rzp_test_x1wrukWWgYpZX2',
                name : 'MentoMeet',
                description : 'Mentomeet Subscription',
                order_id : order_details.data.id,
                theme : {
                    color:"#686CFD"
                },
                prefill:{
                    contact:JSON.parse(localStorage.getItem("user")).mobile,//this should be fetched from database
                    email:JSON.parse(localStorage.getItem("user")).email  //this should be fetched from database
                },
                "handler" : (Payment_response)=>{
                    console.log(Payment_response)
                    Axios.post(`https://${window.location.hostname}:5005/callback`,
                    {
                        razorpay_order_id: Payment_response.razorpay_order_id,
                        razorpay_payment_id : Payment_response.razorpay_payment_id,
                        razorpay_signature : Payment_response.razorpay_signature,
                        _id : _id,
						PlanId:PlanId,
						coupon:coupon
                    } 
                )
                .then((resp)=>{
                    console.log(resp)
                   // if (resp.data.authentic && resp.data.captured) c
                      return alert("Successful payment!! receipt sent to the email address and your money will be sent to the mentor soon!")
                })
                }
                
              }
              const rzp1 = new window.Razorpay(options)
              rzp1.open();
        })

    }
   return(
		<Button  onClick = {handlePayment} variant="outline-warning" style={{color:'black',borderRadius:'15px'}} >BUY NOW</Button>
		
   )
}
export default PaymentGateWay;

  /*  <Button onClick = {handlePayment} variant="primary" style={{marginLeft:"33%"}}>
           choose
       </Button> */
