import React, { Component } from "react";
import Carousel from "react-elastic-carousel";
import "./style.css";
import $ from "jquery";
import avinash from "../../assets/testimonial/avinash.jpeg";
import aman from "../../assets/testimonial/aman.jpeg";
import shiv from "../../assets/testimonial/shiv.jpeg";
import shivam from "../../assets/testimonial/shivam.jpeg";

class Testimonial extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <React.Fragment>
        <div
          class="container"
          style={{ flexDirection: "column", height: "100%" }}
        >
          <div class="slider-area">
            <div
              id="carousel-example-generic"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="3"
                ></li>
              </ol>

              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  {/*  <div class="img-area">
                        <img src={aman} alt=""/>
						</div> */}
                  <div class="carousel-caption">
                    <p>
                      <span>
                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                      </span>
                      My experience with MentoMeet was good.Their one-on-one
                      mentorship program helped me to cover up my weak points
                      and also helped in my counseling for college selection.
                      The mentors at Mentomeet also shared their exam
                      strategies, revision plan, mistakes they made. Whenever I
                      faced problems I sheduled a meeting with the mentor and
                      they helped always
                      <span>
                        <i class="fa fa-quote-right" aria-hidden="true"></i>
                      </span>
                    </p>
                    <h3>Aman(IIT Gandhinagar) </h3>
                  </div>
                </div>
                <div class="carousel-item">
                  {/* <div class="img-area">
                        <img src={shiv} alt=""/>
					</div> */}
                  <div class="carousel-caption">
                    <p>
                      <span>
                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                      </span>
                      Mentors and their eagerness to express their own insights
                      and wisdom by listening carefully are incredible. Beyond
                      that, they're going to help you out with topics from
                      fundamentals to the point you're able to do so. They also
                      provided me with their test series which allowed me to
                      check my knowledge.
                      <span>
                        <i class="fa fa-quote-right" aria-hidden="true"></i>
                      </span>
                    </p>
                    <h3>Shiv Bhushan(NEET Aspirant) </h3>
                  </div>
                </div>
                <div class="carousel-item">
                  {/*  <div class="img-area">
                        <img src={shivam} alt=""/>
					</div> */}
                  <div class="carousel-caption">
                    <p>
                      <span>
                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                      </span>
                      The great thing about the mentors here is that they never
                      let you believe that it's too late to start while
                      remaining honest at the same time. Eventually, you'll
                      understand about the subject in time. There webinars kept
                      me motivated which helped me to focus on my subjects. They
                      also proven that a piece of knowledge can be googled, but
                      experience is the true secret
                      <span>
                        <i class="fa fa-quote-right" aria-hidden="true"></i>
                      </span>
                    </p>
                    <h3>Shivam (JEE Aspirant) </h3>
                  </div>
                </div>
                <div class="carousel-item">
                  {/*  <div class="img-area">
                        <img src={avinash} alt=""/>
				  </div> */}
                  <div class="carousel-caption">
                    <p>
                      <span>
                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                      </span>
                      Mentors helped me regarding my college selection and now
                      helping me in my college journey.They always motivate me
                      and tells me about how to get internships,GSOC starting
                      from first year itself and guides me how to be a topics
                      coder.overall My experience as a mentee with MentoMeet was
                      good.
                      <span>
                        <i class="fa fa-quote-right" aria-hidden="true"></i>
                      </span>
                    </p>
                    <h3>Avinash(NIT SriNagar) </h3>
                  </div>
                </div>
              </div>

              <a
                class="left carousel-control"
                href="#carousel-example-generic"
                role="button"
                data-slide="prev"
              >
                <span
                  class="glyphicon glyphicon-chevron-left"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="right carousel-control"
                href="#carousel-example-generic"
                role="button"
                data-slide="next"
              >
                <span
                  class="glyphicon glyphicon-chevron-right"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
