import React,{useState,useEffect} from 'react'
import {Container,Row,Col,Card, Button} from 'react-bootstrap';

/* Child component of mock test */
export default function Result({Testinfo,setStage}){
	
	if(!Testinfo){
		//alert('Getting from localStorage')
		Testinfo = JSON.parse(localStorage.getItem('TEST_INFO'));
	}
	
	//remove started from localStorage
	localStorage.removeItem('started');
	//let [marksObtained,setMarks] = useState('...');
	let [result,setResult] = useState([]);
	
	
	let AnswersPerSection = []
	
	useEffect(() => {
			if(Testinfo && Testinfo.isDividedInSection){
			//get request to get all sub sections
			let allSection = JSON.parse(localStorage.getItem(`${Testinfo._id}_SECTIONS`));
			allSection && allSection.forEach(sct => {
				let _id = sct._id
				
				//do per test
				let RED = [],GREEN = [],VIOLET=[],ORANGE=[];
				
				let questionsArray = sct.questionList ;
				let answers = JSON.parse(localStorage.getItem(`${sct._id}_TEST_ANSWERS`)) || {}
				
				questionsArray && questionsArray.forEach((q) => {
				//console.log('Question ID',q);
				if(answers[q]){
						//console.log('Answer is  present in answer list');
						if(answers[q]['color'] === 'violet'){
							VIOLET.push(answers[q]);
						}
						if(answers[q]['color'] === 'orange'){
							ORANGE.push(answers[q]);
						}
						if(answers[q]['color'] === 'green'){
							GREEN.push(answers[q]);
						}
						if(answers[q]['color'] === 'red'){
							RED.push(answers[q]);
						}
					}
					
				});
				
				let extraInfo = {RED,GREEN,ORANGE,VIOLET}
				let combine = {_id,answers,extraInfo}
				AnswersPerSection.push(combine)
			})
			
		
			let userInfo = JSON.parse(localStorage.getItem('user'));
			let timeInfo = JSON.parse(localStorage.getItem('TIME'));
			if(!timeInfo) return;
			timeInfo.complete = new Date().getTime();
			const token = localStorage.getItem('token');		
			fetch(`https://${window.location.hostname}:5005/mentee/mocktest/combine/result/${Testinfo._id}`,{
				method:'POST',
				headers:{'Content-Type':'application/json','Authorization':`Bearer ${token}`},
				body:JSON.stringify({AnswersPerSection,userInfo,timeInfo})
			})
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						//alert('result rec');
						console.log(data);
						//setMarks(data.marksObtained);
						setResult(data.overallResult);
					})
				}else{
					alert('There is some problem !!');
				}
			})
			.catch(err => {
				alert('SERVER ERROR');
			})
			
		}
	},[])
	
	
	

	
	//let attendedQuestion = GREEN.length  + ORANGE.length;
	//let unattendedQuestion = (Testinfo.questionList.length) - attendedQuestion;
			
	//const MarksObtained = () => {
		//return (parseInt(GREEN.length) + parseInt(ORANGE.length)) *  (Testinfo.correctAnswerMarks);
	//}
	
	const generateBody = result && result.map(el => {
		return (<tr>
				 <td>{el.name}</td>
				 <td>{el.correct}</td>
				 <td>{el.incorrect}</td>
				 <td>{el.attendedQuestion}</td>
				 <td>{el.unattendedQuestion}</td>
				 <td>{el.marksObtained}</td>
				</tr>);
	})
	
	return(
		<Container style={{overflowX: 'hidden',fontFamily: 'Roboto,sans-serif'}}>
			<h1>Combine Result</h1>
			<Row style={{marginBottom:'5%'}}>
				<table className="table table-striped table-bordered mt-4">
					<thead className="thead-dark">
						<tr>
						  <th scope="col">Section Name</th>
						  <th scope="col">Correct Answers</th>
						  <th scope="col">InCorrect Answers</th>
						  <th scope="col">Attended Question</th>
						  <th scope="col">Unattended Question</th>
						  <th scope="col">Marks Obtained</th>
						</tr>
					 </thead>
					<tbody>
						{generateBody}
					</tbody>	
				</table>
			</Row>
			<div style={{display:'flex',flexDirection:'column',width:'100vw',marginBottom:'5rem'}} >
				<div>
					<button className="btn btn-primary" 
					onClick={(e) => {
								e.preventDefault();
								localStorage.removeItem('TEST_INFO');						
								localStorage.removeItem('TIME');						
								window.location.href = '/mentee/mocktest'
						}} >Get Back to Mock Test Page</button>
				</div>
			</div>
		</Container>
	)
}