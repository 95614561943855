import React, { useState } from "react";
import './FAQ.css';

const faqs = [
  { question: "What is MentorMEET?", answer: "MentoMEET connects students with expert mentors for personalized 1:1 guidance in exams preparations, career exploration, and skill development." },
  { question: " How does the monitoring process work?", answer: "After each session, mentors and students can track progress, set goals, and review improvements over time." },
  { question: " Can I join as a mentor?", answer: "Absolutely! We’re always looking for passionate mentors. Apply on our website via Become a Mentor." },
  { question: "How do I find the right mentor?", answer: "Use our search filters to find a mentor whose experience and interests align with your goals." },
  { question: " Can I have multiple mentors?", answer: "Yes! You can connect with multiple mentors to gain different perspectives and expertise." },
];

const NewFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    console.log("index",index)
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <h2>FAQ</h2>
      <p>Got Questions? We've Got Answers</p>
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>{faq.question}</h3>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
      {/* <div className="faq-container">
        <h2>Ready to Start Mentoring?</h2>
        <p>Apply today and become a mentor on MENTOMEET! Share your knowledge, help students achieve their goals, and earn while making a difference.</p>
        <button>Ready to Mentor?</button>
      </div> */}
    </section>
  );
};

export default NewFAQ;

