import React, { useState, useEffect, Fragment } from "react";
import { Button } from "./Button";
import { HomeHeroImage } from "./HomeHeroImage";
import { IconContainerSvg } from "./IconContainer";
import { ReactComponent as AbstractLineImg } from "./AbstractLineImg.svg";
import PureCounter from "@srexi/purecounterjs";
import "./HeroSection.css";
import "./animations.scss";
import { useHistory } from "react-router-dom";

function HeroSection() {
  const history = useHistory();

  const rotatingPhrases = [
    "IIT-JEE Preparation",
    "NEET Preparation",
    "CLAT Preparation",
    "Placement Preparation",
    "Web Development",
    "Product Management",
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prev) => (prev + 1) % rotatingPhrases.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Initialize PureCounter for the stats section
    new PureCounter();
  }, []);

  const getCounterContent = (stat) => {
    if (stat?.suffix) {
      return (
        <Fragment key={stat?.label}>
          <span>{stat.end}</span>
          {stat.suffix || "+"}
          {stat?.hasStar ? "⭐" : null}
        </Fragment>
      );
    }

    return (
      <Fragment key={stat?.label} >
        <span
          className="purecounter"
          data-purecounter-start="0"
          data-purecounter-end={stat.end}
          data-purecounter-duration="2"
        >
          {stat.end}
        </span>
        {stat.suffix || "+"}
        {stat?.hasStar ? "⭐" : null}
      </Fragment>
    );
  };

  return (
    <div className="hero-new-container">
      {/* Main Title */}
      <h2 className="main-title tracking-tight font-normal text-4xl sm:text-6xl leading-tighter">
        <AbstractLineImg
          className="abstract-line"
          style={{ width: "40px", height: "40px", marginBottom: "10px" }}
        />
        <IconContainerSvg />
        <span className="looking">Looking</span> <span>for a Mentor?</span>
      </h2>

      <h3 className="sub-main-title">
        Empowering Students to Achieve Their Goals with{" "}
        <span style={{ color: "#FF9500" }}>1:1</span> Mentorship in{" "}
      </h3>

      <div className="text-container m-t-2" id="animatedText">
        {rotatingPhrases[currentPhraseIndex]}
      </div>

      {/* Buttons */}
      <div className="hero-btns">
        <button
          className="btn1"
          onClick={() => {
            history.push("/mentors");
          }}
        >
          Find Your Mentor
        </button>
        <button
          className="btn2"
          onClick={() => {
            history.push("/register");
          }}
        >
          Schedule a Meeting
        </button>
      </div>

      {/* Hero Image */}
      <div className="hero-home-right">
        <HomeHeroImage width={600} height={600} />
      </div>

      {/* Descriptive Paragraph */}
      <p className="description-text">
        Many students feel lost when it comes to exams and career choices. With
        personalized 1:1 mentorship, we provide clear guidance to help you
        focus, improve your skills, and achieve success at every stage.
      </p>

      {/* Statistics Section with PureCounter */}
      <div className="small-stats-container">
        {[
          { end: 300, label: "Mentors", hasStar: false },
          { end: 3000, label: "Happy Students", hasStar: false },
          { end: 4.8, label: "Mentor Ratings", suffix: "/5", hasStar: true },
        ].map((stat, index) => (
          <div className="stats-container small-stats" key={index}>
            <div className="stats-body">
              <div className="title">{getCounterContent(stat)}</div>
              <p className="sub-title">{stat.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HeroSection;
