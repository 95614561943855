import React, { Component } from "react";
import Carousel from "react-elastic-carousel";
import "./style.css";
import $ from "jquery";

class VideoTestimonial extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <React.Fragment>
        <div
          class="container"
          style={{ flexDirection: "column", height: "100%" }}
        >
          <div class="slider-area">
            <div
              id="carousel-example-generic"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carousel-example-generic"
                  data-slide-to="2"
                ></li>
             
              </ol>

              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <div class="carousel-caption">
                    <div className="video-wrapper">
                      <iframe
                        className="mentor-video"
                        src="https://www.youtube.com/embed/SAiS_q8ILLs"
                        title="Mentor Experience Video 1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  {/* <div class="img-area">
                        <img src={shiv} alt=""/>
					</div> */}
                  <div class="carousel-caption">
                    <div className="video-wrapper">
                      <iframe
                        className="mentor-video"
                       src="https://www.youtube.com/embed/00vR9gruUow"
                        title="Mentor Experience Video 2"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  {/*  <div class="img-area">
                        <img src={shivam} alt=""/>
					</div> */}
                  <div class="carousel-caption">
                    <div className="video-wrapper">
                      <iframe
                        className="mentor-video"
                        src="https://www.youtube.com/embed/P_2kYUWYsT4"
                        title="Mentor Experience Video 1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              
              </div>

              <a
                class="left carousel-control"
                href="#carousel-example-generic"
                role="button"
                data-slide="prev"
              >
                <span
                  class="glyphicon glyphicon-chevron-left"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="right carousel-control"
                href="#carousel-example-generic"
                role="button"
                data-slide="next"
              >
                <span
                  class="glyphicon glyphicon-chevron-right"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default VideoTestimonial;
