import React,{useState,useEffect} from 'react';
import { Card, Button,Badge} from 'react-bootstrap';
import {Model} from 'react-bootstrap';


export default function MyTable({secretId,detail}){
	//let dt = new Date(obj.applicationDate);
	
	const [data,setData] = useState('');


	useEffect(() => {
			const endpoint = `https://${window.location.hostname}:5005/admin/generateResult/${detail}`;
			fetch(endpoint)
			.then(res => {
				if(res.ok){
					//alert('Recieved')
					res.json().then(data => {
						console.log(data);
						setData(data.data);					
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
	},[])
	
	const generateBody = data && data.map(el => {
		return (<tr>
				 <td>{el.menteeName}</td>
				 <td>{el.marksObtained}</td>
				</tr>);
	})
	
	return(
		<table className="table table-striped table-bordered mt-4">
			<thead className="thead-dark">
				<tr>
				  <th scope="col">Mentee Name</th>
				  <th scope="col">Marks Obtained</th>
				</tr>
			 </thead>
			<tbody>
				{generateBody}
			</tbody>	
		</table>
	)
}