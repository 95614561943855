import React from "react";
import Testimonial from './testimonial';
import './testimonial.css';

const mentorTestimonials = [
  {
    name: "Aman (IIT Gandhinagar)",
    description:
      "My experience with MentoMeet was good. Their one-on-one mentorship program helped me to cover up my weak points and also helped in my counseling for college selection. The mentors at Mentomeet also shared their exam strategies, revision plan, mistakes they made. Whenever I faced problems, I scheduled a meeting with the mentor, and they always helped."
  },
  {
    name: "Shiv Bhushan (NEET Aspirant)",
    description:
      "Mentors and their eagerness to express their own insights and wisdom by listening carefully are incredible. Beyond that, they're going to help you out with topics from fundamentals to the point you're able to do so. They also provided me with their test series which allowed me to check my knowledge."
  },
  {
    name: "Shivam (JEE Aspirant)",
    description:
      "The great thing about the mentors here is that they never let you believe that it's too late to start while remaining honest at the same time. Eventually, you'll understand about the subject in time. Their webinars kept me motivated and helped me to focus on my subjects. They also proved that while knowledge can be googled, experience is the true secret."
  },
];

const NewTestimonials = () => {
  return (
    <section className="testimonials">
      <h2>
        See What <span style={{ color: "#FF9500" }}>Students</span> Are Saying{" "}
        <span style={{ color: "#5a159e" }}>About Us!</span>
      </h2>
      <h3>
        Students love the personalized mentorship they receive. From exam
        clarity to career direction, our mentors provide guidance every step of
        the way.
      </h3>
      <Testimonial />
      {/* <div className="testimonial-cards">
        {mentorTestimonials.map((mentor, index) => (
          <div className="testimonial-card" key={index}>
            <p>{mentor.description}</p>
            <h4>{mentor.name}</h4>
          </div>
        ))}
      </div> */}
    </section>
  );
};

export default NewTestimonials;
