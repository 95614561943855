import React from 'react';

import onlineIcon from './../icons/onlineIcon.png';
import closeIcon from './../icons/closeIcon.png';

import './InfoBar.css';

const InfoBar = ({ room, users }) => (
  <div className="infoBar">
    <div className="leftInnerContainer">
      <img className="onlineIcon" src={onlineIcon} alt="online icon" />
      <h3>{room} </h3>{" "} &nbsp;
      <h5>({users.length} active users) </h5>
    </div>
    <div className="rightInnerContainer">
      <a href="/"><img src={closeIcon} alt="close icon" /></a>
    </div>
  </div>
);

export default InfoBar;