import React,{useEffect,useState} from 'react';
import {Container,Row,Col,CardDeck,Button} from 'react-bootstrap';
import Time from '../utils/Time';
import axios from 'axios';

/* Child Component of Mock Test  */
export default function DisplayInstructions({setStage,Testinfo}){
	
	const [checked,setChecked] = useState(false);
	
	function handleProceed(){
		if(!checked){
			alert('Please accept the instructions to give the test');
			return;
		}else{
			
			if(Testinfo && Testinfo.isDividedInSection){
				axios.post(`https://${window.location.hostname}:5005/mentee/mocktest/getSections`,{sectionList:Testinfo.sectionList})
				.then(data => { 
					localStorage.setItem(`${Testinfo._id}_SECTIONS`,JSON.stringify(data.data.result))
				})
				.catch(error => setStage(0))
			}
			
			
			//alert('Getting things ready for test');
			Testinfo && Testinfo.sectionList.forEach(sct => {
				if(localStorage.getItem(`${sct}_TEST_ANSWERS`)){
					localStorage.removeItem(`${sct}_TEST_ANSWERS`);
				}
			})
			localStorage.removeItem('TIME');
			localStorage.removeItem('started');
			/* if(localStorage.getItem('TEST_INFO')){
				localStorage.removeItem('TEST_ANSWERS'); ////needs change
				localStorage.removeItem('TIME');
				localStorage.removeItem('started');
			} */
			setStage(2);
		}
		
	}
	
	return(
		<Container  >
			<h1>Instruction For Mock Test</h1>
			<p>Please Read the Instruction Carefully</p>
			
			<span>General Instructions</span>
			<ul>
				<li>Total duration of {Testinfo.name} is <span style={{color:'red',fontWeight:'800'}} > {(Time(parseInt(Testinfo.testDuration)))} </span>  </li>
				
				{
							Testinfo.isDividedInSection?(
								<></>
							):(
								<>
								<li style={{color:'green',fontWeight:'800'}} >Correct Answer Marks : {Testinfo.correctAnswerMarks} </li>
								<li style={{color:'red',fontWeight:'800'}} >InCorrect Answer Marks : {Testinfo.IncorrectAnswerMarks} </li>
								</>
							)
				}
				<li>The clock will be set at the server</li>
				<li>When the timer reaches zero, the examination will end by 
					itself. You will not be required to
					end or submit your examination </li>
				<li>The Questions Palette displayed on the right side of
						screen will  show the status of each question 
						using one of the following symbol</li>
			     <ul>
					<li style={{fontWeight:'800',align:'center'}} >Considered For Marks Evaluation</li>
						<li style={{color:'green',fontWeight:'500'}} >Green -> Answered</li>
						<li style={{color:'orange',fontWeight:'500'}} >Orange -> Answered and Marked For Review </li>
					<li style={{fontWeight:'800',align:'center'}} > Not Considered For Marks Evaluation</li>
						<li style={{color:'grey',fontWeight:'500'}} >Grey - Not Visited</li>
						<li style={{color:'red',fontWeight:'500'}} >Red - Not Answered</li>
						<li style={{color:'violet',fontWeight:'500'}} >Violet - Mark For Review But Not Answered </li>
				</ul>
			 </ul>
			 
			 <span>Navigating to a Question</span>
			 <ul>
				<li>Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question</li>
				<li>Click on Save & Next to save your answer for the current question and then go to the next question</li>
				<li>Click on Mark Review Button  to mark  Questionit for Review, and then move to the next question</li>
			 </ul>
			 
			 <span>Answering a Question</span>
			 <ul>
				<li>You can move to a particular question by using question palette on Right Hand Side Screen,this will not save the answer</li>
				<li style={{color:'red',fontWeight:'700'}} >To Save your Answer, you must click on the Save & Next Button</li>
				<li style={{color:'red',fontWeight:'700'}} >To mark the Question for Review, Click on the Mark Review  Button</li>
			 </ul>
			 
			<input style={{marginRight:'1rem'}} type='checkbox' onChange={() => setChecked(!checked)} /><span>I have read and understood the instructions</span>
		    <br />
			<Button style={{margin:'1rem auto 1rem auto',display:'flex'}}  className='btn btn-primary' onClick={handleProceed} >Proceed</Button>
		</Container>
	)
}