import React from "react";

export const IconContainerSvg = () =>{

return(
<svg width="80" height="80" viewBox="0 0 297 298" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.492188" y="0.716797" width="296.447" height="296.447" rx="28.6884" fill="#F4E9FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M166.43 78.4596C168.47 79.5972 169.472 81.9797 168.857 84.2335L155.366 133.699H204.598C206.621 133.699 208.451 134.899 209.257 136.755C210.063 138.61 209.692 140.767 208.312 142.246L137.188 218.449C135.594 220.157 133.041 220.558 131 219.42C128.96 218.283 127.958 215.9 128.573 213.646L142.064 164.181H92.8323C90.8094 164.181 88.9792 162.981 88.173 161.125C87.3667 159.27 87.7381 157.113 89.1184 155.634L160.242 79.4305C161.836 77.7226 164.389 77.322 166.43 78.4596Z" fill="#262626"/>
</svg>
);
}