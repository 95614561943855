import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Card, Button} from 'react-bootstrap';
import { Checkbox} from '@material-ui/core';
import EditQuestion from './Actions/EditQuestion';
import AddTestQuestion from './Actions/AddTestQuestion';
import MUIRichTextEditor from 'mui-rte';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
//const AddTestQuestion= require('./Actions/AddTestQuestion')

 /* Child Component of MyAllTestsHistory */
export default function DisplayAllQuestions({detail,selectedTest,setStage}){
		
	let all = JSON.parse(localStorage.getItem(`${detail}.admin_questionList`));
	const [allQuestions,setQuestions] = useState(all);
	const [questionDisplay,setQuestionDisplay] = useState('');
	const [selectedQuestion,setSelectedQuestion] = useState('');
	const [editMode,setEditMode] = useState(false);
	const [addMode,setAddMode] = useState(false);
	const [lastEdited,setLastEdited] = useState('');

	useEffect(() => {
		if(!selectedQuestion) return;
		let userInfo = JSON.parse(localStorage.getItem('user'));		
		fetch(`https://${window.location.hostname}:5005/mentor/question/${selectedQuestion}`)
		  .then(res => {
			  if(res.ok){
				  res.json().then(data => {
					  if(data && data.question){
						  console.log(data.question._id);
						  // setSelectedOption(oldSelectedOption);
						  //setCorrectOption(oldSelectedOption)
						  setQuestionDisplay(data.question);
					  }
				  })
			  }else{
				  alert('Response not OK');
			  }
		  })
		  .catch(err => {
			  alert('ERROR');
		  })
	},[selectedQuestion,lastEdited])
	
	
	const QUESTION = () => {
		let IMAGE_SET = [];
		let show = '';
		switch(questionDisplay.questionType){
			case 'text': 
					if(!questionDisplay.textQuestion.includes('{"blocks"')){
						return <p style={{margin:'0.5rem 0.5rem 0.5rem 0.5rem'}} >
											{questionDisplay.textQuestion}
									</p>
					}else{
					
						return <MUIRichTextEditor 
											label="Start .."
											value={questionDisplay.textQuestion}
											controls={[]}
											customControls={[
													{
														name: "superscript",
														icon:<ArrowUpwardIcon />,
														type:'inline',
														inlineStyle:{
															fontSize:'x-small',
															verticalAlign:'super'
														}
													},
													{
														name: "subscript",
														icon: <ArrowDownwardIcon />,
														type:'inline',
														inlineStyle:{
															fontSize:'x-small',
															verticalAlign:'sub'
														}
													}
													
												]}
											/>
							
									
					}
						
			case 'image': 
				IMAGE_SET = questionDisplay.imageQuestions.map((name) => {
					   
						return  <li><img src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{width:'100px',height:'100px'}} alt='QUESTION' /></li>;
				});
				console.log(IMAGE_SET);
				return( 
					<ul key={questionDisplay._id}  style={{display:'flex',flexDirection:'column',listStyle:'none'}} >
						{
							IMAGE_SET
						}
					</ul>
				)
			case 'both':
				IMAGE_SET = questionDisplay.imageQuestions.map((name) => {					
						return  <img src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{width:'100px',height:'100px'}} alt='QUESTION' />
				});
				return (<div>
								{!questionDisplay.textQuestion.includes('{"blocks"')?<p style={{margin:'0.5rem 0.5rem 0.5rem 0.5rem'}} >{questionDisplay.textQuestion}
									</p>:(
									 <MUIRichTextEditor 
										label="Start .."
										name='question'
										controls={[]}
										value={questionDisplay.textQuestion}
										
										/>)
								}
								{IMAGE_SET}
					  </div>)
			default: return <p>Some ERROR</p>
		}
	} 
	
	const OPTIONS = () => {
		//text
		if(questionDisplay.answerType === 'text'){
			//if only option can be correcy
			//onClick={e => handleSelect(e)
			return (
				<ul key={questionDisplay._id}   style={{display:'flex',flexDirection:'column',listStyle:'none'}} >
					{
					  questionDisplay.textAnswers.map((opt,index) => {
						 return <li >
						 {/* <input  type='checkbox' defaultChecked={(parseInt(selectedOption[index]) === parseInt(index))?true:false}  data-id={index} /> */}
										
									<Checkbox
										checked={questionDisplay.correctOption.includes(index)?true:false}
									
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<span style={{margin:'0.3rem 0.5rem 0.3rem 0.3rem'}} >{opt}</span>
								</li>
					  })
					}
						
				</ul>
			)
		}
		
		//both
		if(questionDisplay.answerType === 'both'){
			//if only option can be correcy
			let IMAGE_SET = questionDisplay.imageAnswers.map((name) => {
					
					return  <img src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{width:'140px',height:'140px'}} alt='Anwers' />
			});
			//onClick={e => handleSelect(e) }
			return (
				<ul key={questionDisplay._id}  style={{display:'flex',flexDirection:'column',listStyle:'none'}} >
					{
					  questionDisplay.textAnswers.map((opt,index) => {
						 return <li >
						 {/* <input  type='checkbox' defaultChecked={(parseInt(selectedOption[index]) === parseInt(index))?true:false}  data-id={index} /> */}
									<Checkbox
										checked={questionDisplay.correctOption.includes(index)?true:false}
																	
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<span style={{margin:'0.3rem 0.5rem 0.3rem 0.3rem'}} >{opt}</span>
									{IMAGE_SET[index]}
								</li>
					  })
					}
						
				</ul>
			)
		}
		
		//image
		if(questionDisplay.answerType === 'image'){
			//if only option can be correcy
			let IMAGE_SET = questionDisplay.imageAnswers.map((name,index) => {
								
					return  <li>
									<Checkbox
										checked={questionDisplay.correctOption.includes(index)?true:false}
									
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<img src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{width:'140px',height:'140px'}} alt='Anwers' />
								</li>
			});
			
			//onClick={e => handleSelect(e) }
			return (
				<ul key={questionDisplay._id}  style={{display:'flex',flexDirection:'column',listStyle:'none'}} >
					{
						IMAGE_SET
					}
				</ul>
			)
		}
		//over
	} 
	
	function ALL(){
		
		return allQuestions.map((q,index) => {
			return <button data-id={q}  >{index+1}</button>
		})
	
	}


	function handleButtonClick(e){
			if(e.target.dataset.id){
				let id = e.target.dataset.id;
				//let corr = questionDisplay.correctOption;
		
		/* 		console.log(corr);
				console.log(typeof corr);
				let obj = {}
				corr[id]['correctOption'].forEach((el) => {
					obj[el] = true;
				})
				setCorrectOption(obj); */ 
				setSelectedQuestion(id);
				setEditMode(false);				
			}
	}
	
	function TAGS(){
		return(
			questionDisplay.topic.map(tag => {
				return `#${tag} `
			})
		)
	}
	
	function handleEdit(){
		setEditMode(true);
	}
	
	function handleDelete(){
		if(!questionDisplay) return;
		let userInfo = JSON.parse(localStorage.getItem('user'));				 
		fetch(`https://${window.location.hostname}:5005/mentor/question/delete/${questionDisplay._id}`)
		  .then(res => {
			  if(res.ok){
				  alert('Deleted');
				 let newQuestions = allQuestions.filter((ques) => ques != questionDisplay._id);
				  console.log('new questions',newQuestions);
				  localStorage.setItem(`${detail}.admin_questionList`,JSON.stringify(newQuestions));
				  setQuestions(newQuestions);
			  }else{
				  alert('Response not OK');
			  }
		  })
		  .catch(err => {
			  alert('ERROR');
		  })
		
	}
	
	function handleRemove(){
		if(!questionDisplay) return;
		let userInfo = JSON.parse(localStorage.getItem('user'));				 
		fetch(`https://${window.location.hostname}:5005/mentor/question/remove/${questionDisplay._id}/${detail}`)
		  .then(res => {
			  if(res.ok){
				  alert('Removed');
				   let newQuestions = allQuestions.filter((ques) => ques != questionDisplay._id);
				  console.log('new questions',newQuestions);
				  localStorage.setItem(`${detail}.admin_questionList`,JSON.stringify(newQuestions));
				  setQuestions(newQuestions);
			  }else{
				  alert('Response not OK');
			  }
		  })
		  .catch(err => {
			  alert('ERROR');
		  })
	}
	
	return(
	    <>
			<Button style={{marginBottom:'1rem'}} onClick={() => setStage(0)} >Back</Button>
		    <Col md={9} onClick={(e) => handleButtonClick(e)} >
				<p>{ALL()}</p>		
		    </Col>
			
			{(questionDisplay && !editMode && !addMode)?<Row>
				<Col>
					<p>{TAGS()}</p>
					<p>{QUESTION()}</p>
					<p>{OPTIONS()}</p>
				</Col>
				<Col style={{gridColumn:'1fr',display:'grid',gridGap:'1rem'}} >
					<Button onClick={handleEdit} >EDIT</Button>
					<Button variant="danger" onClick={handleDelete} >Delete Question</Button>
					<Button variant="danger" onClick={handleRemove} >Remove From This Test</Button>
					<Button variant="danger" onClick={() => setAddMode(true)} >Add New Question</Button>
				</Col>
			</Row>:null}
			
			{(questionDisplay && editMode)?<Row>
				<Col>
					<p>{QUESTION()}</p>
					<p>{OPTIONS()}</p>
				</Col>
				<Col>
					<EditQuestion  setLastEdited={setLastEdited} questionDisplay={questionDisplay} setEditMode={setEditMode}  />
				</Col>
			</Row>:null}
			
			{(questionDisplay && addMode)?<Row>
				<Col>
					<p>{QUESTION()}</p>
					<p>{OPTIONS()}</p>
				</Col>
				<Col>
					<AddTestQuestion setQuestions={setQuestions}  setLastEdited={setLastEdited}  detail={detail}  setAddMode={setAddMode}  />
				</Col>
			</Row>:null}
			
		</>
		 )
}
