import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Checkbox } from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

//need to handle error in fetch what if question is not there
//needs change in schema for no. of possible answers of question
export default function DisplayQuestion({ sectionID,selectedQuestion, PreviousQuestion, NextQuestion, handleSaveNext, MarkReview }) {

	//it is the index of option not its value since anwersOptions is an array
	//const [selectedOption,setSelectedOption] = useState([]);
	const [correctOption, setCorrectOption] = useState({});

	const [questionDisplay, setQuestionDisplay] = useState('');


	useEffect(() => {
		//alert(sectionID);
		let oldSelectedOption = '';
		let answers = JSON.parse(localStorage.getItem(`${sectionID}_TEST_ANSWERS`));
		if (answers[selectedQuestion.id] && answers[selectedQuestion.id]['selectedIndex']) {
			oldSelectedOption = (answers[selectedQuestion.id]['selectedIndex']);
			//alert(oldSelectedOption);
		}
		fetch(`https://${window.location.hostname}:5005/mentee/mocktest/question/${selectedQuestion.id}`)
			.then(res => {
				if (res.ok) {
					res.json().then(data => {
						if (data && data.question) {
							console.log(data.question._id);
							// setSelectedOption(oldSelectedOption);
							setCorrectOption(oldSelectedOption)
							setQuestionDisplay(data.question);
						}
					})
				} else {
					//alert('Response not OK');
				}
			})
			.catch(err => {
				alert('ERROR');
			})
	}, [selectedQuestion,sectionID])

	/* function handleSelect(e){
		if(e.target.dataset.id){
			setSelectedOption(e.target.dataset.id);
		}
	} */

	function showImage(src){
		let z = document.createElement('a');
		z.href = src;
		z.target = 'blank';
		z.click();
	} 

	const QUESTION = () => {
		let IMAGE_SET = [];
		switch (questionDisplay.questionType) {
			case 'text': 
					if(!questionDisplay.textQuestion.includes('{"blocks"')){
						return <p style={{margin:'0.5rem 0.5rem 0.5rem 0.5rem'}} >
											{questionDisplay.textQuestion}
									</p>
					}else{
					
						return <MUIRichTextEditor 
											label="Start .."
											value={questionDisplay.textQuestion}
											controls={[]}
											customControls={[
													{
														name: "superscript",
														icon:<ArrowUpwardIcon />,
														type:'inline',
														inlineStyle:{
															fontSize:'x-small',
															verticalAlign:'super'
														}
													},
													{
														name: "subscript",
														icon: <ArrowDownwardIcon />,
														type:'inline',
														inlineStyle:{
															fontSize:'x-small',
															verticalAlign:'sub'
														}
													}
													
												]}
											/>
							
									
					}
				
			case 'image':
				IMAGE_SET = questionDisplay.imageQuestions.map((name) => {				
					return <img onClick={e => showImage(e.target.src)}  src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{height: '170px' }} alt='QUESTION' />
				});
				console.log(IMAGE_SET);
				return <div>{IMAGE_SET.map(el => el)}</div>
			case 'both':
				IMAGE_SET = questionDisplay.imageQuestions.map((name) => {
					
					return <img onClick={e => showImage(e.target.src)}  src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{ width: '170px', height: '170px' }} alt='QUESTION' />
				});
				return (<div>
					{!questionDisplay.textQuestion.includes('{"blocks"')?<p style={{margin:'0.5rem 0.5rem 0.5rem 0.5rem'}} >{questionDisplay.textQuestion}
									</p>:(
									 <MUIRichTextEditor 
										label="Start .."
										name='question'
										controls={[]}
										value={questionDisplay.textQuestion}
										
										
										/>)
								}
					{IMAGE_SET}
				</div>)
			default: return <p>Some ERROR</p>
		}
	}

	const OPTIONS = () => {
		//text
		if (questionDisplay.answerType === 'text') {
			//if only option can be correcy
			//onClick={e => handleSelect(e)
			return (
				<ul key={questionDisplay._id} style={{ display: 'flex', flexDirection: 'column', listStyle: 'none' }} >
					{
						questionDisplay.textAnswers.map((opt, index) => {
							return <li >
								{/* <input  type='checkbox' defaultChecked={(parseInt(selectedOption[index]) === parseInt(index))?true:false}  data-id={index} /> */}

								<Checkbox
									checked={correctOption[index]}
									onChange={() => {
										let oldCorrect = correctOption;
										if (!oldCorrect) {
											oldCorrect = {};
											oldCorrect[index] = !oldCorrect[index];
										} else {
											oldCorrect[index] = !oldCorrect[index];
										}
										console.log(oldCorrect);
										console.log(typeof oldCorrect);

										setCorrectOption({ ...oldCorrect });
									}}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
								<span style={{ margin: '0.3rem 0.5rem 0.3rem 0.3rem' }} >{opt}</span>
							</li>
						})
					}

				</ul>
			)
		}

		//both
		if (questionDisplay.answerType === 'both') {
			//if only option can be correcy
			let IMAGE_SET = questionDisplay.imageAnswers.map((name) => {			
				return <img  onClick={e => showImage(e.target.src)} src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{ height: '170px' }} alt='Anwers' />
			});
			//onClick={e => handleSelect(e) }
			return (
				<ul key={questionDisplay._id} style={{ display: 'flex', flexDirection: 'column', listStyle: 'none' }} >
					{
						questionDisplay.textAnswers.map((opt, index) => {
							return <li >
								{/* <input  type='checkbox' defaultChecked={(parseInt(selectedOption[index]) === parseInt(index))?true:false}  data-id={index} /> */}
								<Checkbox
									checked={correctOption[index]}
									onChange={() => {
										let oldCorrect = correctOption;
										if (!oldCorrect) {
											oldCorrect = {};
											oldCorrect[index] = !oldCorrect[index];
										} else {
											oldCorrect[index] = !oldCorrect[index];
										}
										console.log(oldCorrect);
										console.log(typeof oldCorrect);

										setCorrectOption({ ...oldCorrect });
									}}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
								<span style={{ margin: '0.3rem 0.5rem 0.3rem 0.3rem' }} >{opt}</span>
								{IMAGE_SET[index]}
							</li>
						})
					}

				</ul>
			)
		}

		//image
		if (questionDisplay.answerType === 'image') {
			//if only option can be correcy
			let IMAGE_SET = questionDisplay.imageAnswers.map((name, index) => {
			
				return <li>
					<Checkbox
						checked={correctOption[index]}
						onChange={() => {
							let oldCorrect = correctOption;
							if (!oldCorrect) {
								oldCorrect = {};
								oldCorrect[index] = !oldCorrect[index];
							} else {
								oldCorrect[index] = !oldCorrect[index];
							}
							console.log(oldCorrect);
							console.log(typeof oldCorrect);

							setCorrectOption({ ...oldCorrect });
						}}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
					<img onClick={e => showImage(e.target.src)}  src={`https://${window.location.hostname}:5005/mentor/mocktest/IMAGES/${name}`} style={{ width: '170px', height: '170px' }} alt='Anwers' />
				</li>
			});

			//onClick={e => handleSelect(e) }
			return (
				<ul key={questionDisplay._id} style={{ display: 'flex', flexDirection: 'column', listStyle: 'none' }} >
					{
						IMAGE_SET
					}
				</ul>
			)
		}
		//over
	}


	

	return (
		<Col md={9} >
		<Card style={{border:'2px solid yellow',marginTop:'0.2rem'}} >			
			<Card.Body style={{ fontFamily: 'Roboto, sans-serif',fontWeight: '500',fontSize: '1rem'}}>
				<p>Question : </p>
				<p>{QUESTION()}</p>
				<p>Options : </p>
				{OPTIONS()}
				<div style={{ display: 'flex', justifyContent: 'space-between',flexWrap:'wrap' }} >
					<div>
						<Button style={{margin:'10px'}} onClick={() => PreviousQuestion()} >Previous</Button>
						<Button  style={{margin:'10px'}} onClick={() => NextQuestion()} >Next</Button></div>  
					 <div>
						<Button style={{ margin:'10px' }} onClick={() => handleSaveNext(correctOption)} >Save & Next</Button>
						<Button style={{ margin:'10px' }} onClick={() => MarkReview(correctOption)} >Mark Review</Button>
					</div>
					<div><i><b>Note: </b>Click on picture to see it on full screen if it doesn't appear properly</i></div> 

				</div>
			</Card.Body  >
		</Card>
		</Col>
	)
}