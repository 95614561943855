// import { Card } from "@material-ui/core";
import Axios from "axios";
import React,{useEffect, useState} from "react";
import {Modal,Button,Card,Badge} from "react-bootstrap"
const PaymentHistory = ({user_id}) =>{
 useEffect(()=>{
  //  console.log(user_id)
    Axios.get(`https://${window.location.hostname}:5005/getOrderDetails/${user_id}`)
    .then((resp)=>{
      SetPaymentData(resp.data)
    })
 },[])
 const [paymentData,SetPaymentData] = useState([])
//  console.log(paymentData)
  return(

        <div>
           {paymentData.map((payment,index)=>(
           <Card style={{marginBottom:"20px"}} key={index}>
             <Card.Body>

             <Badge variant="danger">Price <span >{payment.amount/100}</span></Badge>
             <h4>Payment Details</h4>
             <p>Paid on <Badge variant ="success">{new Date(payment.updatedAt).toString()}</Badge></p>

             <p>Order Id :<Badge variant="info">{payment.razorpay_order_id}</Badge></p>
             <p>Payment Id : <Badge variant="info">{payment.razorpay_payment_id}</Badge></p>
             </Card.Body>
           </Card>
           ))}
        </div>
  )
}
export default PaymentHistory 