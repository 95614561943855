import React from 'react';
import { Card, Button,Badge} from 'react-bootstrap';
import Avatar from './default-user-avatar.svg';
import {Model} from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';


export default function MyTable({obj,openAsssignMentorModal = () => {}}){
	let dt = new Date(obj.applicationDate);
	
	return(
			  <tbody>
				<tr  className={(obj.status === 'approved')?'bg-warning':'bg-light'}>
				  <td>{obj.mobile || '----'}</td>
				  <td ><Badge variant={(obj.status === 'approved')?'success':'danger'} >{obj.status.toUpperCase()}</Badge></td>
				  <td>{obj.category.toUpperCase()}</td>
				  <td>{obj.menteeName || '----'}</td>
				  <td>{obj.mentorName || '----'}</td>
				  <td>{new Date(obj.selectedDate).toDateString() || '----'}</td>
				  <td  className='cutTheText' onClick={(e) => e.target.classList.toggle('showTheText') } >
							{obj.query}
				  </td>
				  <td  onClick={(e) => e.target.classList.toggle('showTheText') } >
					<div   style={{display:'flex',justifyContent:'space-between'}}>
							{obj?.assignedMentorId && obj?.assignedMentorName ? <p className='cutTheText' onClick={(e) => e.target.classList.toggle('showTheText') } >{obj.assignedMentorName}</p>:null}
							{obj.status !== 'approved' ? <div className='material-icons color-primary' onClick={() => openAsssignMentorModal(obj)} ><EditIcon /></div>:null}
					</div>
				  </td>
				</tr>
			  </tbody>
	)
}