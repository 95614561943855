import React, { useState } from "react";
import PureCounter from "@srexi/purecounterjs";

function StatsSection() {
  const [countList] = useState([
    { end: 300, label: "Mentors" , addStar: false},
    { end: 3000, label: "Happy Students" ,addStar: false},
    { end: 4.8, label: "Mentor Ratings", suffix: "/5" , addStar: true},
  ]);

  return (
    <div className="New-stats-container">
      <div className="small-stats-container">
        {countList.map((stat, index) => (
          <div className="stats-container small-stats" key={index + 1}>
            <div className="stats-body">
              <div className="title">
                <span
                  className="purecounter"
                  data-purecounter-start="0"
                  data-purecounter-end={stat.end}
                  data-purecounter-duration="2"
                  data-purecounter-decimals={stat.suffix ? 1 : 0}
                >
                  {stat.end}
                </span>
                {stat.suffix || "+"}
                {
                  stat?.addStar ? (
                    "⭐"
                  ):null
                }
               
              </div>
              <p className="sub-title">{stat.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatsSection;
