import React from 'react';
import './App.css'; 

import img1 from'./assets/google.jpg';
import img2 from'./assets/Amazon.jpg';
import img3 from'./assets/facebook.jpg';
import img4 from'./assets/airbnb.jpg'; 
import img5 from'./assets/ccc.jpeg'; 
 

const App = () => {
  return (
    <div className="connect-collaboratep-container"> 
    {/*  <img src={img5} class=" mt-4" 
       height="280px" 
       width="1300px"
	/> */}
	<div className='connect-collaborate'>
		<p>CONNECT</p>
		<p className="collab" >COLLABORATE</p>
		<p className="create" >CREATE</p>
	</div>	 
    <div className="row mb-4 ml-5 mr-5">
    <div className="col-sm  mr-3">
    <div className='collab-card'>
  <img src={img1} className="card-img-top mt-3" 
    height="120px" 
  />
  <div class="card-body">
    
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
</div>
    </div>
    <div className="col-sm mr-3">
    <div className='collab-card' >
  <img src={img2} class="card-img-top mt-3"  
   height="120px" 
   />
  <div class="card-body">
    
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
</div>
    </div> 
    <div className="col-sm mr-3">
    <div className='collab-card' >
  <img src={img3} class="card-img-top mt-3" 
     height="120px"
  />
  <div class="card-body">
    
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
</div>
    </div>
    <div className="col-sm">
    <div className='collab-card' >
  <img src={img4} class="card-img-top mt-3" alt="..." 
     height="120px"
  />
  <div class="card-body">
   
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
  </div>
    </div>
   </div> 
   
   <div className="partner-container" >
		<button className="btn btn-warning partner-btn"  ><a href="#" >Partner With Us</a></button>
  </div>

    </div>
  );
}

export default App;
