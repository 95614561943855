import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';


const STYLES = ['btn--primary', 'btn--outline','btn--login1', 'btn--join1' ];
const SIZES = ['btn--medium', 'btn--large','btn--login', 'btn--join'];

export const Button = ({ children, type, onClick, buttonStyle,isActive, buttonSize,goto }) => {
    
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize=SIZES.includes(buttonSize)? buttonSize : SIZES[0];
	

    return(
        <Link className='btn-mobile' to= {goto}>
            <button className={`btn ${checkButtonStyle} ${checkButtonSize} ${isActive?'btns-active':''}`} onClick={onClick} type={type}>
                {children}
            </button>
        </Link>
    )
}