import React, {Component} from 'react'
import Axios from 'axios'

import EachQuestion from "./EachQuestion"

import Pagination from '@material-ui/lab/Pagination';

class VotedAns extends Component{
    constructor(props){
        super(props)
        this.state = {
            votedQuestion : [], 
            shownQuestion : [], 
			limit:7,
			currentTab:1,
			prev:0,
            isDataReturned: false
        }
		
		this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount(){
        Axios.get(`https://${window.location.hostname}:5005/quora/votedquestion/`).then(votedQues => {
            console.log(votedQues);
            this.setState({
                votedQuestion: votedQues.data,
                isDataReturned: true,
				currentTab:1,
				prev:0,
				shownQuestion:votedQues.data.slice(0,this.state.limit)
            })
        }).catch(error => {
            console.log("Axios error")
            console.log(error)
        })
    }
	
	 handlePageChange(e,value){
		e.preventDefault();
		this.setState({
			currentTab:value,
			prev:(value - 1) * (this.state.limit),
			shownQuestion:this.state.votedQuestion.slice((value - 1) * (this.state.limit),(value - 1) * (this.state.limit) + this.state.limit)
		})
	}
	
    render(){
        return(
            this.state.isDataReturned ?             
                <div>
					<div className="mt-2 mb-2"  >
							<Pagination count={Math.round(this.state.votedQuestion.length/this.state.limit)} page={this.state.currentTab} onChange={this.handlePageChange} color="primary" />
					</div>
				
                    {this.state.shownQuestion.map((data, index) => {
                        return(
                            <EachQuestion key={new Date()} question={{"eachQuest": data}}/>                            
                        )
                    })}
                    
                </div>
            : <></>
        )
    }
}

export default VotedAns;