import React from 'react'
import './App.css'
import { Button } from './Button'
import './Updated.css'
import { Link } from 'react-router-dom';

function Updated() {
    return ( 
        <div>
            < div className='hero-main-container'>
                <div className="herd-container"> 
                    <h3>Keep Yourself</h3>
                    <h2>Updated</h2>
                </div>

                <div className='her-btns'>
                    <div className='btn1'>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/qna">Q and A </Button>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/pricing" >Plans</Button>
                    </div>
                    <div className='btn2'>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/blogs">Blogs</Button>
                    </div>
                    <div className='btn1'>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/mentee/mocktest" >Mock Tests</Button>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/webinar">Webinar</Button>
                    </div>
                    <div className='btn2'>
                        <Link to="/qna">
                            <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' goto="/team">Team</Button></Link>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Updated;