import React from 'react';

export const  HomeHeroImage = ({ width = 600, height = 600 }) => {

    return (
        <svg width={width} height={height} viewBox={'0 0 2303 1995'} fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3192_97)">
<path d="M962.513 1587.69C962.649 1604.53 969.461 1620.63 981.454 1632.46C993.448 1644.28 1009.64 1650.86 1026.48 1650.75L2116.49 1642.94C2133.33 1642.81 2149.43 1636 2161.25 1624C2173.06 1612.01 2179.64 1595.81 2179.52 1578.97C2179.4 1562.13 2172.59 1546.02 2160.6 1534.2C2148.61 1522.37 2132.42 1515.79 2115.58 1515.9L1025.57 1523.71C1008.73 1523.85 992.634 1530.66 980.811 1542.66C968.988 1554.65 962.407 1570.85 962.513 1587.69Z" fill="#F2F2F2"/>
<path d="M1093.26 1572.5C1093.26 1571.43 1093.68 1570.4 1094.44 1569.65C1095.19 1568.89 1096.21 1568.46 1097.28 1568.45L1378.82 1567.45C1379.34 1567.45 1379.87 1567.55 1380.36 1567.75C1380.85 1567.95 1381.29 1568.24 1381.67 1568.62C1382.05 1568.99 1382.34 1569.43 1382.55 1569.92C1382.75 1570.41 1382.86 1570.93 1382.86 1571.46C1382.86 1571.99 1382.76 1572.52 1382.56 1573.01C1382.36 1573.5 1382.06 1573.94 1381.69 1574.32C1381.32 1574.69 1380.87 1574.99 1380.39 1575.2C1379.9 1575.4 1379.37 1575.51 1378.84 1575.51L1097.31 1576.52C1096.24 1576.52 1095.21 1576.1 1094.46 1575.34C1093.7 1574.59 1093.27 1573.57 1093.26 1572.5Z" fill="#CCCCCC"/>
<path d="M1093.59 1609.45C1093.59 1608.38 1094.01 1607.36 1094.76 1606.6C1095.51 1605.84 1096.54 1605.41 1097.6 1605.41L1822.48 1601.11C1823 1601.1 1823.53 1601.21 1824.02 1601.41C1824.51 1601.61 1824.96 1601.9 1825.33 1602.27C1825.71 1602.64 1826.01 1603.09 1826.21 1603.57C1826.42 1604.06 1826.53 1604.59 1826.53 1605.12C1826.53 1605.64 1826.43 1606.17 1826.23 1606.66C1826.03 1607.15 1825.74 1607.6 1825.36 1607.97C1824.99 1608.35 1824.55 1608.65 1824.06 1608.85C1823.57 1609.06 1823.05 1609.17 1822.52 1609.17H1822.5L1097.63 1613.47C1096.56 1613.47 1095.54 1613.05 1094.78 1612.3C1094.02 1611.55 1093.59 1610.52 1093.59 1609.45Z" fill="#CCCCCC"/>
<path d="M962.513 1345.9C962.649 1362.74 969.461 1378.84 981.454 1390.66C993.448 1402.49 1009.64 1409.07 1026.48 1408.96L2116.49 1401.15C2133.33 1401.02 2149.43 1394.21 2161.25 1382.21C2173.06 1370.22 2179.64 1354.02 2179.52 1337.18C2179.4 1320.33 2172.59 1304.23 2160.6 1292.4C2148.61 1280.58 2132.42 1274 2115.58 1274.11L1025.57 1281.92C1008.73 1282.06 992.634 1288.87 980.811 1300.87C968.988 1312.86 962.407 1329.06 962.513 1345.9Z" fill="#F2F2F2"/>
<path d="M1081.3 1367.66C1081.29 1366.59 1081.71 1365.57 1082.47 1364.81C1083.22 1364.05 1084.24 1363.62 1085.31 1363.62L1810.18 1359.32C1810.71 1359.31 1811.24 1359.42 1811.73 1359.62C1812.22 1359.82 1812.66 1360.11 1813.04 1360.49C1813.41 1360.86 1813.71 1361.3 1813.91 1361.79C1814.12 1362.28 1814.23 1362.8 1814.23 1363.33C1814.23 1363.86 1814.13 1364.39 1813.93 1364.88C1813.72 1365.37 1813.43 1365.81 1813.06 1366.19C1812.68 1366.56 1812.24 1366.86 1811.75 1367.07C1811.26 1367.27 1810.74 1367.38 1810.21 1367.38L1085.34 1371.68C1084.27 1371.68 1083.25 1371.26 1082.49 1370.51C1081.73 1369.75 1081.3 1368.73 1081.3 1367.66Z" fill="#CCCCCC"/>
<path d="M1081.3 1326.68C1081.29 1325.61 1081.71 1324.59 1082.47 1323.83C1083.22 1323.07 1084.24 1322.64 1085.31 1322.63L1810.18 1318.33C1810.71 1318.33 1811.24 1318.43 1811.73 1318.64C1812.22 1318.84 1812.66 1319.13 1813.04 1319.5C1813.41 1319.88 1813.71 1320.32 1813.91 1320.81C1814.12 1321.3 1814.23 1321.82 1814.23 1322.35C1814.23 1322.88 1814.13 1323.4 1813.93 1323.89C1813.72 1324.38 1813.43 1324.83 1813.06 1325.21C1812.68 1325.58 1812.24 1325.88 1811.75 1326.08C1811.26 1326.29 1810.74 1326.39 1810.21 1326.4L1085.34 1330.7C1084.27 1330.7 1083.25 1330.28 1082.49 1329.52C1081.73 1328.77 1081.3 1327.75 1081.3 1326.68Z" fill="#CCCCCC"/>
<path d="M1281.52 1867.96L191.52 1860.15C174.68 1860.03 158.485 1866.61 146.495 1878.44C134.506 1890.27 127.702 1906.37 127.582 1923.21C127.461 1940.05 134.033 1956.25 145.852 1968.25C157.671 1980.24 173.77 1987.06 190.61 1987.19L1280.61 1995C1297.45 1995.11 1313.65 1988.53 1325.64 1976.7C1337.63 1964.88 1344.43 1948.77 1344.55 1931.93C1344.67 1915.09 1338.1 1898.89 1326.28 1886.9C1314.46 1874.9 1298.36 1868.09 1281.52 1867.96Z" fill="#F2F2F2"/>
<path d="M533.642 1920.76L252.106 1919.75C251.576 1919.75 251.053 1919.65 250.564 1919.44C250.076 1919.24 249.633 1918.94 249.26 1918.56C248.887 1918.19 248.591 1917.74 248.391 1917.25C248.19 1916.76 248.087 1916.24 248.089 1915.71C248.091 1915.18 248.197 1914.66 248.402 1914.17C248.606 1913.68 248.904 1913.24 249.28 1912.86C249.656 1912.49 250.101 1912.19 250.591 1911.99C251.081 1911.79 251.605 1911.69 252.134 1911.69L533.67 1912.7C534.201 1912.7 534.726 1912.8 535.215 1913.01C535.705 1913.21 536.15 1913.51 536.524 1913.89C536.898 1914.26 537.194 1914.71 537.396 1915.2C537.597 1915.69 537.7 1916.21 537.698 1916.74C537.696 1917.27 537.59 1917.8 537.385 1918.29C537.18 1918.78 536.88 1919.22 536.504 1919.59C536.127 1919.97 535.68 1920.26 535.189 1920.46C534.698 1920.66 534.172 1920.76 533.642 1920.76Z" fill="#CCCCCC"/>
<path d="M979.983 1957.71L255.113 1953.41C254.584 1953.41 254.06 1953.31 253.572 1953.1C253.083 1952.9 252.64 1952.6 252.267 1952.22C251.894 1951.85 251.599 1951.4 251.398 1950.91C251.197 1950.42 251.095 1949.9 251.097 1949.37C251.099 1948.84 251.205 1948.32 251.409 1947.83C251.613 1947.34 251.912 1946.9 252.287 1946.52C252.663 1946.15 253.108 1945.85 253.598 1945.65C254.088 1945.45 254.612 1945.35 255.142 1945.35L980.012 1949.65C980.541 1949.65 981.065 1949.76 981.553 1949.96C982.042 1950.17 982.485 1950.47 982.858 1950.84C983.231 1951.22 983.526 1951.66 983.727 1952.15C983.928 1952.64 984.03 1953.17 984.029 1953.7C984.027 1954.23 983.92 1954.75 983.716 1955.24C983.512 1955.73 983.213 1956.17 982.838 1956.54C982.462 1956.92 982.017 1957.21 981.527 1957.41C981.037 1957.61 980.513 1957.72 979.983 1957.71Z" fill="#CCCCCC"/>
<path d="M2274.31 1369.45C2290.16 1369.45 2303 1356.61 2303 1340.76C2303 1324.92 2290.16 1312.08 2274.31 1312.08C2258.47 1312.08 2245.63 1324.92 2245.63 1340.76C2245.63 1356.61 2258.47 1369.45 2274.31 1369.45Z" fill="#F2F2F2"/>
<path d="M2274.31 1611.24C2290.16 1611.24 2303 1598.4 2303 1582.55C2303 1566.71 2290.16 1553.87 2274.31 1553.87C2258.47 1553.87 2245.63 1566.71 2245.63 1582.55C2245.63 1598.4 2258.47 1611.24 2274.31 1611.24Z" fill="#F2F2F2"/>
<path d="M986.469 1764.17L197.852 1882.32C191.425 1883.29 184.871 1882.98 178.564 1881.41C172.257 1879.84 166.321 1877.05 161.094 1873.19C155.868 1869.33 151.453 1864.47 148.103 1858.9C144.753 1853.33 142.533 1847.16 141.571 1840.73C140.608 1834.3 140.921 1827.75 142.492 1821.44C144.063 1815.14 146.862 1809.2 150.727 1803.98C154.593 1798.75 159.45 1794.34 165.021 1791C170.592 1787.65 176.768 1785.43 183.196 1784.48L971.813 1666.33C978.239 1665.36 984.793 1665.67 991.1 1667.24C997.407 1668.81 1003.34 1671.6 1008.57 1675.46C1013.8 1679.33 1018.21 1684.18 1021.56 1689.75C1024.91 1695.32 1027.13 1701.49 1028.09 1707.92C1029.06 1714.35 1028.74 1720.9 1027.17 1727.21C1025.6 1733.51 1022.8 1739.45 1018.94 1744.67C1015.07 1749.9 1010.21 1754.31 1004.64 1757.65C999.072 1761 992.896 1763.22 986.469 1764.17Z" fill="#5A159E"/>
<path d="M437.769 1792.39L234.193 1823.64C233.42 1823.76 232.631 1823.56 232.001 1823.1C231.37 1822.64 230.949 1821.94 230.831 1821.17C230.712 1820.4 230.905 1819.61 231.368 1818.98C231.831 1818.35 232.525 1817.93 233.298 1817.81L436.874 1786.56C437.647 1786.44 438.436 1786.64 439.066 1787.1C439.697 1787.56 440.118 1788.26 440.236 1789.03C440.355 1789.8 440.162 1790.59 439.699 1791.22C439.236 1791.85 438.542 1792.27 437.769 1792.39Z" fill="white"/>
<path d="M764.53 1768.41L240.188 1847.62C239.415 1847.74 238.627 1847.55 237.996 1847.08C237.366 1846.62 236.945 1845.93 236.826 1845.15C236.707 1844.38 236.901 1843.59 237.364 1842.96C237.826 1842.33 238.52 1841.91 239.294 1841.79L763.635 1762.58C764.018 1762.52 764.41 1762.53 764.786 1762.62C765.163 1762.72 765.518 1762.88 765.831 1763.11C766.144 1763.34 766.409 1763.63 766.61 1763.96C766.812 1764.29 766.946 1764.66 767.004 1765.04C767.063 1765.43 767.046 1765.82 766.953 1766.19C766.861 1766.57 766.695 1766.93 766.465 1767.24C766.235 1767.55 765.946 1767.81 765.614 1768.01C765.282 1768.22 764.913 1768.35 764.53 1768.41Z" fill="white"/>
<path d="M803.273 1704.71C800.215 1704.53 797.231 1703.69 794.529 1702.25C791.827 1700.8 789.471 1698.79 787.624 1696.35C785.776 1693.9 784.482 1691.09 783.83 1688.09C783.178 1685.1 783.184 1682 783.847 1679.01L698.132 1617.62L709.283 1589.73L810.555 1663.39C815.506 1664.88 819.738 1668.14 822.45 1672.54C825.161 1676.95 826.163 1682.19 825.265 1687.29C824.368 1692.38 821.633 1696.97 817.58 1700.18C813.526 1703.39 808.436 1705 803.273 1704.71Z" fill="#A0616A"/>
<path d="M572.999 1361.46L521.493 1380.31C519.23 1381.14 517.152 1382.41 515.378 1384.04C513.604 1385.67 512.168 1387.64 511.154 1389.82C510.139 1392.01 509.565 1394.37 509.464 1396.78C509.363 1399.19 509.737 1401.59 510.565 1403.86C510.768 1404.41 510.996 1404.95 511.251 1405.48L581.476 1551.99L750.679 1673.95L776.614 1632.62L648.44 1515.9L572.999 1361.46Z" fill="black"/>
<path d="M551.82 1664.45C493.123 1658.99 435.625 1645.44 376.838 1640.88L400.431 1580.83L521.003 1598.79L551.82 1664.45Z" fill="#A0616A"/>
<path d="M352.676 1678.57L352.167 1683.07L347.97 1719.19L343.053 1719.49L342.784 1764.73C344.33 1769.21 344.321 1774.08 342.76 1778.55C341.199 1783.02 338.176 1786.83 334.18 1789.37C333.517 1789.8 332.826 1790.18 332.112 1790.51C329.122 1767.03 330.023 1743.22 334.78 1720.03C336.689 1711.4 339.502 1703 343.173 1694.97C345.023 1690.99 347.126 1687.13 349.469 1683.43C350.458 1681.78 351.537 1680.16 352.676 1678.57Z" fill="#1B1A2A"/>
<path d="M353.605 1670.77L352.676 1678.54V1678.57L352.167 1683.07C350.848 1683.25 349.918 1683.37 349.468 1683.43C349.359 1683.44 349.249 1683.45 349.139 1683.46L353.605 1670.77Z" fill="#FF823C"/>
<path d="M567.049 1521.89L556.917 1580.83C556.917 1580.83 581.529 1636.3 564.591 1650.12C562.52 1651.97 560.926 1654.3 559.945 1656.91C558.964 1659.51 558.624 1662.31 558.955 1665.08C556.557 1664.87 554.189 1664.66 551.821 1664.45C493.123 1658.99 435.626 1645.44 376.838 1640.88C372.671 1640.55 368.534 1640.25 364.368 1640.04L382.474 1588.54L370.243 1542.76L357.563 1495.27L354.835 1417.08C354.77 1415.6 354.78 1414.12 354.865 1412.64C354.865 1411.86 354.895 1411.11 354.955 1410.34C355.614 1400.46 358.476 1390.86 363.332 1382.24C368.188 1373.62 374.913 1366.19 383.014 1360.51C383.037 1360.51 383.058 1360.49 383.074 1360.48C385.837 1358.54 388.743 1356.82 391.768 1355.32C395.017 1353.71 398.396 1352.38 401.87 1351.33L405.348 1350.29L424.174 1319.83L445.998 1317.55L454.092 1316.71L506.823 1311.22L518.185 1325.97L520.254 1328.67L528.258 1339.04L553.199 1352.32C558.651 1355.21 563.634 1358.91 567.979 1363.3C578.177 1373.49 584.505 1386.92 585.875 1401.28C586.206 1404.59 586.266 1407.92 586.055 1411.23L567.049 1521.89Z" fill="black"/>
<path d="M310.371 1256.45C309.829 1279.75 318.536 1302.31 331.045 1322.12C335.224 1310.62 341.075 1299.79 348.411 1289.99C344.899 1311.7 348.64 1333.97 359.057 1353.34C359.424 1353.6 359.804 1353.85 360.196 1354.07C365.197 1357.32 370.872 1359.38 376.789 1360.11C382.706 1360.83 388.711 1360.2 394.348 1358.26C383.213 1345.66 376.535 1329.74 375.345 1312.96C374.156 1296.18 378.52 1279.48 387.765 1265.43C393.174 1257.61 400.17 1250.7 403.521 1241.8C408.811 1227.6 403.726 1211.97 400.518 1197.17C397.518 1183.39 396.993 1167 406.235 1156.9C405.248 1155.49 403.903 1154.37 402.339 1153.65C400.775 1152.93 399.048 1152.63 397.334 1152.8C394.548 1153.51 391.967 1154.86 389.798 1156.75C387.629 1158.63 385.933 1161 384.846 1163.66C382.715 1168.99 380.011 1174.08 376.781 1178.82C369.963 1187.07 358.353 1189.02 348.568 1193.37C324.873 1203.92 310.939 1230.52 310.371 1256.45Z" fill="#1B1A2A"/>
<path d="M509.828 1243.12C508.474 1238.38 517.807 1267.36 513.304 1267.88C492.791 1270.23 464.633 1282.48 450.347 1288.2C449.704 1288.12 449.06 1288.05 448.436 1287.96C446.903 1287.79 445.408 1287.54 443.932 1287.26C410.666 1280.97 397.874 1229.87 397.874 1229.4C397.874 1227.1 403.387 1164 412.073 1163.07C414.354 1162.82 416.647 1162.7 418.941 1162.69H440.494C444.954 1161.73 449.475 1161.08 454.024 1160.73H454.062C497.452 1157.43 531.664 1183.88 528.466 1216.06C528.447 1216.09 528.409 1216.13 528.39 1216.17C527.463 1217.55 526.649 1218.86 525.93 1220.11C525.23 1221.29 524.643 1222.41 524.133 1223.47C520.178 1231.69 530.344 1242.03 523.603 1243.96C514.637 1246.53 547.182 1330.91 511.696 1330.91C528.957 1335.82 513.564 1256.2 509.828 1243.12Z" fill="#1B1A2A"/>
<path d="M471.095 1292.63C499.277 1292.63 522.124 1269.79 522.124 1241.6C522.124 1213.42 499.277 1190.57 471.095 1190.57C442.912 1190.57 420.065 1213.42 420.065 1241.6C420.065 1269.79 442.912 1292.63 471.095 1292.63Z" fill="#A0616A"/>
<path d="M528.466 1218.84C527.614 1219.29 526.782 1219.7 525.93 1220.11C525.041 1220.54 524.152 1220.97 523.262 1221.36C506.175 1229.11 492.267 1229.97 483.827 1218.84C483.601 1212.98 482.286 1207.22 479.948 1201.84C480.361 1207.85 478.778 1213.83 475.444 1218.84H458.452C440.948 1230.46 431.96 1231.67 444.108 1269.81C447.496 1280.44 460.964 1342.29 455.665 1351.46C454.133 1351.29 437.826 1283.55 436.35 1283.27C403.084 1276.98 381.947 1222.17 384.653 1219.07C384.64 1210.25 386.667 1201.55 390.576 1193.64C395.521 1183.63 403.23 1175.24 412.791 1169.48C418.49 1166.71 424.432 1164.48 430.541 1162.81C430.692 1162.77 430.825 1162.73 430.976 1162.7C432.849 1162.19 434.761 1161.72 436.672 1161.33C440.62 1160.5 444.613 1159.89 448.631 1159.52C448.915 1159.5 449.199 1159.48 449.464 1159.48C451.068 1159.49 452.642 1159.92 454.024 1160.73C454.043 1160.73 454.043 1160.75 454.062 1160.75C455.049 1161.33 455.911 1162.1 456.597 1163.01C457.806 1164.55 458.459 1166.45 458.452 1168.41H477.374C478.075 1168.41 478.775 1168.43 479.475 1168.47C492.104 1168.9 504.118 1174.03 513.165 1182.85C522.212 1191.67 527.641 1203.56 528.39 1216.17C528.447 1217.07 528.466 1217.95 528.466 1218.84Z" fill="#1B1A2A"/>
<path d="M416.893 1202.53C435.46 1202.53 450.512 1187.47 450.512 1168.91C450.512 1150.34 435.46 1135.29 416.893 1135.29C398.326 1135.29 383.275 1150.34 383.275 1168.91C383.275 1187.47 398.326 1202.53 416.893 1202.53Z" fill="#1B1A2A"/>
<path d="M334.18 1789.37C333.517 1789.8 332.826 1790.18 332.111 1790.51C329.625 1791.74 326.921 1792.46 324.155 1792.64C321.388 1792.82 318.614 1792.46 315.989 1791.57C313.364 1790.67 310.941 1789.27 308.857 1787.44C306.774 1785.62 305.07 1783.39 303.845 1780.91C302.62 1778.42 301.897 1775.72 301.716 1772.95C301.536 1770.18 301.902 1767.41 302.794 1764.78C303.686 1762.16 305.086 1759.74 306.915 1757.65C308.743 1755.57 310.964 1753.87 313.451 1752.64C313.81 1752.46 314.175 1752.3 314.544 1752.14L314.484 1721.29L314.424 1684.98L314.364 1646.7L343.503 1639.5L343.173 1694.97L343.053 1719.49L342.783 1764.73C344.329 1769.21 344.321 1774.08 342.76 1778.55C341.199 1783.02 338.176 1786.83 334.18 1789.37Z" fill="#A0616A"/>
<path d="M449.355 1395.62L446.328 1401.28L370.243 1542.76L367.965 1546.98L357.203 1639.71L353.605 1670.77L352.676 1678.54V1678.57L352.166 1683.06L347.969 1719.19L343.053 1719.49L334.779 1720.03L314.484 1721.29L299.285 1722.25L299.315 1697.93L299.585 1513.68L354.864 1412.64L356.213 1410.16L377.558 1371.15C379.902 1366.87 383.849 1363.7 388.53 1362.34C393.212 1360.97 398.245 1361.52 402.522 1363.86C403.028 1364.14 403.52 1364.44 403.998 1364.77L449.355 1395.62Z" fill="black"/>
<path d="M28.6851 1939.09C44.5274 1939.09 57.3701 1926.25 57.3701 1910.41C57.3701 1894.56 44.5274 1881.72 28.6851 1881.72C12.8427 1881.72 0 1894.56 0 1910.41C0 1926.25 12.8427 1939.09 28.6851 1939.09Z" fill="#F2F2F2"/>
<path d="M1995.37 870.657C1995.37 870.657 1984.96 980.774 1979.21 1062.29V1062.35C1978.76 1068.92 1978.34 1075.26 1977.95 1081.39L1974.89 1081.66C1972.79 1081.87 1970.72 1082.11 1968.63 1082.32C1935.11 1085.88 1901.92 1091.79 1868.62 1096.92C1865.41 1097.4 1862.2 1097.88 1859 1098.36C1825.78 1103.78 1792.17 1106.51 1758.51 1106.51H1757.88C1758.15 1102.31 1758.57 1096.83 1759.05 1090.53C1761.87 1054.89 1767.56 993.096 1767.56 993.096L1772.87 957.33L1790.23 840.168L1790.35 839.268L1790.5 838.249L1791.34 838.039L1827.79 828.566L1847.39 823.499L1858.79 807.789L1873.95 786.863L1937.03 794.628L1941.65 811.837L1950.19 843.556L1950.46 844.575L1983.55 863.792L1995.37 870.657Z" fill="#E6E6E6"/>
<path d="M1905.33 780.086C1942.25 780.086 1972.18 750.157 1972.18 713.237C1972.18 676.318 1942.25 646.389 1905.33 646.389C1868.41 646.389 1838.49 676.318 1838.49 713.237C1838.49 750.157 1868.41 780.086 1905.33 780.086Z" fill="#FFB6B6"/>
<path d="M1835.29 672.78C1841.16 677.523 1839.82 700.592 1846.36 696.832C1852.9 693.072 1854.26 686.339 1861.79 686.268C1867.93 686.607 1873.72 689.228 1878.03 693.617C1878.83 694.341 1879.59 695.101 1880.33 695.852C1881.01 695.614 1881.68 695.399 1882.38 695.222C1887.66 693.933 1893.24 694.718 1897.96 697.416C1893.6 697.727 1889.45 699.401 1886.09 702.2C1887.53 703.812 1888.99 705.432 1890.52 706.929C1903.32 719.475 1929.57 706.114 1947.22 703.099C1932.52 719.151 1935.42 728.111 1939.08 749.566L1939.09 750.738C1939.84 745.79 1955.69 746.037 1953.08 752.602C1949.8 759.088 1945.69 765.122 1940.86 770.556C1962.45 755.598 1979.88 734.98 1983.73 710.074C1985.15 704.969 1985.43 699.617 1984.58 694.389C1983.94 691.808 1982.47 689.509 1980.39 687.852C1978.31 686.195 1975.75 685.273 1973.09 685.23C1975.49 677.59 1975.78 669.441 1973.93 661.65C1972.07 653.858 1968.14 646.716 1962.55 640.981C1956.96 635.246 1949.92 631.133 1942.17 629.08C1934.43 627.027 1926.28 627.111 1918.58 629.321C1911.56 631.817 1904.71 634.774 1898.08 638.171C1891.39 641.582 1883.81 642.866 1876.36 641.85C1871.73 640.892 1867.03 638.49 1862.48 639.85C1857.22 641.43 1854.95 646.993 1852.84 652.363C1856.8 653.81 1860.25 656.406 1862.73 659.819C1858.63 658.307 1854.16 658.126 1849.95 659.3C1849.88 659.314 1849.8 659.334 1849.73 659.362C1844.96 667.908 1844.68 670.003 1835.29 672.78Z" fill="#1B1A2A"/>
<path d="M1868.62 1096.92C1865.41 1097.4 1862.2 1097.88 1859 1098.35C1825.77 1103.78 1792.17 1106.51 1758.51 1106.51C1758.75 1101.2 1758.9 1095.87 1759.05 1090.53C1759.26 1083.06 1759.41 1075.57 1759.56 1068.07C1760.25 1034.11 1760.94 1000.15 1761.63 966.202C1761.66 964.583 1761.69 962.934 1761.72 961.315C1762.41 926.988 1763.34 891.522 1777.57 860.252C1780.83 853.13 1784.88 846.401 1789.66 840.196C1789.9 839.866 1790.11 839.566 1790.35 839.266C1790.67 838.847 1791 838.427 1791.33 838.037C1795.16 833.269 1799.41 828.864 1804.05 824.876C1811.2 818.282 1819.6 813.181 1828.76 809.871C1837.91 806.561 1847.63 805.109 1857.35 805.599L1858.78 807.787L1860.7 810.755C1859.44 820.139 1858.16 829.553 1856.96 838.967C1851.77 879.769 1848.05 920.812 1852.22 961.645C1855.76 995.973 1864.84 1029.58 1867.72 1063.94C1867.84 1065.08 1867.93 1066.25 1868.02 1067.39C1868.73 1077.21 1868.93 1087.07 1868.62 1096.92Z" fill="black"/>
<path d="M1974.88 1081.64C1976.36 1075.24 1977.75 1068.81 1979.23 1062.33V1062.29C1984.92 1036.72 1990.63 1011.15 1996.36 985.576C2003.86 952.053 2011.35 917.383 2004.92 883.655C2004.27 880.185 2003.43 876.751 2002.42 873.368C2002.09 872.262 2001.72 871.156 2001.36 870.049C1998.77 862.28 1995.26 854.85 1990.91 847.919C1979.51 829.723 1962.47 815.585 1941.65 811.855C1941.26 811.738 1940.86 811.656 1940.46 811.609L1935.91 815.83C1934.97 824.56 1934.07 833.371 1933.09 842.141C1928.41 883.655 1922.31 925.128 1908.33 964.388C1896.37 997.993 1878.83 1029.43 1867.89 1063.36C1867.85 1063.56 1867.77 1063.77 1867.72 1063.93C1864.11 1075.22 1861.2 1086.71 1859 1098.36C1862.19 1097.87 1865.43 1097.41 1868.63 1096.92C1901.94 1091.8 1935.09 1085.9 1968.61 1082.33L1974.88 1081.64Z" fill="black"/>
<path d="M2040 699.171L2038.08 680.763L2037.63 676.506L2036.94 669.671L2022.13 527.595C2023.36 523.496 2023.19 519.108 2021.65 515.115C2020.12 511.123 2017.3 507.752 2013.65 505.529C2009.42 503.012 2004.38 502.273 1999.61 503.476C1994.84 504.679 1990.75 507.725 1988.23 511.945C1988.14 512.095 1988.08 512.245 1987.99 512.395C1985.47 516.786 1984.76 521.985 1986.02 526.889C1987.27 531.792 1990.39 536.014 1994.7 538.657C1995.59 539.169 1996.53 539.61 1997.49 539.977L1997.37 543.964L1997.01 556.466L1996.74 565.91L1996.17 584.707L1995.96 591.992L1995.27 615.616L1994.61 638.402L1993.8 666.013L1993.17 687.239L2000.82 689.188L2040 699.171Z" fill="#FFB6B6"/>
<path d="M2054.81 729.422C2054.49 726.956 2053.89 724.532 2053.04 722.197C2049.17 715.482 2046.8 724.865 2048.93 706.877C2050.64 692.277 2040.39 694.106 2044.07 690.988C2050.16 685.831 2041.77 683.553 2038.08 680.765C2036.43 679.535 2035.74 678.216 2037.63 676.507C2038.52 675.735 2039.5 675.089 2040.57 674.589C2044.58 672.49 2042.28 670.901 2036.94 669.672C2024.94 667.672 2012.8 666.539 2000.64 666.284C1997.7 666.134 1995.3 666.074 1993.8 666.014C1992.69 665.984 1992.06 665.984 1992.06 665.984C1989.97 667.969 1988.66 670.64 1988.38 673.509C1987.49 679.603 1987.96 685.817 1989.75 691.707C1990.99 695.797 1992.62 699.754 1994.64 703.519C1995.41 704.931 1995.76 706.532 1995.66 708.136V708.166C1995.45 709.114 1995.03 710.003 1994.43 710.769C1993.83 711.536 1993.08 712.162 1992.21 712.603C1988.17 715.302 1990.41 720.968 1993.56 726.304C1996.24 729.88 1998.51 733.741 2000.34 737.817C2000.34 741.504 1975.39 819.872 1975.39 819.872L1984.57 938.204C1991.37 943.45 1999.96 945.828 2008.49 944.829C2015.61 943.632 2022.19 940.284 2027.35 935.236C2034.6 928.139 2040.51 919.777 2044.76 910.562C2068.99 861.904 2059.96 758.143 2054.81 729.422Z" fill="black"/>
<path d="M1599.97 534.275C1604.51 536.493 1608.02 540.398 1609.73 545.16C1611.45 549.922 1611.23 555.165 1609.14 559.772C1608.7 560.702 1608.18 561.594 1607.6 562.439L1693.85 681.855L1661.78 718.028L1580.24 566.042C1576.93 563.336 1574.61 559.608 1573.63 555.442C1572.66 551.275 1573.09 546.903 1574.87 543.009C1576.95 538.554 1580.71 535.107 1585.33 533.428C1589.95 531.748 1595.05 531.973 1599.51 534.053C1599.66 534.125 1599.81 534.199 1599.97 534.275Z" fill="#FFB6B6"/>
<path d="M1841.75 884.416C1841.75 884.416 1830.22 925.661 1776.41 895.358C1722.6 865.055 1664.4 751.895 1663.81 744.142C1663.23 736.388 1670.46 742.832 1658.62 729.153C1648.98 718.028 1658.5 713.781 1653.7 713.266C1641.06 711.909 1663.01 695.878 1647.4 697.717C1631.78 699.556 1682.7 663.363 1682.7 663.363C1682.7 663.363 1691.98 663.586 1699.06 683.367C1703.04 694.477 1699.28 702.078 1708.76 702.035C1718.24 701.992 1714.18 724.501 1716.19 727.475C1718.19 730.45 1782.89 781.35 1782.89 781.35L1841.75 884.416Z" fill="black"/>
<path d="M1770.16 623.587C1942.35 623.587 2081.93 483.992 2081.93 311.793C2081.93 139.595 1942.35 0 1770.16 0C1597.97 0 1458.39 139.595 1458.39 311.793C1458.39 483.992 1597.97 623.587 1770.16 623.587Z" fill="#5A159E"/>
<path d="M1611.74 242.407C1614.98 248.582 1617.46 255.13 1619.12 261.905C1623.34 279.719 1620.38 295.885 1610.65 303.468C1609.68 304.255 1608.63 304.943 1607.52 305.523C1595.35 311.885 1578.57 305.17 1565.12 290.145C1560.5 284.92 1556.53 279.151 1553.31 272.965C1550.09 266.858 1547.63 260.377 1546 253.668C1541.26 233.993 1545.3 216.249 1557.5 209.868C1573.66 201.417 1597.93 216.006 1611.74 242.407Z" fill="white"/>
<path d="M1606.58 272.858C1611.51 282.257 1612.96 293.104 1610.65 303.469C1609.68 304.256 1608.63 304.945 1607.52 305.524C1595.35 311.886 1578.57 305.171 1565.12 290.146C1560.5 284.921 1556.53 279.152 1553.31 272.966C1550.09 266.859 1547.63 260.378 1546 253.669C1556.58 248.209 1568.89 247.163 1580.25 250.759C1591.6 254.355 1601.06 262.3 1606.58 272.858Z" fill="black"/>
<path d="M1732.77 179.108C1746.03 204.456 1744.81 231.498 1730.42 241.108C1729.82 241.516 1729.19 241.888 1728.55 242.224C1712.43 250.656 1688.15 236.067 1674.34 209.666C1671.13 203.571 1668.67 197.102 1667.05 190.405C1662.29 170.695 1666.33 152.95 1678.53 146.569C1694.69 138.118 1718.96 152.707 1732.77 179.108Z" fill="white"/>
<path d="M1726.41 210.184C1731.4 219.682 1732.82 230.653 1730.42 241.108C1729.82 241.516 1729.19 241.888 1728.55 242.224C1712.43 250.656 1688.15 236.067 1674.34 209.666C1671.12 203.571 1668.67 197.102 1667.05 190.405C1677.57 185.367 1689.65 184.614 1700.72 188.304C1711.8 191.993 1721 199.838 1726.41 210.184Z" fill="black"/>
<path d="M1596.45 421.519C1633.16 491.716 1704.37 526.94 1755.51 500.194C1806.66 473.448 1818.35 394.859 1781.65 324.662C1741.32 383.459 1680.25 416.564 1596.45 421.519Z" fill="white"/>
<path opacity="0.2" d="M2046.81 167.765C2017.14 111.035 1970.74 64.808 1913.89 35.3609C1857.05 5.91386 1792.52 -5.32712 1729.07 3.16405C1803.99 13.0234 1872.78 49.7561 1922.65 106.532C1972.52 163.308 2000.08 236.264 2000.19 311.834C2000.31 387.404 1972.98 460.445 1923.29 517.376C1873.6 574.308 1804.93 611.255 1730.04 621.348C1786.62 628.783 1844.16 620.538 1896.38 597.513C1948.6 574.489 1993.5 537.567 2026.18 490.775C2058.86 443.983 2078.06 389.116 2081.7 332.158C2085.34 275.199 2073.27 218.335 2046.81 167.765Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_3192_97">
<rect width="2303" height="1995" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}