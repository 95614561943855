import React from "react";
import { Link } from "react-router-dom";

import brand from "../assets/brand.png";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFAQ: false,
      showMobileLinks: false,
    };
  }

  handleClose = () => {
    this.setState({ openFAQ: false });
  };

  handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/index";
  };

  handleopenFAQ = () => {
    this.setState({ openFAQ: true });
  };

  getRoutes = (user) => {
    return (
      <div
        // className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav">
          <li className="nav-item mx-3">
            <Link className="nav-link  " to="/about-us">
              About us
            </Link>
          </li>
          {/* Resource Corner */}
          <li className="nav-item mx-3">
          <a href="/index#offer" className="nav-link">
            Services
            </a>
          </li>

          {/* Conditional Rendering for Mentor and Mentee */}
          {user ? (
            user.role.toLowerCase() === "mentor" ? (
              <li className="nav-item dropdown mx-1">
                <a
                  className="nav-link   dropdown-toggle"
                  href="#"
                  id="mockTestDropdown"
                  role="button"
                  data-toggle="dropdown"
                >
                  Mock-test
                </a>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item text-info"
                    to="/mentor/createtest"
                  >
                    Create Test
                  </Link>
                  <Link
                    className="dropdown-item text-info"
                    to="/mentor/history/testhistory"
                  >
                    My Mock Tests
                  </Link>
                </div>
              </li>
            ) : (
              <>
                <li className="nav-item dropdown mx-1">
                  <a
                    className="nav-link   dropdown-toggle"
                    href="#"
                    id="plansDropdown"
                    role="button"
                    data-toggle="dropdown"
                  >
                    Plans
                  </a>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item text-info" to="/pricing">
                      JEE/NEET
                    </Link>
                    <Link className="dropdown-item text-info" to="/plans">
                      Engineering College
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a
                    className="nav-link   dropdown-toggle"
                    href="#"
                    id="mockTestDropdown"
                    role="button"
                    data-toggle="dropdown"
                  >
                    Mock-test
                  </a>
                  <div className="dropdown-menu">
                    <Link
                      className="dropdown-item text-info"
                      to="/mentee/mocktest"
                    >
                      All Tests
                    </Link>
                    <Link
                      className="dropdown-item text-info"
                      to="/mentee/mocktest/history"
                    >
                      History
                    </Link>
                  </div>
                </li>
              </>
            )
          ) : null}
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <Link to="/qna" className="dropdown-item">
                QnA
              </Link>
              <Link to="/blogs" className="dropdown-item">
                Blogs
              </Link>
              <Link
                to={user ? "/mock-test" : "/login"}
                className="dropdown-item"
              >
                Mock Test
              </Link>
              <Link to={user ? "/Notes" : "/login"} className="dropdown-item">
                Study Material
              </Link>
              <Link to="/webinar" className="dropdown-item">
                Event
              </Link>
            </div>
          </li>
          <li className="nav-item mx-3">
            <a href="#footer" className="nav-link">
              Contact us
            </a>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    return (
      <>
        {/* FAQ Dialog */}
        <Dialog
          open={this.state.openFAQ}
          TransitionComponent={Transition}
          onClose={this.handleClose}
          aria-labelledby="FAQs"
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle>Frequently Asked Questions:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* FAQs content here */}
              {/* For brevity, I’ve omitted the FAQ content */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Navbar */}
        <div className="navBarContainer">
          <div className="mentomeetLogo">
            <a href="/index">
              <img src={brand} width={30} height={30} alt="Brand" />
              <h5
                className="mentommetLogoText"
                style={{
                  color: "black",
                  fontSize: "15px",
                  display: "inline",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  paddingLeft: 8,
                  textDecoration: "none",
                }}
              >
                MentoMEET
              </h5>
            </a>
            <nav className="navbar navbar-toggler-container shadow navbar-expand-lg sticky-top">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#mobileNavigation"
              >
                <MenuIcon />
              </button>
            </nav>
          </div>

          {this.getRoutes(user)}
          <div className="d-flex align-items-center justify-content-end" >
            <button className="MentorBtn d-none d-sm-block" onClick={() => {
              this.props.history.push('/mentor');
            }}>
              Become a Mentor
            </button>
            <button className="MentorBtn d-block d-md-none" onClick={() => {
              this.props.history.push('/mentor');
            }}>
              Be a Mentor
            </button>
            {/* Logout or Login */}
            {user ? (
              <button className="logoutBtn" onClick={() => this.handleLogout()}>
                Logout
              </button>
            ) : (
              <>
                <Link className="signupBtn" to="/register">
                  Signup
                </Link>
                <Link className="loginBtn" to="/login">
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
        <div id="mobileNavigation" className="collapse navbar-collapse">
          {this.getRoutes(user)}
        </div>
      </>
    );
  }
}

export default withRouter(NavBar);
