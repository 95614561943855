import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {Container,Row,Col, Table, Button } from 'reactstrap';
import TestDetail from './TestDetail';  //details of each test
import DisplayAllQuestions from '../DisplayAllQuestions'; //all questions within a test
import axios from 'axios';
import ResultTable from '../Actions/ResultTable';
import {Checkbox} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

/* Parent Component MyAllTestsHistory  */
export default function MyAllTestsHistory({secretId}){
	
	const [data,setData] = useState([]);
	const [detail,setDetail] = useState('');
	const [stage,setStage] = useState(0);
	
	const [selectedTest,setSelectedTest] = useState({});
	const [toggleSelect,setToggleSelect] = useState(false);
	
	
	//0 for all test list and their basic info
	//1 for questions particular to a test
	//2 for results 
	//3 for showing form for combine test ---- not implemented

	useEffect(() => {
		//get role 
		let userInfo = JSON.parse(localStorage.getItem('user'));
		let role = userInfo.role;
		if(window.location.href.includes('/admin/dashboard/mocktest') && secretId){
			role = 'admin';
		}
		
		axios.get(`https://${window.location.hostname}:5005/${role}/testshistory/${userInfo._id}`)
			.then(response => {
				setData(response.data);
			})
			.catch((err) => {
				console.log('Error :', err);
			})
	},[detail])
	
	function handleClick(e){
		e.preventDefault();
		let userInfo = JSON.parse(localStorage.getItem('user'));		
		if(e.target.dataset.id && e.target.dataset.publish){
			if(!secretId || !window.location.href.includes('/admin/dashboard/mocktest')) return;
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId })
			};

			let testID = e.target.dataset.id;
			const endpoint = `https://${window.location.hostname}:5005/admin/publish/${e.target.dataset.publish}/${e.target.dataset.id}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						setData(data);
						setDetail('');
						alert('Done');
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
		}else if(e.target.dataset.id && e.target.dataset.pricing){
			
			if(!secretId || !window.location.href.includes('/admin/dashboard/mocktest')) return;
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId })
			};

			const endpoint = `https://${window.location.hostname}:5005/admin/mocktest/pricing/${e.target.dataset.pricing}/${e.target.dataset.id}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						setData(data);
						setDetail('');
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
			
		}else if(e.target.dataset.id){
			setDetail(e.target.dataset.id);
		}else if(e.target.dataset.delete){
			alert('Do you want to delete this test ?');
			//get role 
			let userInfo = JSON.parse(localStorage.getItem('user'));
			let role = userInfo.role;
			if(window.location.href.includes('/admin/dashboard/mocktest') && secretId){
				role = 'admin';
			}
			axios.get(`https://${window.location.hostname}:5005/${role}/test/delete/${e.target.dataset.delete}/${userInfo._id}`)
			.then(response => {
				setData(response.data);
				setDetail('');
				alert('Done');
			})
			.catch((err) => {
				console.log('Error :', err);
			})
		}
	}
	
	function dataList() {
		if(!data) return;
		return data.map((currData, i) => {
			return <HistoryData secretId={secretId}  selectedTest={selectedTest}  setSelectedTest={setSelectedTest} selectStatus={toggleSelect} data={currData} key={i} />;
		})
	}
	
	
	function updateMockTestTags(newTags,nid){
			const requestOptions = {
			  method: 'POST',
			  headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify({ API_KEY: secretId ,newTags:newTags})
			};

			const endpoint = `https://${window.location.hostname}:5005/admin/test/update/tags/${nid}`;
			fetch(endpoint, requestOptions)
			.then(res => {
				if(res.ok){
					res.json().then(data => {
						alert('Updated')
						console.log(data);
						//IF Drawer is Open , Close It
						/* if(drawer){
							setDrawer(false);
						} */
					})
				}else{
					alert('ERROR')
				}
			 })
			.catch((err) => {
				console.log('Error :', err);
			})
	}
	
	
	function handleCombineTest(){
		console.log(selectedTest);
		alert('Combining Tests');
		let testName,testDesc,testDuration;
		testName = prompt('Enter Name OF Combine Test');
		testDesc = prompt('Enter Description Of Test');
		testDuration = prompt('Enter Duration OF Test In SECONDS');
		
		//IF TEST SELECTED
		let allTests = [];
		 Object.keys(selectedTest).forEach((el) => {
			if(selectedTest[el]){
				allTests.push(el);
			}
		})
		console.log(allTests);
		if(allTests.length > 0){
			alert('Going To send request');
			let userInfo = JSON.parse(localStorage.getItem('user'));
			if(!userInfo){
				return;
			}
			axios.post(`https://${window.location.hostname}:5005/mentor/combineTest`,{userInfo,mergeTest:allTests,testName,testDesc,testDuration})
			.then(response => {
				console.log(response);
				alert('Need To Show The Combine Test');
			})
			.catch((err) => {
				console.log('Error :', err);
			})
		}else{
			alert('No Test Selected')
		}
	}
	
	return(
			<Container className='justify-center mt-2' style={{marginBottom:'15rem'}} >
					<Row className="mt-3 mb-3 ">
						<Col>
							<Button  onClick={() => setToggleSelect(!toggleSelect)} >Select Tests</Button>
							{toggleSelect?<Button className="ml-2 mr-2" onClick={() => handleCombineTest()} >Combine Tests</Button>:null}
						</Col>
					</Row>
					<Row>
					  {(stage == 0)?<Col>
							<Table striped>
								<thead>
									<tr>
										{
											toggleSelect?<th>Select</th>:null
										}
										<th>Test Name</th>
										<th> Status</th>
										{secretId?<th>Pricing</th>:null}
										<th>Action</th>
									</tr>
								</thead>
								<tbody onClick={(e) => handleClick(e)}>
									{dataList()}
								</tbody>
							</Table>
					     </Col>:null}
						 
						 
						{(detail && (stage == 0))?<Col>
								<Drawer anchor={'right'} open={stage == 0?true:false}	 onClose={() => setDetail('')}>
									<TestDetail handleTags={updateMockTestTags} handleClick={handleClick} detail={detail} setStage={setStage} />
								</Drawer>
						  </Col>:null}
						  
						  
						{(stage == 1)?<Col>
								<DisplayAllQuestions  detail={detail} setStage={setStage} />
						  </Col>:null}
						  
						 {(stage == 2)?<Col >
							<Button onClick={() => setStage(0)}>Back</Button>
							<ResultTable detail={detail} secretId={secretId} />
						</Col>:null}
				  </Row>
			  </Container>
	)
}


const HistoryData = (props) => (
	<tr >
		{/* For Selecting to combine */}
			{
				//Is The Selected Option is true
				props.selectStatus?(
						<Checkbox
							id={props.data._id}
							checked={props.selectedTest[props.data._id]?true:false}
							onClick={(e) => {
								let i = e.target.id;
								//console.log(i);
								let oldSelection = props.selectedTest || {};
								//console.log(oldSelection);
								oldSelection[i] = !oldSelection[i];
								//console.log(oldSelection);
								props.setSelectedTest({...oldSelection});
							}}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
				):null
			}
	
		<td><Link  data-id={props.data._id}  >{props.data.name.toUpperCase()}</Link></td>
		
		{JSON.parse(localStorage.getItem('user')).role.toLowerCase() === 'mentor'?(
				<td>{props.data.published?<span style={{color:'green'}} >Published</span>:<span style={{color:'red'}} >Not Published</span>}</td>
			):null
		}
		
		{props.secretId && window.location.href.includes('/admin/dashboard/mocktest')?(
			<td>{props.data.isFree?<span style={{color:'green'}} >FREE</span>:<span style={{color:'red'}} >PAID</span>}</td>
			):null
		}
		
		<Button data-id={props.data._id} >More</Button>
	
	  
	</tr>
)





