import React,{useState,useEffect} from 'react';
import {Button,Form} from 'react-bootstrap';
import { TextField} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import MUIRichTextEditor from 'mui-rte';
import {createMuiTheme ,MuiThemeProvider} from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const defaultTheme = createMuiTheme();

Object.assign(defaultTheme,{
	overrides:{
		MUIRichTextEditor:{
			editor:{
				color:'black',
				maxHeight:'200px',
				height:'200px',
				overflow:'scroll'
			}
		}
	}
})


/* Child Component of AdminWebinar  */
export default function AdminCreateWebinar(){
		
	const [name,setName] = useState('');
	const [mode,setMode] = useState('');
	const [description,setDescription] = useState('');
	const [image,setImage] = useState('');
	const [contact,setContact] = useState('');
	const [selectedDate,setSelectedDate] = useState(new Date());
	const [selectedTime,setSelectedTime] = useState(new Date());
	
	function check(){
		let code = true;
		if(!name) code = false;
		if(!mode) code = false; 
		if(!description) code = false; 
		if(!selectedDate) code = false;
		if(!selectedTime) code = false;
		if(!image) code = false;
		if(!contact) code = false;
		return code;
	}
	
	function handleSave(e){
		e.preventDefault();
		if(!check()){
			alert('Required Fields Missing ')
			return;
		}
		
		//creating form 
		const data = new FormData();
		data.append('eventName',name);
		data.append('mode',mode);
		data.append('description',description);
		data.append('date',selectedDate);
		data.append('time',selectedTime);
		data.append('webinar',image);
		data.append('contact',contact);
		
	    console.log('Sending Request');
		//let role = JSON.parse(localStorage.getItem('user')).role.toLowerCase();
		const token = localStorage.getItem('token');		
		fetch(`https://${window.location.hostname}:5005/admin/webinar`,{
			method:"POST",
			//headers:{'Authorization':`Bearer ${token}`},			
			body:data
		})
		.then(res => {
		    if(res.ok){
				alert('ok')
			/* res.json().then(data => {
				     
				}) */
			}else{
				alert('error')
			}
		})
		.catch(err => {
			alert('Error')
		})
		
	}

	return(
		<div style={{margin:'1rem auto 0 auto',border:'2px solid yellow'}}>
				<p style={{color:'red'}} >You Need To Click On Save Icon To Save The Webinar Description</p>
				<Form autocomplete="off" style={{display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'ghostwhite',marginBottom:'2rem',padding:'2rem'}} >
					{/* Webinar Name */}
						<TextField
							fullWidth={true}
							placeholder={`Type Webinar Name ...`}
							style={{ marginBottom: '18px' }}
							rows={2}
							rowsMax={20}
							required
							name='webinarName'
							value={name}
							onChange={(e) => setName(e.target.value)}
							variant="filled"
							
						/>
				    {/* Mode Of Webinar */}
						<div>		
							<select  onChange={(e) => setMode(e.target.value)} >
								<option >Select Mode </option>
								<option>Online</option>
								<option>Offline</option>					
							</select>
						</div>
					{/* Date   */}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						 <KeyboardDatePicker
							  disableToolbar
							  variant="inline"
							  margin="normal"
							  id="date-picker-inline"
							  label={'Webinar Date'}
							  value={selectedDate}
							  onChange={(date) => setSelectedDate(date)}
							  KeyboardButtonProps={{
								'aria-label': 'change date',
							  }}
							/>
					 </MuiPickersUtilsProvider>
						
					{/* Time */}
					 <MuiPickersUtilsProvider utils={DateFnsUtils} >
						<KeyboardTimePicker
							  margin="normal"
							  id="time-picker"
							  label="Webinar Time"
							  value={selectedTime}
							  onChange={(date) => setSelectedTime(date)}
							  KeyboardButtonProps={{
								'aria-label': 'change time',
							  }}
							/>
					  </MuiPickersUtilsProvider>
					{/* Description */}
						<MuiThemeProvider theme={defaultTheme} > 
							 <MUIRichTextEditor 
									label="Start .."
									controls={['superscript','subscript','bulletList','italic','underline','bold','save']}
									name='question'
									
									onSave={data => setDescription(data) }
									customControls={[
										{
											name: "superscript",
											icon:<ArrowUpwardIcon />,
											type:'inline',
											inlineStyle:{
												fontSize:'x-small',
												verticalAlign:'super'
											}
										},
										{
											name: "subscript",
											icon: <ArrowDownwardIcon />,
											type:'inline',
											inlineStyle:{
												fontSize:'x-small',
												verticalAlign:'sub'
											}
										}
										
									]}
							 />
						</MuiThemeProvider>
						{/* 	<TextField
							fullWidth={true}
							placeholder={`Type Webinar Description ...`}
							style={{ marginBottom: '18px' }}
							rows={2}
							multiline={true}
							rowsMax={20}
							required
							name='webinarName'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							variant="filled"
							
/> */}
					{/* contact info */}
						<TextField
							fullWidth={true}
							placeholder={`Contact Info ...`}
							style={{ marginBottom: '18px' }}
							rows={2}
							rowsMax={20}
							required
							name='webinarName'
							value={contact}
							onChange={(e) => setContact(e.target.value)}
							variant="filled"
							
						/>
					  {/*Webinar Image */}
						  <Form.File
						  required
						  style={{color:'black'}}
						  name="webinar"
						  label="Webinar Image"
						  feedbackTooltip
						  style={{marginLeft:'3rem',color:'black'}}
						  onChange={(e) => { 
								setImage(e.target.files[0])
							}}
						  />
					  {/* Save Webinar Button */}
						<Button type='submit' onClick={(e) => handleSave(e)}  style={{marginTop:'2rem'}} >Save Webinar</Button>
						
                    </Form>
                </div>
	)

}